<template>
  <div id="operation">
    <!-- 操作记录 -->
    <div class="line" v-for="(item, index) in operationData" :key="index">
      <div class="name">{{ item.userName }}:</div>
      <div class="content">{{ item.operation }}</div>
      <div class="annex" @click="showAnnex(item)" v-if="item.annex">
        查看附件
      </div>
      <div class="time">{{ item.time }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["operationData"],
  data() {
    return {};
  },
  methods: {
    /**
     * 查看附件
     */
    showAnnex(item) {
      window.open(item.annex, "_blank");
    },
  },
  mounted() {
    console.log("打开操作记录");
  },
};
</script>

<style lang="less" scoped>
#operation {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  align-items: center;
  > .line {
    width: 80%;
    height: auto;
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-top: 15px;
    box-sizing: border-box;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(51, 51, 51, 0.239);
    > .name {
      width: 10%;
      height: auto;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      margin-right: 10px;
    }
    > .content {
      width: 0;
      flex: 1;
      height: auto;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    > .annex {
      width: 15%;
      height: auto;
      font-size: 12px;
      cursor: pointer;
      color: @baseColor;
    }
    > .time {
      width: 15%;
      height: auto;
      font-size: 12px;
      color: rgba(144, 144, 144, 0.548);
    }
  }
}
</style>
