import Vue from 'vue'

export default function request(method, url, sendData, deal, err) {
    Vue.axios({
            method: method,
            url: this.$baseurl + url,
            data: JSON.stringify(sendData),
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            }
        })
        .then(
            res => {
                switch (res.data.code) {
                    case 200:
                        deal(res.data.result)
                        break;
                    case 500:
                        // this.$message.error(res.data.message);
                        err(res.data.message)
                        break
                    case 401:
                        window.sessionStorage.removeItem("token");
                        this.$router.push("/");
                        this.$message.error(res.data.msg);
                        break
                    default:
                        this.$message.error(res.data.message);
                        err()
                        break;
                }
            }
        ).catch(() => {
            // this.$message.error('地址异常');
        })

}