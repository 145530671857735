<template>
  <div class="create-agent">
    <el-divider content-position="left">个人, 区域信息</el-divider>
    <div class="line">
      <div class="line-title">账号:</div>
      <div class="line-value">
        <el-input v-model="value.idCard" placeholder="请输入账号"></el-input>
      </div>
    </div>
    <div class="line">
      <div class="line-title">姓名:</div>
      <div class="line-value">
        <el-input v-model="value.userName" placeholder="请输入姓名"></el-input>
      </div>
    </div>
    <div class="line">
      <div class="line-title">密码:</div>
      <div class="line-value">
        <el-input
          v-model="value.password"
          placeholder="请输入密码"
          show-password
        ></el-input>
      </div>
    </div>
    <div class="line">
      <div class="line-title">区域名称:</div>
      <div class="line-value">
        <el-select
          v-model="value.areaId"
          placeholder="请选择区域名称"
          :disabled="value.isEdit"
        >
          <el-option-group
            v-for="group in value.areaList"
            :key="group.label"
            :label="group.label"
          >
            <el-option
              v-for="item in group.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-option-group>
        </el-select>
      </div>
    </div>
    <el-divider content-position="left">地址信息</el-divider>
    <div class="line-address">
      <div
        class="line-address-item"
        v-for="(item, index) in value.addressList"
        :key="index"
      >
        <div class="line-address-item-left">
          <div class="line-address-item-left-line">
            <div class="line-address-item-left-item" style="width: 50%">
              <div class="title">地址前缀:</div>
              <div class="value">
                <el-input v-model="item.prefix"></el-input>
              </div>
            </div>
            <div class="line-address-item-left-item" style="width: 25%">
              <div class="title">地址后缀:</div>
              <div class="value">
                <el-input v-model="item.suffix"></el-input>
              </div>
            </div>
            <div class="line-address-item-left-item" style="width: 25%">
              <div class="title">标签:</div>
              <div class="value">
                <el-input v-model="item.tag"></el-input>
              </div>
            </div>
          </div>
          <div class="line-address-item-left-line">
            <div class="line-address-item-left-item">
              <div class="title">个体户价格:</div>
              <div class="value">
                <el-input v-model="item.priceIndividual"></el-input>
              </div>
            </div>
            <div class="line-address-item-left-item">
              <div class="title color">折扣:</div>
              <div class="value">
                <el-input v-model="item.discountIndividual"></el-input>
              </div>
            </div>
            <div class="line-address-item-left-item">
              <div class="title">企业价格:</div>
              <div class="value">
                <el-input v-model="item.priceEnterprise"></el-input>
              </div>
            </div>
            <div class="line-address-item-left-item">
              <div class="title color">折扣:</div>
              <div class="value">
                <el-input v-model="item.discountEnterprise"></el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="line-address-item-right">
          <div class="line-address-item-right-btn">
            <i class="el-icon-minus" @click="handleClick(index, 'delete')"></i>
            <i class="el-icon-plus" @click="handleClick(index, 'add')"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["value"],
  data() {
    return {};
  },
  methods: {
    handleClick(index, type) {
      switch (type) {
        case "add":
          // 添加地址
          this.$emit("addArea", index);
          break;
        case "delete":
          // 删除地址
          this.$emit("deleteArea", index);
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    console.log("value", this.value);
  },
};
</script>

<style lang="less" scoped>
.create-agent {
  width: 100%;
  height: 100%;
  font-size: 14px;
  overflow-y: auto;
  .line {
    width: 90%;
    display: flex;
    align-items: center;
    min-height: 50px;
    margin: 8px 0;
    .line-title {
      width: 150px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }
    .line-value {
      width: 0;
      flex: 1;
      height: 100%;
      display: flex;
      align-items: flex-start;
      margin-left: 10px;
    }
  }
  .line-address {
    width: 90%;
    margin: 0 auto;
    .color {
      color: @activeColor;
    }
    .line-address-item {
      width: 100%;
      display: flex;
      background-color: #f4f4f4;
      padding: 10px;
      border-radius: 3px;
      margin: 20px 0;
      .line-address-item-left {
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .line-address-item-left-line {
          width: 100%;
          display: flex;
          .line-address-item-left-item {
            display: flex;
            align-items: center;
            width: 25%;
            margin: 5px 0;
            .title {
              min-width: 80px;
              text-align: right;
              margin-right: 5px;
            }
            .value {
              width: 100%;
            }
          }
        }
      }
      .line-address-item-right {
        width: 10%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        .line-address-item-right-btn {
          width: 100%;
          display: flex;
          justify-content: space-evenly;
          cursor: pointer;
        }
      }
      .line-address-info {
        width: 100%;
        display: flex;
        align-items: center;
        margin: 8px 0;
        .line-address-info-item {
          display: flex;
          align-items: center;
          .title {
            min-width: 75px;
            margin-right: 10px;
            text-align: right;
          }
          .value {
            width: 100px;
            display: flex;
            justify-content: space-evenly;
          }
          .color {
            color: @activeColor;
          }
        }
      }
    }
  }
}
</style>
