<template>
  <div id="instructions" class="div-flex-center">
    <!-- 公告须知界面 -->
    <el-tabs v-model="activeName">
      <el-tab-pane label="集中管理注册地址申请须知" name="first">
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          height="65vh"
          stripe
        >
          <el-table-column prop="num" label="编号" width="50" center>
          </el-table-column>
          <el-table-column prop="content" label="内容">
            <template slot-scope="scope">
              <div class="table_content" v-html="scope.row.content"></div>
            </template>
          </el-table-column>
        </el-table>
        <div class="bottom">
          <el-checkbox v-model="checked" @change="changeBut"
            >已阅读确认业务须知</el-checkbox
          >
        </div>
        <div class="bottom-buts">
          <div
            :class="{
              but: true,
              'div-flex-center': true,
              'line-but-disable': isDisable,
            }"
            @click="makesure"
          >
            {{ butTip }}
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import * as instructionsData from "../../assets/js/instructions.js";
export default {
  data() {
    return {
      loading: true,
      userData: {
        userId: "",
        userName: "",
        icon: "",
      },
      name: "", // 当前目录
      activeName: "first",
      tableData: [],
      checked: false,

      isDisable: true,
      butTip: "确认",
      timer: null,
      countdown: 10,
    };
  },
  methods: {
    /**
     * 初始化界面 获取须知表格内容
     */
    initData() {
      let requestData = {};
      this.$request("post", "/systemInfo/getReadMust", requestData, (data) => {
        // let companyName = "安徽广电企服科技有限公司"; // 公司名称
        // let tencentName = "南沙商秘"; // 公众号
        // let contractName = "商务秘书服务合同"; // 签订的合同名称
        this.tableData = instructionsData.getAnnouncementTheGuidelinesList(
          data.companyName,
          data.tencentName,
          data.contractName
        );
        this.isDisable = false;
      });
      // this.$forceUpdate();
      // if (!this.timer) {
      //   console.log("计时器开启", this.tableData);
      //   this.timer = setInterval(() => {
      //     if (this.countdown > 0 && this.countdown <= 10) {
      //       this.countdown--;
      //       this.isDisable = true;
      //       if (this.countdown !== 0) {
      //         this.butTip = "确认(" + this.countdown + ")";
      //       } else {
      //         clearInterval(this.timer);
      //         this.butTip = "确认";
      //         this.countdown = 10;
      //         this.timer = null;
      //         this.isDisable = false;
      //       }
      //     }
      //   }, 1000);
      // }
    },
    changeBut() {},
    makesure() {
      if (!this.checked) {
        this.$message({
          showClose: true,
          message: "请确认须知内容后再进行下一步申请!",
          type: "warning",
        });
        return;
      }
      if (this.isDisable) {
        return;
      }
      let path = "/main/order/";
      switch (this.name) {
        case "新设立申请":
          path = path + "create_order";
          break;
        case "迁入":
          path = path + "tomovein_order";
          break;
        case "续签":
          path = path + "renew_order";
          break;
        default:
          break;
      }
      this.$router.push({
        path: path,
        query: {
          id: "",
        },
      });
    },
  },
  mounted() {
    this.loading = true;
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    this.name = this.$route.query.name;
    this.initData();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
};
</script>

<style lang="less" scoped>
#instructions {
  width: 100%;
  height: 100%;
  .el-tabs {
    width: 98%;
    height: 98%;
  }
  .bottom {
    width: 100%;
    height: auto;
    padding: 10px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  .bottom-buts {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    .but {
      width: auto;
      height: 30px;
      padding: 0 15px;
      margin: 0 0 0 15px;
      background-color: @baseColor;
      color: #fff;
      font-size: 15px;
      border-radius: 8px;
      transition: 0.3s;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      &:hover {
        cursor: pointer;
        background-color: @but-hoverColor;
      }
    }
    .line-but-disable {
      background-color: @but-disableColor;
      &:hover {
        background-color: @but-disableColor;
        cursor: not-allowed;
      }
    }
  }
}
</style>
