import { render, staticRenderFns } from "./personal_information.vue?vue&type=template&id=549dae18&scoped=true&"
import script from "./personal_information.vue?vue&type=script&lang=js&"
export * from "./personal_information.vue?vue&type=script&lang=js&"
import style0 from "./personal_information.vue?vue&type=style&index=0&id=549dae18&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "549dae18",
  null
  
)

export default component.exports