<template>
  <div id="advertising" class="div-flex-column-center" v-loading="loading">
    <!-- 广告设置 -->
    <div class="content div-flex-center">
      <el-table :data="tableData" border style="width: 90%" height="98%">
        <el-table-column
          :prop="item.key"
          :label="item.title"
          :width="item.width"
          v-for="(item, index) in tableMenu"
          :key="index"
        >
          <template slot-scope="scope">
            <span v-if="item.key == 'img'" v-viewer="{ movable: false }">
              <img :src="scope.row[item.key]" alt="" />
            </span>
            <span v-else>{{ scope.row[item.key] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="140">
          <template slot="header">
            <el-button
              @click="handleClick({}, 'add')"
              type="text"
              size="small"
              icon="el-icon-circle-plus-outline"
              >添加</el-button
            >
          </template>
          <template slot-scope="scope">
            <el-button
              v-for="(item, index) in tableOper"
              :key="index"
              v-show="item.show && item.statusShow"
              @click="handleClick(scope.row, item.active)"
              type="text"
              size="small"
              >{{ item.name }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page">
      <el-pagination
        @current-change="initData"
        :current-page.sync="pageData.currentPage"
        :page-size="pageData.pageSize"
        layout="total, prev, pager, next,jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>
    <div id="my-dialog" class="div-flex-center" v-if="dialogData.flag">
      <div
        class="my-dialog-content div-flex-column-center"
        v-loading="dialogData.loading"
      >
        <div class="top div-flex-center">
          <div class="title">{{ dialogData.title }}</div>
          <div class="perch-active"></div>
          <div class="close" v-if="dialogData.closeFlag">
            <i class="el-icon-close" @click="dialogData.flag = false"></i>
          </div>
        </div>
        <div
          class="content"
          v-if="dialogData.type == 'edit' || dialogData.type == 'add'"
          style="width: 100%"
        >
          <div class="edit">
            <information_input
              :informationInputData="dialogData.data"
              @handleAvatarSuccess="handleAvatarSuccess"
              @beforeAvatarUpload="beforeAvatarUpload"
            >
            </information_input>
          </div>
        </div>
        <div class="buts div-flex-center">
          <div
            class="but"
            v-for="(item, index) in dialogData.buttonList"
            :key="index"
            @click="dialogHandleCilck(item.active)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      userData: {
        userId: "",
        userName: "",
        icon: "",
      },
      searchData: {
        input: "",
      },
      pageData: {
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页条数
        total: 0, // 总条目数
      },
      tableData: [],
      tableMenu: [
        {
          title: "标题",
          key: "title",
          width: "",
        },
        {
          title: "图片",
          key: "img",
          width: "",
        },
        {
          title: "链接",
          key: "link",
          width: "",
        },
        {
          title: "类型",
          key: "type",
          width: "对联",
        },
        {
          title: "状态",
          key: "statusName",
          width: "80",
        },
        {
          title: "创建时间",
          key: "gmtCreate",
          width: "180",
        },
      ],
      tableOper: [
        {
          name: "编辑",
          key: "编辑",
          active: "edit",
          show: false, // 由权限控制
          statusShow: true, // 由当前订单的状态控制
        },
        {
          name: "删除",
          key: "删除",
          active: "delete",
          show: false, // 由权限控制
          statusShow: true, // 由当前订单的状态控制
        },
      ],
      dialogData: {
        loading: true,
        closeFlag: true,
        flag: false,
        title: "",
        type: "show_information",
        data: [],
        url: require("../../assets/img/qr_code.jpg"),
        buttonList: [],
      },
      orderList: [
        {
          title: "广告设置",
          data: [
            {
              title: "标题",
              type: "input",
              value: "",
              placeholder: "请输入标题",
              tip: true, // 是否为必填项
              landscape: true, // 是否横屏显示
              key: "title",
            },
            {
              title: "链接",
              type: "input",
              value: "",
              placeholder: "请输入链接",
              tip: true, // 是否为必填项
              landscape: true, // 是否横屏显示
              key: "link",
            },
            {
              title: "广告图片",
              key: "businessLicense",
              type: "file_reuse", // 仅文字展示
              tip: true, // 是否不得为空
              value: "",
              options: [],
              landscape: true, // 是否横屏显示
              placeholder: "选择附件", // 提示文字
              data: {
                systemName: "cluster_register",
                path: "",
                title: "广告图片",
              },
              isDisable: false,
              text: "", // 文件名称  显示在界面上的
            },
            {
              title: "状态",
              key: "status",
              type: "switch",
              value: 0,
              tip: true, // 是否为必填项
              landscape: true, // 是否横屏显示
              activeValue: 1,
              inactiveValue: 0,
            },
            {
              title: "类型",
              key: "type",
              type: "radio",
              value: "对联",
              tip: true, // 是否为必填项
              landscape: true, // 是否横屏显示
              options: [
                {
                  label: "对联",
                  value: "对联",
                },
                {
                  label: "弹窗",
                  value: "弹窗",
                },
              ],
            },
          ],
        },
      ],
      tempData: {},
    };
  },
  methods: {
    /**
     * 2.3 获取当前角色权限
     */
    getUserAuthority() {
      this.$baseData.getUserAuthority(this.userData.userId, this, (data) => {
        this.tableOper = this.$baseData.judgeButtonPermissions(
          data[0].model,
          this.tableOper,
          "系统设置",
          "广告设置"
        );
        this.initData();
      });
    },
    /**
     *12.2 获取客户模板列表
     */
    initData() {
      let requestData = {
        userId: this.userData.userId,
        search: {},
        currentPage: this.pageData.currentPage,
        pageSize: this.pageData.pageSize,
      };
      this.$request("post", "/advertisement/page", requestData, (data) => {
        this.tableData = data.records;
        this.tableData.forEach((item) => {
          if (item.status == 0) {
            item.statusName = "禁用";
          } else {
            item.statusName = "启用";
          }
        });
        this.pageData.total = data.total;
      });
    },
    /**
     * 表格中按钮点击事件
     */
    handleClick(item, active) {
      let requestData = {};
      this.tempData = item;
      switch (active) {
        case "edit":
          // 编辑表单
          this.dialogData.loading = true;
          this.dialogData.title = "编辑表单";
          this.dialogData.type = "edit";
          this.dialogData.buttonList = [
            {
              name: "确认",
              active: "sure_edit",
            },
            {
              name: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = JSON.parse(JSON.stringify(this.orderList));
          this.dialogData.data[0].data[0].value = this.tempData.title;
          this.dialogData.data[0].data[1].value = this.tempData.link;
          this.dialogData.data[0].data[2].value = this.tempData.img;
          this.dialogData.data[0].data[2].isDisable = true;
          this.dialogData.data[0].data[3].value = this.tempData.status;
          this.dialogData.data[0].data[4].value = this.tempData.type;
          let file = this.tempData.img;
          let d1 = file.split("\\");
          if (d1.length > 0) {
            let d2 = d1[d1.length - 1];
            let d3 = d2.split("_")[1];
            this.dialogData.data[0].data[2].text = d3 + ".pdf";
          }
          console.log("this.dialogData.data", this.dialogData.data);
          this.dialogData.loading = false;
          this.dialogData.flag = true;
          break;
        case "add":
          //   添加表单
          this.dialogData.loading = true;
          this.dialogData.title = "添加表单";
          this.dialogData.type = "add";
          this.dialogData.buttonList = [
            {
              name: "确认",
              active: "sure_add",
            },
            {
              name: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = JSON.parse(JSON.stringify(this.orderList));
          this.dialogData.loading = false;
          this.dialogData.flag = true;
          break;
        case "delete":
          //   删除表单
          this.$confirm(
            "此操作将永久删除该表单,不影响之前使用过的表单, 是否继续?",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              requestData = {
                ids: [this.tempData.id],
              };
              this.$request(
                "post",
                "/advertisement/delAdvertisement",
                requestData,
                (data) => {
                  this.$message.success("删除成功!");
                  this.initData();
                  this.$bus.$emit("refresh");
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;

        default:
          break;
      }
    },
    dialogHandleCilck(active) {
      let requestData = {};
      switch (active) {
        case "cancel":
          // 取消
          this.dialogData.flag = false;
          this.dialogData.closeFlag = true;
          break;
        case "sure_add":
          // 确认添加
          requestData = {
            title: this.dialogData.data[0].data[0].value, // 标题
            link: this.dialogData.data[0].data[1].value, // 连接
            img: this.dialogData.data[0].data[2].value, // 图片
            status: this.dialogData.data[0].data[3].value, // 状态：0 - 禁用；1 - 启用
            type: this.dialogData.data[0].data[4].value, // 类型 对联 弹窗
          };
          if (!requestData.title || !requestData.img || !requestData.link) {
            this.$message.error("标题,链接,图片为必填项!");
            return;
          }
          this.$request(
            "post",
            "/advertisement/addAdvertisement",
            requestData,
            (data) => {
              this.$message.success("添加成功!");
              this.dialogHandleCilck("cancel");
              this.initData();
              this.$bus.$emit("refresh");
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "sure_edit":
          // 确认编辑
          requestData = {
            id: this.tempData.id, // id
            title: this.dialogData.data[0].data[0].value, // 标题
            link: this.dialogData.data[0].data[1].value, // 链接
            img: this.dialogData.data[0].data[2].value, // 图片
            status: this.dialogData.data[0].data[3].value, // 状态：0 - 禁用；1 - 启用
            type: this.dialogData.data[0].data[4].value, // 类型 对联 弹窗
          };
          if (!requestData.title || !requestData.img || !requestData.link) {
            this.$message.error("标题,链接,图片为必填项!");
            return;
          }
          this.$request(
            "post",
            "/advertisement/updateAdvertisement",
            requestData,
            (data) => {
              this.$message.success("编辑成功!");
              this.dialogHandleCilck("cancel");
              this.initData();
              this.$bus.$emit("refresh");
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        default:
          break;
      }
    },
    /**
     * 文件上传前格式判断
     */
    beforeAvatarUpload(file) {
      console.log("上传文件格式:", file.type);
      const isJPG =
        file.type === "application/pdf" ||
        file.type === "image/jpeg" ||
        file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("上传文件只能是 PDF 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    /**
     * 文件上传成功
     */
    handleAvatarSuccess(data) {
      switch (data.response.result.title) {
        case "广告图片":
          this.dialogData.data[0].data[2].value = data.response.result.path;
          this.dialogData.data[0].data[2].isDisable = true;
          this.dialogData.data[0].data[2].text = data.file.name;
          break;
        default:
          break;
      }
      console.log(this.orderList);
    },
  },
  mounted() {
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    let dd = {
      id: "",
      name: "广告设置",
      auth: "2",
      path: "/main/setting/advertising",
    };
    sessionStorage.setItem("childernMenu", JSON.stringify(dd));
    this.getUserAuthority();
  },
};
</script>

<style lang="less" scoped>
#advertising {
  width: 100%;
  height: 100%;
  .fill-in-information {
    width: 100%;
    height: auto;
    .el-tabs {
      width: 98%;
      height: auto;
    }
  }
  .content {
    width: 98%;
    height: 0;
    flex: 1;
    .tip {
      color: @baseColor;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: @activeColor;
      }
    }
    img {
      width: 30px;
      height: 30px;
    }
  }
  .page {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
  }
  .buts {
    width: 100%;
    height: 50px;
    .but {
      width: auto;
      height: auto;
      padding: 5px 15px;
      margin: 0 10px;
      box-sizing: border-box;
      border-radius: 8px;
      background-color: @baseColor;
      color: #ffffff;
      font-size: @font-size-tip-mini;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        background-color: @but-hoverColor;
      }
    }
  }
  #my-dialog .content {
    width: 98%;
    height: 0;
    flex: 1;
    .content-item {
      width: 33%;
      height: 50%;
      font-size: 14px;
      .content-item-top {
        width: 100%;
        height: 35px;
        position: relative;
        .content-item-top-line {
          width: 100%;
          height: 1px;
          background-color: rgba(160, 160, 160, 0.288);
          position: absolute;
          left: 0;
          top: 10px;
        }
        .content-item-top-title {
          width: auto;
          background-color: #ffffff;
          height: 100%;
          position: absolute;
          left: 30px;
          padding: 0 8px;
          font-size: 14px;
          span {
            color: red;
            margin-left: 8px;
          }
        }
      }
      .content {
        width: 98%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 13px;
        .line {
          width: 100%;
          height: 30px;
          display: flex;
          align-items: center;
          .span {
            width: auto;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 3px;
            box-sizing: border-box;
          }
          .value {
            width: 0;
            flex: 1;
            height: 100%;
            display: flex;
            align-items: center;
          }
          .file_text {
            color: @baseColor;
            transition: 0.3s;
            &:hover {
              cursor: pointer;
              color: @activeColor;
              text-decoration: underline;
            }
          }
        }
        .edit {
          width: 100%;
        }
      }
    }
    .buts {
      width: 100%;
      height: 50px;
      .but {
        width: auto;
        height: auto;
        padding: 5px 15px;
        margin: 0 10px;
        box-sizing: border-box;
        border-radius: 8px;
        background-color: @baseColor;
        color: #ffffff;
        font-size: @font-size-tip-mini;
        transition: 0.3s;
        &:hover {
          cursor: pointer;
          background-color: @but-hoverColor;
        }
      }
    }
    .tip {
      margin-top: 20px;
      font-size: 14px;
    }
  }
}
</style>
