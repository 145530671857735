<template>
  <div id="agent_management" class="div-flex-column-center" v-loading="loading">
    <!-- 合作商 -->
    <div class="search-line">
      <el-input
        v-model="searchData.companyName"
        placeholder="企业名称"
        clearable
        @keyup.enter.native="resetPage"
      ></el-input>
      <el-select
        v-model="searchData.type"
        clearable
        filterable
        placeholder="请选择代理类型"
        @change="resetPage"
      >
        <el-option label="全部" value="0"> </el-option>
        <el-option label="区域审核" value="2"> </el-option>
        <el-option label="合作商" value="5"> </el-option>
      </el-select>
      <el-input
        v-model="searchData.legalPersonName"
        placeholder="法定代表人"
        clearable
        @keyup.enter.native="resetPage"
      ></el-input>
      <el-input
        v-model="searchData.legalPersonPhone"
        placeholder="手机号"
        clearable
        @keyup.enter.native="resetPage"
      ></el-input>
      <el-select
        v-model="searchData.status"
        clearable
        filterable
        placeholder="请选择备案状态"
        @change="resetPage"
      >
        <el-option label="全部" :value="''"> </el-option>
        <el-option label="审核中" value="1"> </el-option>
        <el-option label="审核不通过" value="2"> </el-option>
        <el-option label="已通过" value="3"> </el-option>
      </el-select>
    </div>
    <div class="content div-flex-center">
      <el-table :data="tableData" border style="width: 90%" height="98%">
        <el-table-column
          :prop="item.key"
          :label="item.title"
          :width="item.width"
          :fixed="item.fixed"
          v-for="(item, index) in tableMenu"
          :key="index"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="180" align="left">
          <template slot-scope="scope">
            <el-button
              v-for="(it, ii) in scope.row.tableOper"
              :key="ii"
              @click="handleClick(scope.row, it.active)"
              type="text"
              size="small"
              v-show="it.show && it.statusShow"
              >{{ it.name }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page">
      <el-pagination
        @current-change="initData"
        :current-page.sync="pageData.currentPage"
        :page-size="pageData.pageSize"
        layout="total, prev, pager, next,jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>
    <div id="my-dialog" class="div-flex-center" v-if="dialogData.flag">
      <div
        class="my-dialog-content div-flex-column-center"
        v-loading="dialogData.loading"
      >
        <div class="top div-flex-center">
          <div class="title">{{ dialogData.title }}</div>
          <div class="perch-active"></div>
          <div class="close">
            <i class="el-icon-close" @click="dialogData.flag = false"></i>
          </div>
        </div>
        <div class="content div-flex-column-center">
          <information_text
            v-if="dialogData.type == 'information'"
            :informationTextData="dialogData.data"
          ></information_text>
          <information_input
            v-if="dialogData.type == 'input'"
            :informationInputData="dialogData.data"
            @beforeAvatarUpload="beforeAvatarUpload"
            @handleAvatarSuccess="handleAvatarSuccess"
            @radioChange="radioChange"
          >
          </information_input>
          <OperationData
            v-if="dialogData.type == 'operation'"
            :operationData="dialogData.data"
          ></OperationData>
        </div>
        <div class="buts div-flex-center">
          <div
            class="but"
            v-for="(item, index) in dialogData.buttonList"
            :key="index"
            @click="dialogHandleCilck(item.active)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as agentData from "../../assets/js/agentData.js";
import * as instructionsData from "../../assets/js/instructions.js";
import OperationData from "@/components/operation.vue";
export default {
  components: { OperationData },
  data() {
    return {
      input: "12312",
      loading: false,
      userData: {
        userId: "",
        userName: "",
        icon: "",
      },
      searchData: {
        companyName: "", // 企业名称
        legalPersonName: "", // 法定代表人姓名
        legalPersonPhone: "", // 法定代表人手机号
        status: "", // 备案状态 '' 全部  1-审核中  2-审核不通过 3-已通过
        type: "", // 代理类型
      },
      orderList: agentData.getAssignDialogData(),
      pageData: {
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页条数
        total: 0, // 总条目数
      },
      tableData: [],
      tableMenu: agentData.getAgentMenuData(),
      tableOper: [
        {
          name: "查看详情",
          key: "查看详情",
          active: "show_detail",
          show: false, // 由权限控制
          statusShow: true, // 由当前订单的状态控制
        },
        {
          name: "支出详情",
          key: "支出详情",
          active: "pay_details",
          show: false, // 由权限控制
          statusShow: true, // 由当前订单的状态控制
        },
        {
          name: "充值",
          key: "充值",
          active: "pay",
          show: false, // 由权限控制
          statusShow: false, // 由当前订单的状态控制
        },
        {
          name: "折扣设置",
          key: "折扣设置",
          active: "discount",
          show: false, // 由权限控制
          statusShow: false, // 由当前订单的状态控制
        },
      ],
      dialogData: {
        flag: false,
        title: "客户基本信息录入",
        loading: true,
        type: "sign",
        baseData: {
          // 录入基本信息时,填写的表单信息
          title: "", // 自定义表单标题
          id: "", // 自定义表单ID
          time: "", // 时间
        },
        data: [],
        buttonList: [],
      },
      tempData: {},
      areaSelectOption: [],
      alreadyAreaSelectOption: [],
    };
  },
  methods: {
    /**
     * 2.3 获取当前角色权限
     */
    getUserAuthority() {
      this.$baseData.getUserAuthority(this.userData.userId, this, (data) => {
        this.tableOper = this.$baseData.judgeButtonPermissions(
          data[0].model,
          this.tableOper,
          "代理管理",
          "合作商"
        );
        console.log("当前界面按钮权限:", this.tableOper);
        this.initData();
      });
    },
    // 列表数据
    initData() {
      let requestData = {
        userId: this.userData.userId,
        companyName: this.searchData.companyName, // 企业名称
        legalPersonName: this.searchData.legalPersonName, // 法定代表人姓名
        legalPersonPhone: this.searchData.legalPersonPhone, // 法定代表人手机号
        status: this.searchData.status, // 备案状态 '' 全部  1-审核中  2-审核不通过 3-已通过
        type: this.searchData.type,
        currentPage: this.pageData.currentPage,
        pageSize: this.pageData.pageSize,
      };
      this.$request(
        "post",
        "/agent/page",
        requestData,
        (data) => {
          this.tableData = data.records;
          this.tableData.forEach((item) => {
            let dd = JSON.parse(JSON.stringify(this.tableOper));
            switch (item.status) {
              case "已通过":
                dd[2].statusShow = true;
                dd[3].statusShow = true;
                break;
              case "审核中":
                break;
              case "审核不通过":
                break;
              default:
                break;
            }
            this.$set(item, "tableOper", dd);
          });
          this.pageData.total = data.total;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 重置 page 请求列表数据
     */
    resetPage() {
      this.pageData.currentPage = 1;
      this.initData();
    },
    /**
     * 表格按钮操作事件
     */
    handleClick(item, active) {
      let requestData = {};
      this.tempData = item;
      switch (active) {
        case "show_detail":
          // 查看详情  dialog显示详情,并展示是否通过
          requestData = {
            agentId: item.id,
          };
          this.$request(
            "post",
            "/agent/getAgentInformation",
            requestData,
            (data) => {
              this.dialogData.title = "备案申请";
              this.dialogData.type = "information";
              this.dialogData.data = instructionsData.matchOrderData(
                agentData.getOrderList(),
                data
              );
              this.dialogData.data.splice(0, 1);
              this.dialogData.data[1].data.splice(3, 1);
              this.dialogData.buttonList = [];

              if (this.tempData.status == "审核中") {
                this.dialogData.buttonList = [
                  {
                    name: "审核通过",
                    active: "pass",
                  },
                  {
                    name: "不通过",
                    active: "fail",
                  },
                ];
              } else {
                this.dialogData.buttonList = [
                  {
                    name: "取消",
                    active: "cancel",
                  },
                ];
              }
              this.dialogData.loading = false;
              this.dialogData.flag = true;
            }
          );
          break;
        case "pay_details":
          // 查看代理的支出详情
          this.$baseData.showOperationRecords(
            item.id,
            "agent",
            this,
            (saveData) => {
              this.dialogData.loading = true;
              this.dialogData.flag = true;
              setTimeout(() => {
                this.dialogData.data = saveData;
                this.dialogData.type = "operation";
                this.dialogData.title = "审批记录";
                this.dialogData.buttonList = [];
                this.dialogData.loading = false;
              }, 500);
            }
          );
          break;
        case "pay":
          // 充值
          this.dialogData.loading = true;
          this.dialogData.title = "合作商余额调整";
          this.dialogData.type = "input";
          this.dialogData.buttonList = [
            {
              name: "确定",
              active: "makeSure_pay",
            },
            {
              name: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.flag = true;
          this.$request(
            "get",
            "/user/queryMoney?userId=" + item.userId,
            {},
            (data) => {
              setTimeout(() => {
                this.dialogData.data = agentData.payDialogData();
                this.dialogData.data[0].data[0].value = data;
                console.log(this.dialogData);
                this.dialogData.loading = false;
              }, 500);
            }
          );
          break;
        case "discount":
          // 折扣设置
          this.dialogData.loading = true;
          this.dialogData.title = "合作商折扣调整";
          this.dialogData.type = "input";
          this.dialogData.buttonList = [
            {
              name: "确定",
              active: "makeSure_discount",
            },
            {
              name: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.flag = true;
          this.$request(
            "get",
            "/user/queryDiscount?userId=" + item.userId,
            {},
            (data) => {
              setTimeout(() => {
                this.dialogData.data = agentData.discountDialogData();
                this.dialogData.data[0].data[0].value =
                  data.rechargeDiscountValidity;
                this.dialogData.data[0].data[1].value = data.rechargeDiscount;
                console.log(this.dialogData);
                this.dialogData.loading = false;
              }, 500);
            }
          );
          break;
        default:
          break;
      }
    },
    /**
     * dialog 按钮操作事件
     */
    dialogHandleCilck(active) {
      let requestData = {};
      let flag = false;
      switch (active) {
        case "pass":
          // 审核通过
          requestData = {
            id: this.tempData.id,
            userId: this.userData.userId,
          };
          this.$request("post", "/agent/agreeAgent", requestData, (data) => {
            this.$message({
              message: "审核通过!",
              type: "success",
            });
            this.dialogData.flag = false;
            this.initData();
          });
          break;
        case "fail":
          // 不通过审核
          this.$prompt("请审核意见", "审核意见", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
          })
            .then(({ value }) => {
              requestData = {
                id: this.tempData.id,
                content: value,
                userId: this.userData.userId,
              };
              this.$request(
                "post",
                "/agent/failCheckAgentFile",
                requestData,
                (data) => {
                  this.dialogData.flag = false;
                  this.initData();
                }
              );
            })
            .catch(() => {});
          break;
        case "makeSure_pay":
          // 确定修改合作商余额
          requestData = {
            toUpUserId: this.tempData.userId, //  充值用户 id（需要增加金额的用户 id）
            amount: this.dialogData.data[0].data[1].value, // 充值金额
            payCertificate: this.dialogData.data[0].data[2].value, // 充值凭证
            operationalUserId: this.userData.userId, // 操作用户 id
          };
          if (!requestData.amount) {
            this.$message.error("请输入充值金额!");
            return;
          }
          if (!requestData.payCertificate) {
            this.$message.error("请上传充值凭证!");
            return;
          }
          this.$request(
            "post",
            "/user/topUp",
            requestData,
            (data) => {
              this.$baseData.saveOperationRecord(
                this.tempData.id,
                "充值" + requestData.amount + "元",
                this.userData.userId,
                "agent",
                this,
                (saveData) => {
                  this.$message({
                    message: "余额充值成功!",
                    type: "success",
                  });
                  this.dialogData.flag = false;
                  this.initData();
                },
                requestData.payCertificate // 凭证
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "makeSure_discount":
          // 确认修改折扣
          requestData = {
            userId: this.tempData.userId, // 用户id
            rechargeDiscount: "", // 充值折扣，必须是一个大于 1 的数
            rechargeDiscountValidity: this.dialogData.data[0].data[0].value, // 充值折扣有效期
          };
          requestData.rechargeDiscount = parseFloat(
            this.dialogData.data[0].data[1].value
          );
          if (
            !requestData.rechargeDiscount ||
            requestData.rechargeDiscount < 1
          ) {
            this.$message.error("充值折扣为必填项且必须大于1!");
            return;
          }
          if (!requestData.rechargeDiscountValidity) {
            this.$message.error("充值折扣有效期为必填项!");
          }
          this.$request(
            "post",
            "/user/settingRechargeDiscount",
            requestData,
            (data) => {
              this.$baseData.saveOperationRecord(
                this.tempData.id,
                "修改充值折扣为" + requestData.rechargeDiscount,
                this.userData.userId,
                "agent",
                this,
                (saveData) => {
                  this.$message({
                    message: "充值折扣修改成功!",
                    type: "success",
                  });
                  this.dialogData.flag = false;
                  this.initData();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          // 取消
          this.dialogData.flag = false;
          break;
        default:
          break;
      }
    },
    /**
     * 分配区域时,选择安徽/合肥事件 返回挡圈范围的可选区域
     */
    radioChange(value) {
      console.log(
        "分配区域时,选择安徽/合肥事件 返回挡圈范围的可选区域:",
        value
      );
      let requestData = {
        search: {},
        flag: value,
      };
      this.dialogData.data[0].data[1].options = [];
      this.$request(
        "post",
        "/area/getAllNormalAreaList",
        requestData,
        (data) => {
          this.dialogData.data[0].data[1].options = this.$baseData.getAreaList(
            value,
            data.list
          );
        }
      );
    },
    /**
     * 文件上传前格式判断
     */
    beforeAvatarUpload(file) {
      console.log("上传文件格式:", file.type);
      const isJPG =
        file.type === "application/pdf" ||
        file.type === "image/jpeg" ||
        file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("上传文件只能是 PDF 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    /**
     * 文件上传成功
     */
    handleAvatarSuccess(data) {
      switch (data.response.result.title) {
        case "充值凭证":
          this.dialogData.data[0].data[2].value = data.response.result.path;
          this.dialogData.data[0].data[2].isDisable = true;
          this.dialogData.data[0].data[2].text = data.file.name;
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    let dd = {
      id: "",
      name: "合作商",
      auth: "2",
      path: "/main/agent/agent_management",
    };
    sessionStorage.setItem("childernMenu", JSON.stringify(dd));
    this.getUserAuthority();
  },
};
</script>

<style lang="less" scoped>
#agent_management {
  width: 100%;
  height: 100%;
  .search-line {
    width: 100%;
    height: auto;
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    .el-input {
      width: 23% !important;
      margin-left: 10px !important;
      margin-top: 5px;
      .el-input__inner {
        height: 30px !important;
      }
    }
    .el-select,
    .el-range-editor {
      width: 23% !important;
      margin-left: 10px !important;
      margin-top: 5px;
    }
  }
  .content {
    width: 98%;
    height: 0;
    flex: 1;
    .tip {
      color: @baseColor;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: @activeColor;
      }
    }
  }
  .page {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
  }
  .buts {
    width: 100%;
    height: 50px;
    .but {
      width: auto;
      height: auto;
      padding: 5px 15px;
      margin: 0 10px;
      box-sizing: border-box;
      border-radius: 8px;
      background-color: @baseColor;
      color: #ffffff;
      font-size: @font-size-tip-mini;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        background-color: @but-hoverColor;
      }
    }
  }
  #my-dialog .content {
    width: 98%;
    height: 0;
    flex: 1;
    .content-item {
      width: 33%;
      height: 50%;
      font-size: 14px;
      .content-item-top {
        width: 100%;
        height: 35px;
        position: relative;
        .content-item-top-line {
          width: 100%;
          height: 1px;
          background-color: rgba(160, 160, 160, 0.288);
          position: absolute;
          left: 0;
          top: 10px;
        }
        .content-item-top-title {
          width: auto;
          background-color: #ffffff;
          height: 100%;
          position: absolute;
          left: 30px;
          padding: 0 8px;
          font-size: 14px;
          span {
            color: red;
            margin-left: 8px;
          }
        }
      }
      .content {
        width: 98%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 13px;
        .line {
          width: 100%;
          height: 30px;
          display: flex;
          align-items: center;
          .span {
            width: auto;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 3px;
            box-sizing: border-box;
          }
          .value {
            width: 0;
            flex: 1;
            height: 100%;
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .buts {
      width: 100%;
      height: 50px;
      .but {
        width: auto;
        height: auto;
        padding: 5px 15px;
        margin: 0 10px;
        box-sizing: border-box;
        border-radius: 8px;
        background-color: @baseColor;
        color: #ffffff;
        font-size: @font-size-tip-mini;
        transition: 0.3s;
        &:hover {
          cursor: pointer;
          background-color: @but-hoverColor;
        }
      }
    }
    .tip {
      margin-top: 20px;
      font-size: 14px;
    }
  }
}
</style>
