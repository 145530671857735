<template>
  <div id="agent-address" class="div-flex-column-center" v-loading="loading">
    <!-- 优质号段 -->
    <div class="search-line">
      <el-input
        v-model="searchData.number"
        placeholder="优质编号"
        clearable
        @keyup.enter.native="resetPage"
      ></el-input>
      <el-select
        v-model="searchData.areaId"
        placeholder="区域名称"
        clearable
        @change="resetPage"
      >
        <el-option-group
          v-for="group in alreadyAreaSelectOption"
          :key="group.label"
          :label="group.label"
        >
          <el-option
            v-for="item in group.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-option-group>
      </el-select>
      <el-select
        v-model="searchData.areaFlag"
        placeholder="区域范围"
        clearable
        @change="resetPage"
      >
        <el-option label="安徽省" value="0"></el-option>
        <el-option label="合肥市" value="1"></el-option>
      </el-select>
    </div>
    <div class="content div-flex-center">
      <el-table :data="tableData" border style="width: 90%" height="98%">
        <el-table-column
          :prop="item.key"
          :label="item.title"
          :width="item.width"
          :fixed="item.fixed"
          v-for="(item, index) in tableMenu"
          :key="index"
        >
          <template slot-scope="scope">
            <span v-if="item.key == 'areaFlag'">{{
              scope.row[item.key] == "0" ? "安徽省" : "合肥市"
            }}</span>
            <span v-if="item.key == 'status'">{{
              scope.row["orderId"] ? "已分配" : "未分配"
            }}</span>
            <span v-else>{{ scope.row[item.key] }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page">
      <!-- @current-change="getAllCompanyList" -->
      <el-pagination
        @current-change="initData"
        :current-page.sync="pageData.currentPage"
        :page-size="pageData.pageSize"
        layout="total, prev, pager, next,jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>
    <div id="my-dialog" class="div-flex-center" v-if="dialogData.flag">
      <div
        class="my-dialog-content div-flex-column-center"
        v-loading="dialogData.loading"
      >
        <div class="top div-flex-center">
          <div class="title">{{ dialogData.title }}</div>
          <div class="perch-active"></div>
          <div class="close">
            <i class="el-icon-close" @click="dialogData.flag = false"></i>
          </div>
        </div>
        <div
          class="content div-flex-column-center"
          v-if="dialogData.type == 'allocation'"
        >
          <information_input :informationInputData="dialogData.data">
          </information_input>
        </div>
        <div class="buts div-flex-center">
          <div
            class="but"
            v-for="(item, index) in dialogData.buttonList"
            :key="index"
            @click="dialogHandleCilck(item.active)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as agentData from "../../assets/js/agentData.js";
export default {
  data() {
    return {
      input: "12312",
      loading: false,
      userData: {
        userId: "",
        userName: "",
        icon: "",
      },
      searchData: {
        number: "",
        areaId: "",
        areaFlag: "",
      },
      orderList: agentData.getAssignDialogData(),
      pageData: {
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页条数
        total: 0, // 总条目数
      },
      tableData: [],
      tableMenu: [
        {
          key: "number",
          title: "优质编号",
          width: "150",
          fixed: false,
        },
        {
          key: "orderNo",
          title: "分配订单",
          width: "160",
        },
        {
          key: "status",
          title: "状态",
          width: "100",
        },
        {
          key: "addressPrefix",
          title: "地址前缀",
          width: "350",
          fixed: false,
        },
        {
          key: "addressSuffix",
          title: "地址后缀",
          width: "150",
          fixed: false,
        },
        {
          key: "addressTag",
          title: "地址标签",
          width: "150",
          fixed: false,
        },
        {
          key: "areaName",
          title: "区域名称",
          width: "150",
          fixed: false,
        },
      ],
      tableOper: [
        {
          name: "分配",
          key: "分配",
          active: "allocation",
          show: false, // 由权限控制
          statusShow: true, // 由当前订单的状态控制
        },
      ],
      dialogData: {
        flag: false,
        title: "客户基本信息录入",
        loading: true,
        type: "sign",
        baseData: {
          // 录入基本信息时,填写的表单信息
          title: "", // 自定义表单标题
          id: "", // 自定义表单ID
          time: "", // 时间
        },
        data: [],
        buttonList: [],
      },
      tempData: {},
      areaSelectOption: [],
      alreadyAreaSelectOption: [],
    };
  },
  methods: {
    /**
     * 2.3 获取当前角色权限
     */
    getUserAuthority() {
      this.$baseData.getUserAuthority(this.userData.userId, this, (data) => {
        this.tableOper = this.$baseData.judgeButtonPermissions(
          data[0].model,
          this.tableOper,
          "代理管理",
          "优质号段"
        );
        console.log("当前界面按钮权限:", this.tableOper);
        this.initData();
      });
    },
    // 列表数据
    initData() {
      let requestData = {
        number: this.searchData.number, // 筛选项：优质编号
        areaId: this.searchData.areaId, // 筛选项：区域名称对应的区域 id
        areaFlag: this.searchData.areaFlag, // 筛选项：区域范围标识
        currentPage: this.pageData.currentPage,
        pageSize: this.pageData.pageSize,
      };
      this.$request(
        "post",
        "/addressExcellent/page",
        requestData,
        (data) => {
          this.tableData = data.records;
          this.pageData.total = data.total;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 重置 page 请求列表数据
     */
    resetPage() {
      this.pageData.currentPage = 1;
      this.initData();
    },
    getAreaSelectOption() {
      this.$request(
        "get",
        "/area/getAreaSelectOption?flag=0", // 0 - 查询未代理；1 - 查询已代理；不传查询全部
        {},
        (data) => {
          this.areaSelectOption = data;
          this.$request(
            "get",
            "/area/getAreaSelectOption?flag=1", // 0 - 查询未代理；1 - 查询已代理；不传查询全部
            {},
            (data) => {
              this.alreadyAreaSelectOption = data;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 表格按钮操作事件
     */
    handleClick(item, active) {
      let requestData = {};
      this.tempData = item;
      switch (active) {
        case "allocation":
          // 分配
          if (this.tempData.orderId) {
            this.$message.error("该优质号段已分配!");
            return;
          }
          this.dialogData.loading = true;
          this.dialogData.title = "分配优质号段";
          this.dialogData.type = "allocation";
          this.dialogData.buttonList = [
            {
              name: "确认",
              active: "makeSure_allocation",
            },
            {
              name: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              title: "分配优质号段",
              tip: "输入订单编号分配优质号段",
              data: [
                {
                  title: "订单编号:",
                  tip: true, // 是否不得为空
                  type: "input",
                  key: "registeredCapital",
                  value: "",
                  options: [],
                  landscape: false, // 是否横屏显示
                  placeholder: "请输入订单编号", // 提示文字
                },
              ],
            },
          ];
          this.dialogData.loading = false;
          this.dialogData.flag = true;
          break;
        default:
          break;
      }
    },
    /**
     * dialog 按钮操作事件
     */
    dialogHandleCilck(active) {
      let requestData = {};
      let flag = false;
      switch (active) {
        case "makeSure_allocation":
          // 确认分配
          this.$confirm(
            "该地址编号将会分配给输入订单的地址编号, 是否确认?",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              this.dialogData.loading = true;
              requestData = {
                userId: this.userData.userId, // 用户id
                orderNo: this.dialogData.data[0].data[0].value, // 订单编号
                addressExcellentId: this.tempData.id, // 优质地址 id
              };
              this.$request(
                "post",
                "/addressExcellent/assign",
                requestData,
                (data) => {
                  this.dialogData.loading = false;
                  this.dialogData.flag = false;
                  this.$message.success("分配成功!");
                  this.initData();
                },
                (errorMessage) => {
                  this.dialogData.loading = false;
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;
        case "cancel":
          // 取消
          this.dialogData.flag = false;
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    let dd = {
      id: "",
      name: "优质号段",
      auth: "2",
      path: "/main/agent/agent_address",
    };
    sessionStorage.setItem("childernMenu", JSON.stringify(dd));
    this.getUserAuthority();
    this.getAreaSelectOption();
  },
};
</script>

<style lang="less" scoped>
#agent-address {
  width: 100%;
  height: 100%;
  .search-line {
    width: 100%;
    height: auto;
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    .el-input {
      width: 23% !important;
      margin-left: 10px !important;
      margin-top: 5px;
      .el-input__inner {
        height: 30px !important;
      }
    }
    .el-select,
    .el-range-editor {
      width: 23% !important;
      margin-left: 10px !important;
      margin-top: 5px;
    }
  }
  .content {
    width: 98%;
    height: 0;
    flex: 1;
    .tip {
      color: @baseColor;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: @activeColor;
      }
    }
  }
  .page {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
  }
  .buts {
    width: 100%;
    height: 50px;
    .but {
      width: auto;
      height: auto;
      padding: 5px 15px;
      margin: 0 10px;
      box-sizing: border-box;
      border-radius: 8px;
      background-color: @baseColor;
      color: #ffffff;
      font-size: @font-size-tip-mini;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        background-color: @but-hoverColor;
      }
    }
  }
  #my-dialog .content {
    width: 98%;
    height: 0;
    flex: 1;
    .content-item {
      width: 33%;
      height: 50%;
      font-size: 14px;
      .content-item-top {
        width: 100%;
        height: 35px;
        position: relative;
        .content-item-top-line {
          width: 100%;
          height: 1px;
          background-color: rgba(160, 160, 160, 0.288);
          position: absolute;
          left: 0;
          top: 10px;
        }
        .content-item-top-title {
          width: auto;
          background-color: #ffffff;
          height: 100%;
          position: absolute;
          left: 30px;
          padding: 0 8px;
          font-size: 14px;
          span {
            color: red;
            margin-left: 8px;
          }
        }
      }
      .content {
        width: 98%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 13px;
        .line {
          width: 100%;
          height: 30px;
          display: flex;
          align-items: center;
          .span {
            width: auto;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 3px;
            box-sizing: border-box;
          }
          .value {
            width: 0;
            flex: 1;
            height: 100%;
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .buts {
      width: 100%;
      height: 50px;
      .but {
        width: auto;
        height: auto;
        padding: 5px 15px;
        margin: 0 10px;
        box-sizing: border-box;
        border-radius: 8px;
        background-color: @baseColor;
        color: #ffffff;
        font-size: @font-size-tip-mini;
        transition: 0.3s;
        &:hover {
          cursor: pointer;
          background-color: @but-hoverColor;
        }
      }
    }
    .tip {
      margin-top: 20px;
      font-size: 14px;
    }
  }
}
</style>
