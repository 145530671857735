<template>
  <div id="order-management" class="div-flex-column-center" v-loading="loading">
    <!-- 订单管理 -->
    <div class="search-line">
      <el-input
        v-model="search.companyName"
        placeholder="企业名称"
        clearable
        @keyup.enter.native="resetPage"
      ></el-input>
      <el-input
        v-model="search.legalPersonName"
        placeholder="法定代表人"
        clearable
        @keyup.enter.native="resetPage"
      ></el-input>
      <el-input
        v-model="search.legalPersonPhone"
        placeholder="法定代表人手机号"
        clearable
        @keyup.enter.native="resetPage"
      ></el-input>
      <el-input
        v-model="search.orderNo"
        placeholder="订单编号"
        clearable
        @keyup.enter.native="resetPage"
      ></el-input>
      <el-select
        v-model="search.theAreaId"
        filterable
        clearable
        placeholder="请选择区域"
        @change="resetPage"
      >
        <el-option-group
          v-for="group in search.alreadyAreaSelectOption"
          :key="group.label"
          :label="group.label"
        >
          <el-option
            v-for="item in group.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-option-group>
      </el-select>
      <el-select
        v-model="search.industryType"
        clearable
        filterable
        placeholder="请选择行业分类"
        @change="resetPage"
      >
        <el-option
          v-for="item in search.industryList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-date-picker
        v-model="search.submitTime"
        type="daterange"
        value-format="yyyy-MM-dd"
        range-separator="至"
        start-placeholder="提交范围"
        end-placeholder="提交范围"
        @change="changeTime"
      >
      </el-date-picker>
    </div>
    <div class="buts div-flex-center">
      <div
        :class="{
          but: true,
          'div-flex-center': true,
          'but-active': item.status == search.status,
        }"
        @click="changeStatus(item.status)"
        v-for="(item, index) in statusButs"
        :key="index"
      >
        {{ item.name }}
      </div>
      <div class="perch-active"></div>
    </div>
    <div class="content div-flex-center">
      <el-table :data="tableData" border style="width: 90%" height="98%">
        <el-table-column
          :prop="item.key"
          :label="item.title"
          :width="item.width"
          :fixed="item.key == 'orderNo' ? true : item.fixed"
          v-for="(item, index) in tableMenu"
          :key="index"
          :align="item.fixed ? 'center' : 'left'"
        >
          <template slot-scope="scope">
            <span
              v-if="item.key == 'orderNo'"
              class="tip"
              @click="showDetails(scope.row, 0)"
              >{{ scope.row[item.key] }}</span
            >
            <span v-else>{{ scope.row[item.key] }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row, item.active)"
              type="text"
              size="small"
              v-for="(item, index) in scope.row.tableOper"
              :key="index"
              v-show="item.show && item.statusShow"
              >{{ item.name }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page">
      <el-pagination
        @current-change="getAllOrderList"
        :current-page.sync="pageData.currentPage"
        :page-size="pageData.pageSize"
        layout="total, prev, pager, next,jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>
    <div id="my-dialog" class="div-flex-center" v-if="dialogData.flag">
      <div
        class="my-dialog-content div-flex-column-center"
        v-loading="dialogData.loading"
      >
        <div class="top div-flex-center">
          <div class="title">{{ dialogData.title }}</div>
          <div class="perch-active"></div>
          <div class="close" v-if="dialogData.closeFlag">
            <i class="el-icon-close" @click="dialogData.flag = false"></i>
          </div>
        </div>

        <div
          class="content div-flex-center div-flex-wrap div-flex-between"
          v-if="dialogData.type == 'show_information'"
        >
          <div
            class="content-item div-flex-column-center"
            v-for="(item, index) in dialogData.data"
            :key="index"
          >
            <div class="content-item-top">
              <div class="content-item-top-line"></div>
              <div class="content-item-top-title">{{ item.title }}</div>
            </div>

            <div class="content" v-if="dialogData.type == 'show_information'">
              <div class="line" v-for="(it, ii) in item.data" :key="ii">
                <div class="span">{{ it.title }}</div>
                <div class="value" v-if="it.type != 'file'">{{ it.value }}</div>
                <div class="value file_text" v-else @click="openPdf(it.value)">
                  {{ it.text }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="content" v-if="dialogData.type == 'input'">
          <information_input
            :informationInputData="dialogData.data"
            @beforeAvatarUpload="beforeAvatarUpload"
            @handleAvatarSuccessByIdCard="handleAvatarSuccessByIdCard"
            @handleAvatarSuccess="handleAvatarSuccess"
            @deleteItem="deleteItem"
          >
          </information_input>
        </div>
        <div class="content" v-if="dialogData.type == 'show_text'">
          <information_text :informationTextData="dialogData.data">
          </information_text>
        </div>

        <div
          class="content div-flex-column-center"
          v-if="dialogData.type == 'show_img'"
        >
          <el-image
            style="width: 350px; height: 350px"
            :src="dialogData.url"
            fit="contain"
          ></el-image>

          <div class="tip" @click="dialogHandleCilck('qrcode')">二维码下载</div>
        </div>
        <div class="content" v-if="dialogData.type == 'operation'">
          <OperationData :operationData="dialogData.data"></OperationData>
        </div>
        <div class="content" v-if="dialogData.type == 'overdueList'">
          <overdueList :overdueList="dialogData.data"></overdueList>
        </div>
        <div
          class="content"
          v-if="dialogData.type == 'renew'"
          style="width: 100%"
        >
          <div class="renew">
            <span>选择开始续签时间: </span>
            <el-date-picker
              v-model="renewDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
        </div>
        <div
          class="content"
          v-if="dialogData.type == 'allocation'"
          style="width: 100%"
        >
          <div class="allocation">
            <div class="allocation-title">选择优质号段:</div>
            <div class="allocation-value">
              <el-select
                v-model="AddressByFront"
                placeholder="请选择号段前缀"
                @change="queryUsedNum"
                filterable
              >
                <el-option
                  v-for="item in AddressByFrontList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="allocation-value">
              <el-select
                v-model="AddressByBehind"
                placeholder="请选择号段后缀"
                filterable
              >
                <el-option
                  v-for="item in AddressByBehindList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="buts div-flex-center">
          <div
            class="but"
            v-for="(item, index) in dialogData.buttonList"
            :key="index"
            @click="dialogHandleCilck(item.active)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as instructionsData from "../../assets/js/instructions.js";
import OperationData from "@/components/operation.vue";
import overdueList from "@/components/overdueList.vue";
import * as tempOrderData from "./order.js";
export default {
  components: { OperationData, overdueList },
  data() {
    return {
      renewDate: "",
      loading: false,
      userData: {
        userId: "",
        userName: "",
        icon: "",
      },
      userAuthority: "",
      pageData: {
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页条数
        total: 0, // 总条目数
      },
      search: {
        companyName: "", // 企业名称
        legalPersonName: "", // 法定代表人姓名
        legalPersonPhone: "", // 法定代表人手机号
        orderNo: "", // 订单编号
        status: "-1", // 订单状态 -1 全部 0 待提交 2 代签名 1 待审核 3 已退回 4 已完成
        theAreaId: "", // 区域ID
        // areaList: [], // 区域列表
        industryType: "", // 行业分类
        industryList: [], // 行业列表
        submitTime: [], // 提交时间筛选
        submitTime1: "", // 提交时间（最小） 年月日
        submitTime2: "", // 提交时间（最大） 年月日
        alreadyAreaSelectOption: [],
      },
      statusButs: [
        {
          name: "待提交申请(草稿)",
          key: "查看",
          status: "0",
          show: false, // 由权限控制
        },
        {
          name: "待签名申请",
          key: "查看",
          status: "2",
          show: false, // 由权限控制
        },
        {
          name: "待审核申请",
          key: "查看",
          status: "1",
          show: false, // 由权限控制
        },
        {
          name: "已退回申请",
          key: "查看",
          status: "3",
          show: false, // 由权限控制
        },
        {
          name: "已完成申请",
          key: "查看",
          status: "4",
          show: false, // 由权限控制
        },
        {
          name: "已过期申请",
          key: "查看",
          status: "-2",
          show: false, // 由权限控制
        },
        {
          name: "已作废申请",
          key: "查看",
          status: "5",
          show: false, // 由权限控制
        },
        {
          name: "已续签申请",
          key: "查看",
          status: "6",
          show: false, // 由权限控制
        },
        {
          name: "全部申请",
          key: "查看",
          status: "-1",
          show: false, // 由权限控制
        },
      ],
      tableData: [],
      tableMenu: tempOrderData.getTableMenuData(),
      tableOper: [
        {
          name: "签名二维码",
          key: "签名",
          active: "sign",
          show: false, // 由权限控制
          statusShow: false, // 由当前订单的状态控制
        },
        {
          name: "编辑",
          key: "编辑",
          active: "update",
          show: false, // 由权限控制
          statusShow: false, // 由当前订单的状态控制
        },
        {
          name: "查看审批记录",
          key: "查看",
          active: "show",
          show: true, // 由权限控制
          statusShow: false, // 由当前订单的状态控制
        },
        {
          name: "删除",
          key: "删除",
          active: "delete",
          show: false, // 由权限控制
          statusShow: false, // 由当前订单的状态控制
        },
        {
          name: "审核",
          key: "审核",
          active: "check",
          show: false, // 由权限控制
          statusShow: false, // 由当前订单的状态控制
        },
        {
          name: "下载PDF",
          key: "下载",
          active: "download",
          show: false, // 由权限控制
          statusShow: false, // 由当前订单的状态控制
        },
        {
          name: "续签",
          key: "续签",
          active: "renew",
          show: false, // 由权限控制
          statusShow: false, // 由当前订单的状态控制
        },
        {
          name: "订单作废",
          key: "订单作废",
          active: "cancellation",
          show: false, // 由权限控制
          statusShow: false, // 由当前订单的状态控制
        },
        {
          name: "电子合同重新生成",
          key: "电子合同重新生成",
          active: "rebuild",
          show: false, // 由权限控制
          statusShow: false, // 由当前订单的状态控制
        },
        {
          name: "订单修改",
          key: "订单修改",
          active: "edit",
          show: false, // 由权限控制
          statusShow: false, // 由当前订单的状态控制
        },
        {
          name: "分配优质号段",
          key: "分配优质号段",
          active: "allocation",
          show: false, // 由权限控制
          statusShow: false, // 由当前订单的状态控制
        },
        {
          name: "企业备注",
          key: "企业备注",
          active: "remark",
          show: false, // 由权限控制
          statusShow: false, // 由当前订单的状态控制
        },
      ],
      dialogData: {
        loading: true,
        closeFlag: true,
        flag: false,
        title: "",
        type: "show_information",
        data: [],
        url: require("../../assets/img/qr_code.jpg"),
        buttonList: "",
      },
      tempOrder: {}, // 临时表格数据
      timer: null,
      countdown: 3,
      count: 0,
      AddressByFront: "A", // 优质号段前缀
      AddressByBehind: "", // 优质号段后缀
      AddressByFrontList: this.$baseData.getGoodAddressByFront(),
      AddressByBehindList: [],
    };
  },
  methods: {
    /**
     * 2.3 获取当前角色权限
     */
    getUserAuthority() {
      this.$baseData.getUserAuthority(this.userData.userId, this, (data) => {
        this.userAuthority = data;
        this.tableOper = this.$baseData.judgeButtonPermissions(
          data[0].model,
          this.tableOper,
          "业务办理",
          "订单管理"
        );
        this.statusButs = this.$baseData.judgeButtonPermissions(
          data[0].model,
          this.statusButs,
          "业务办理",
          "订单管理"
        );
        console.log("按钮权限", this.tableOper);
        console.log("statusButs", this.statusButs);
        this.getAreaSelectOption();
        // this.alreadyAreaSelectOption();
      });
    },
    /**
     * 获取所有区域列表
     */
    getAreaSelectOption() {
      this.$request(
        "get",
        "/area/getAreaSelectOption?flag=1", // 0 - 查询未代理；1 - 查询已代理；不传查询全部
        {},
        (data) => {
          this.search.alreadyAreaSelectOption = data;
          this.getAllIndustry();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有行业分类列表
     */
    getAllIndustry() {
      let requestData = {
        name: "",
      };
      this.search.industryList = [];
      this.$request(
        "post",
        "/dictionary/getAllIndustry",
        requestData,
        (data) => {
          if (data.length > 0) {
            data.forEach((element) => {
              let dd = {
                label: element,
                value: element,
              };
              this.search.industryList.push(dd);
            });
          }
          this.getRoleInfo();
          // this.getAllOrderList();
        }
      );
    },
    /**
     * 获取当前用户角色
     */
    getRoleInfo() {
      let requestData = {
        id: this.userData.userId,
      };
      this.$request("post", "/user/getRoleInfo", requestData, (data) => {
        this.getAllOrderList();
      });
    },
    /**
     * 6.6 获取所有订单业务
     */
    getAllOrderList() {
      this.loading = true;
      let requestData = {
        userId: this.userData.userId,
        search: this.search,
        currentPage: this.pageData.currentPage,
        pageSize: this.pageData.pageSize,
      };
      this.$request(
        "post",
        "/orders/getAllOrderList",
        requestData,
        (data) => {
          this.search.expire = false;
          this.pageData.total = data.total;
          this.tableData = data.tableData;
          let but = [];
          this.tableData.forEach((element) => {
            this.$set(
              element,
              "statusName",
              instructionsData.getStatusName(element.status)
            );
            but = JSON.parse(JSON.stringify(this.tableOper));
            this.$set(
              element,
              "tableOper",
              instructionsData.getStatusByData(but, element.status)
            );
          });
          console.log("tableOper", this.tableOper);
          this.loading = false;
          if (this.count == 0) {
            this.count++;
            for (let i = 0; i < this.userAuthority.length; i++) {
              if (
                this.userAuthority[i].roleId == "2" ||
                this.userAuthority[i].roleId == "3" ||
                this.userAuthority[i].roleId == "5"
              ) {
                this.showOverdueList();
                return;
              }
            }
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
        // "https://crm.hfjunling.com/报销附件/20240305/14-30-43_tmp_30bd2cfe639d23e8c04352b963513a1cf7e360a0da08ddfd_1709620243541.jpg"
        // "https://crm.hfjunling.com/报销附件/20240305/14-30-46_tmp_e07e0d8e10ba0ccadbf26b5d7ef225dc1e4a216534735bfe_1709620246889.jpg"
      );
    },
    /**
     * 6.6 重置 page 请求列表数据
     */
    resetPage() {
      this.pageData.currentPage = 1;
      this.getAllOrderList();
    },
    /**
     * 筛选时间改变事件
     */
    changeTime() {
      if (this.search.submitTime.length == 2) {
        this.search.submitTime1 = this.search.submitTime[0];
        this.search.submitTime2 = this.search.submitTime[1];
      } else {
        this.search.submitTime1 = "";
        this.search.submitTime2 = "";
      }
      this.getAllOrderList();
    },
    /**
     * 查询状态切换
     */
    changeStatus(status) {
      this.search.status = status;
      this.getAllOrderList();
    },
    /**
     * 表格数据的点击事件
     */
    handleClick(item, active) {
      let requestData = {};
      this.tempOrder = item;
      switch (active) {
        case "sign":
          // 签名
          this.dialogData.loading = true;
          requestData = {
            id: item.id,
          };
          this.$request(
            "post",
            "/orders/getQRCodePath",
            requestData,
            (data) => {
              setTimeout(() => {
                this.dialogData.title = "二维码";
                this.dialogData.type = "show_img";
                this.dialogData.url = data.qrCode;
                this.dialogData.buttonList = [];
                this.dialogData.loading = false;
              }, 500);
              this.dialogData.flag = true;
            }
          );
          break;
        case "update":
          // 修改申请
          let path =
            item.type == "新设立"
              ? "/main/order/create_order"
              : item.type == "续签"
              ? "/main/order/renew_order"
              : "/main/order/tomovein_order";
          this.$router.push({
            path: path,
            query: {
              id: item.id,
            },
          });
          break;
        case "edit":
          // 订单修改
          this.dialogData.loading = true;
          this.dialogData.title = "修改订单信息";
          this.dialogData.type = "input";
          this.dialogData.buttonList = [
            {
              name: "确认",
              active: "makeSure_edit",
            },
            {
              name: "取消",
              active: "cancel",
            },
          ];
          requestData = {
            id: this.tempOrder.id,
          };
          this.$request(
            "post",
            "/orders/getOrderDetailsById",
            requestData,
            (data) => {
              this.dialogData.data = instructionsData.matchOrderData(
                instructionsData.editOrderData(),
                data
              );
              setTimeout(() => {
                this.dialogData.loading = false;
                this.dialogData.flag = true;
              }, 1000);
            }
          );
          break;
        case "show":
          // 查看审批记录
          this.$baseData.showOperationRecords(
            item.id,
            "order",
            this,
            (saveData) => {
              console.log("当前操作记录:", saveData);
              this.dialogData.loading = true;
              setTimeout(() => {
                this.dialogData.data = saveData;
                this.dialogData.type = "operation";
                this.dialogData.title = "审批记录";
                this.dialogData.buttonList = [];
                this.dialogData.loading = false;
              }, 500);
              this.dialogData.flag = true;
            }
          );
          break;
        case "delete":
          // 删除申请
          requestData = {
            id: item.id,
          };
          this.$request("post", "/orders/delOrder", requestData, (data) => {
            this.$message({
              message: "订单删除成功!",
              type: "success",
            });
            this.getAllOrderList();
          });
          break;
        case "check":
          // 审核
          this.showDetails(item, 0);
          break;
        case "download":
          // 下载PDF
          window.open(item.pdfUrl, "_blank");
          break;
        case "renew":
          this.dialogData.loading = true;
          this.dialogData.title = "业务续签";
          this.dialogData.type = "renew";
          this.dialogData.buttonList = [
            {
              name: "确认",
              active: "makeSure_renew",
            },
            {
              name: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [];
          this.dialogData.loading = false;
          this.dialogData.flag = true;
          break;
        case "cancellation":
          // 订单作废
          this.$confirm("此操作将会作废该订单, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.loading = true;
              this.$request(
                "get",
                `/orders/orderVoid?orderId=${this.tempOrder.id}&userId=${this.userData.userId}`,
                {},
                (data) => {
                  this.$baseData.saveOperationRecord(
                    this.tempOrder.id,
                    "订单作废!",
                    this.userData.userId,
                    "order",
                    this,
                    (saveData) => {
                      this.$message.success("订单作废成功!");
                      this.dialogData.flag = false;
                      this.getAllOrderList();
                    }
                  );
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;
        case "rebuild":
          // 电子合同重新生成
          this.$confirm("此操作将会重新生成电子合同, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.loading = true;
              this.$request(
                "get",
                `/orders/regenerateContract?orderId=${this.tempOrder.id}`,
                {},
                (data) => {
                  this.$baseData.saveOperationRecord(
                    this.tempOrder.id,
                    "电子合同重新生成!",
                    this.userData.userId,
                    "order",
                    this,
                    (saveData) => {
                      this.$message.success("电子合同重新生成成功!");
                      this.dialogData.flag = false;
                      this.getAllOrderList();
                    }
                  );
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;
        case "allocation":
          // 分配
          this.dialogData.loading = true;
          this.dialogData.title = "分配优质号段";
          this.dialogData.type = "allocation";
          this.dialogData.buttonList = [
            {
              name: "确认",
              active: "makeSure_allocation",
            },
            {
              name: "取消",
              active: "cancel",
            },
          ];
          this.$request(
            "get",
            "/addressExcellent/queryUsedNum?addressId=" +
              this.tempOrder.addressId,
            requestData,
            (data) => {
              // 1. 筛选出已经使用的 和选择前缀字母相对应的 优质号段 去除前缀字母
              let hasBeenResult = data.usedNum
                .filter((item) => item.startsWith(this.AddressByFront))
                .map((item) => item.slice(1));
              console.log("hasBeenResult", hasBeenResult);
              // 2. 筛选出未使用的优质号段
              let result = data.excellentNum.filter(
                (item) => !hasBeenResult.includes(item)
              );
              this.AddressByBehindList = result.map((item) => {
                return {
                  value: item,
                  label: item,
                };
              });
              this.AddressByFront = "A"; // 优质号段前缀
              this.AddressByBehind = ""; // 优质号段后缀
              this.dialogData.data = [];
              this.dialogData.loading = false;
              this.dialogData.flag = true;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "remark":
          this.dialogData.loading = true;
          this.dialogData.title = "企业备注";
          this.dialogData.type = "input";
          this.dialogData.buttonList = [
            {
              name: "确认",
              active: "makeSure_remark",
            },
            {
              name: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              title: "企业备注与附件",
              tip: "",
              data: [
                {
                  title: "备注",
                  tip: true,
                  type: "textarea-input",
                  value: this.tempOrder.remark,
                  landscape: true, // 是否横屏显示
                  placeholder: "请输入备注内容", // 提示文字
                },
                {
                  title: "充值凭证:",
                  tip: true, // 是否不得为空
                  type: "file_list", // 仅文字展示
                  value: JSON.parse(this.tempOrder.annex) || [],
                  options: [],
                  landscape: true, // 是否横屏显示
                  placeholder: "选择附件", // 提示文字
                  key: "businessLicense",
                  data: {
                    systemName: "cluster_register",
                    path: "",
                    title: "企业附件",
                  },
                  isDisable: false,
                  text: "", // 文件名称  显示在界面上的
                },
              ],
            },
          ];
          this.dialogData.loading = false;
          this.dialogData.flag = true;
          break;
        default:
          break;
      }
    },
    // 查询所选前缀字母相对应的 优质号段
    queryUsedNum() {
      this.$request(
        "get",
        "/addressExcellent/queryUsedNum?addressId=" + this.tempOrder.addressId,
        {},
        (data) => {
          let hasBeenResult = data.usedNum
            .filter((item) => item.startsWith(this.AddressByFront))
            .map((item) => item.slice(1));
          let result = data.excellentNum.filter(
            (item) => !hasBeenResult.includes(item)
          );
          this.AddressByBehindList = result.map((item) => {
            return {
              value: item,
              label: item,
            };
          });
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 下载PDF
     */
    openPdf(item) {
      window.open(item, "_blank");
    },
    /**
     * dialog 按钮点击事件
     */
    dialogHandleCilck(active) {
      let requestData = {};
      switch (active) {
        case "qrcode":
          // 二维码下载
          this.downloadIamge(this.dialogData.url, "二维码");
          break;
        case "check":
          // 审核
          this.$confirm("是否确认审核,并生成电子合同?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.loading = true;
              requestData = {
                id: this.tempOrder.id,
                userId: this.userData.userId,
                status: true,
                suggest: "",
              };
              this.$request(
                "post",
                "/orders/orderReview",
                requestData,
                (data) => {
                  this.$baseData.saveOperationRecord(
                    this.tempOrder.id,
                    "订单审核!",
                    this.userData.userId,
                    "order",
                    this,
                    (saveData) => {
                      this.$message({
                        message:
                          "订单审核通过,请通知客户进行电子合同签名,并下载!",
                        type: "success",
                      });
                      this.dialogData.flag = false;
                      this.getAllOrderList();
                    }
                  );
                },
                (errorMessage) => {
                  this.loading = false;
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
          break;
        case "pass":
          // 审核不通过 被打回
          this.$prompt("请输入审核意见", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
          })
            .then(({ value }) => {
              this.loading = true;
              requestData = {
                id: this.tempOrder.id,
                userId: this.userData.userId,
                status: false,
                suggest: value,
              };
              this.$request(
                "post",
                "/orders/orderReview",
                requestData,
                (data) => {
                  this.$baseData.saveOperationRecord(
                    this.tempOrder.id,
                    "订单打回!",
                    this.userData.userId,
                    "order",
                    this,
                    (saveData) => {
                      this.$message({
                        message: "订单已驳回,请通知客户修改,并再次提交!",
                        type: "success",
                      });
                      this.dialogData.flag = false;
                      this.getAllOrderList();
                    }
                  );
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "取消输入",
              });
            });
          break;
        case "makeSure_renew":
          requestData = {
            id: this.tempOrder.id,
            startTime: this.renewDate,
          };
          if (requestData.startTime == "") {
            this.$message.error("请选择续签时间!");
            return;
          }
          this.$request(
            "post",
            "/orders/renewal",
            requestData,
            (data) => {
              let requestData2 = {
                orderId: data.id,
                startTime: this.renewDate, // 续签开始时间
                term: "1", // 订单期限
              };
              this.$request(
                "post",
                "/orders/createNewOrderSubmit",
                requestData2,
                (data2) => {
                  this.$baseData.saveOperationRecord(
                    this.tempOrder.id,
                    "续签订单, 新订单编号: " + data.orderNo,
                    this.userData.userId,
                    "order",
                    this,
                    (saveData) => {
                      this.$message.success("续签成功!");
                      this.renewDate = "";
                      this.dialogData.flag = false;
                    }
                  );
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "makeSure_edit":
          let flag = false;
          this.dialogData.data.forEach((item) => {
            item.data.forEach((it) => {
              if (
                (it.type == "file_idCard" && it.value[0] == "") ||
                it.value[1] == ""
              ) {
                flag = true;
              }
            });
          });
          if (flag) {
            return this.$message.error("请上传身份证件!");
          }
          requestData = {
            ...instructionsData.formatSubmitData(this.dialogData.data, this),
            id: this.tempOrder.id,
          };
          this.$request(
            "post",
            "/orders/updateOrders",
            requestData,
            (data) => {
              this.$message.success("修改成功!");
              this.dialogData.flag = false;
              this.getAllOrderList();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "makeSure_allocation":
          // 确认分配优质号段
          this.dialogData.loading = true;
          requestData = {
            userId: this.userData.userId, // 用户id
            orderId: this.tempOrder.id, // 订单编号
            num: this.AddressByFront + this.AddressByBehind, // 优质地址 id
          };
          this.$request(
            "post",
            "/addressExcellent/assign",
            requestData,
            (data) => {
              this.$baseData.saveOperationRecord(
                this.tempOrder.id,
                "分配地址编号:" + this.AddressByFront + this.AddressByBehind,
                this.userData.userId,
                "order",
                this,
                (saveData) => {
                  this.dialogData.loading = false;
                  this.dialogData.flag = false;
                  this.$message.success("分配成功!");
                  this.getAllOrderList();
                }
              );
            },
            (errorMessage) => {
              this.dialogData.loading = false;
              this.$message.error(errorMessage);
            }
          );
          break;
        case "makeSure_remark":
          requestData = {
            remark: this.dialogData.data[0].data[0].value,
            annex: JSON.stringify(this.dialogData.data[0].data[1].value),
            id: this.tempOrder.id,
          };
          this.$request(
            "post",
            "/orders/updateOrders",
            requestData,
            (data) => {
              this.$message.success("修改成功!");
              this.dialogData.flag = false;
              this.getAllOrderList();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          // 取消
          if (!this.timer) {
            this.dialogData.flag = false;
            this.dialogData.closeFlag = true;
          }
          break;

        default:
          break;
      }
    },
    /**
     * 图片下载
     */
    downloadIamge(imgsrc, name) {
      //下载图片地址和图片名

      var image = new Image();

      // 解决跨域 Canvas 污染问题

      image.setAttribute("crossOrigin", "anonymous");

      image.onload = function() {
        var canvas = document.createElement("canvas");

        canvas.width = image.width;

        canvas.height = image.height;

        var context = canvas.getContext("2d");

        context.drawImage(image, 0, 0, image.width, image.height);

        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据

        var a = document.createElement("a"); // 生成一个a元素

        var event = new MouseEvent("click"); // 创建一个单击事件

        a.download = name || "photo"; // 设置图片名称

        a.href = url; // 将生成的URL设置为a.href属性

        a.dispatchEvent(event); // 触发a的单击事件
      };

      image.src = imgsrc;
    },
    /**
     *  6.9 根据订单编号查看订单详情
     */
    showDetails(item, flag) {
      this.tempOrder = item;
      let requestData = {
        id: item.id,
      };
      let this_ = this;
      this.dialogData.loading = true;
      this.$request(
        "post",
        "/orders/getOrderDetailsById",
        requestData,
        (data) => {
          switch (flag) {
            case 0:
              // 从订单编号进入
              this.dialogData.title =
                "订单编号:" + item.orderNo + "; 状态:" + data.status;
              this.dialogData.data = instructionsData.matchOrderData(
                instructionsData.getOrderData(),
                data
              );
              this.dialogData.type = "show_text";
              if (data.status == "待审核") {
                if (this.tableOper[4].show) {
                  this.dialogData.buttonList = [
                    {
                      name: "通过",
                      active: "check",
                    },
                    {
                      name: "驳回",
                      active: "pass",
                    },
                  ];
                } else {
                  this.dialogData.buttonList = [];
                }
              } else {
                this.dialogData.buttonList = [];
              }
              this.dialogData.flag = true;
              setTimeout(() => {
                this_.dialogData.loading = false;
              }, 500);
              break;
            default:
              break;
          }
        }
      );
    },
    showOverdueList() {
      this.$request(
        "get",
        "/orders/getExpiredList?userId=" + this.userData.userId,
        {},
        (data) => {
          if (data.length > 0) {
            this.dialogData.loading = true;
            this.dialogData.closeFlag = false;
            this.dialogData.data = data;
            this.dialogData.buttonList = [];
            this.dialogData.type = "overdueList";
            this.dialogData.title = "过期业务";
            if (!this.timer) {
              this.timer = setInterval(() => {
                if (this.countdown > 0 && this.countdown <= 4) {
                  this.countdown--;
                  if (this.countdown !== 0) {
                    this.dialogData.buttonList = [
                      {
                        name: "取消 (" + this.countdown + ") ",
                        active: "cancel",
                      },
                    ];
                  } else {
                    clearInterval(this.timer);
                    this.dialogData.buttonList = [
                      {
                        name: "取消",
                        active: "cancel",
                      },
                    ];
                    this.countdown = 3;
                    this.timer = null;
                  }
                }
              }, 1000);
            }
            this.dialogData.loading = false;
            this.dialogData.flag = true;
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 文件上传前格式判断
     */
    beforeAvatarUpload(file) {
      console.log("上传文件格式:", file.type);
      const isJPG =
        file.type === "application/pdf" ||
        file.type === "image/jpeg" ||
        file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("上传文件只能是 PDF 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 3MB!");
      }
      return isJPG && isLt2M;
    },
    handleAvatarSuccessByIdCard(data) {
      let path = data.response.result.path;
      let name = data.file.name;
      switch (data.response.result.title) {
        case "承租方身份证明附件":
          this.$set(this.dialogData.data[0].data[0].value, data.index, path);
          this.$set(this.dialogData.data[0].data[0].text, data.index, name);
          break;
        case "身份证明附件":
          this.$set(this.dialogData.data[1].data[0].value, data.index, path);
          this.$set(this.dialogData.data[1].data[0].text, data.index, name);
          break;
        default:
          break;
      }
    },
    /**
     * 文件上传成功
     */
    handleAvatarSuccess(data) {
      switch (data.response.result.title) {
        case "企业附件":
          this.dialogData.data[0].data[1].value.push(data.response.result.path);
          break;
        default:
          break;
      }
    },
    /**
     * 删除附件
     */
    deleteItem(index) {
      this.dialogData.data[0].data[1].value.splice(index, 1);
    },
  },
  mounted() {
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    console.log("userData", this.userData);
    let dd = {
      id: "",
      name: "订单管理",
      auth: "2",
      path: "/main/order/order_management",
    };
    sessionStorage.setItem("childernMenu", JSON.stringify(dd));
    this.getUserAuthority();
  },
};
</script>

<style lang="less" scoped>
#order-management {
  width: 100%;
  height: 100%;
  .search-line {
    width: 100%;
    height: auto;
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    .el-input {
      width: 23% !important;
      height: 30px !important;
      margin-left: 10px !important;
      margin-top: 5px;
      .el-input__inner {
        height: 30px !important;
      }
    }
    .el-select,
    .el-range-editor {
      width: 23% !important;
      margin-left: 10px !important;
      margin-top: 5px;
    }
  }
  .buts {
    width: 100%;
    height: 50px;
    .but {
      width: auto;
      height: auto;
      padding: 5px 10px;
      margin: 0 10px;
      box-sizing: border-box;
      border-radius: 8px;
      background-color: @baseColor;
      color: #ffffff;
      font-size: @font-size-tip-mini;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        background-color: @but-hoverColor;
      }
    }
    .but-active {
      background-color: @but-hoverColor;
    }
  }
  .content {
    width: 98%;
    height: 0;
    flex: 1;
    .tip {
      color: @baseColor;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: @activeColor;
      }
    }
  }
  .page {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
  }
}
#my-dialog .content {
  width: 98%;
  height: 0;
  flex: 1;
  .content-item {
    width: 33%;
    height: 50%;
    font-size: 14px;
    .content-item-top {
      width: 100%;
      height: 35px;
      position: relative;
      .content-item-top-line {
        width: 100%;
        height: 1px;
        background-color: rgba(160, 160, 160, 0.288);
        position: absolute;
        left: 0;
        top: 10px;
      }
      .content-item-top-title {
        width: auto;
        background-color: #ffffff;
        height: 100%;
        position: absolute;
        left: 30px;
        padding: 0 8px;
        font-size: 14px;
        span {
          color: red;
          margin-left: 8px;
        }
      }
    }
    .content {
      width: 98%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 13px;
      .line {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        .span {
          width: auto;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 3px;
          box-sizing: border-box;
        }
        .value {
          width: 0;
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
        }
        .file_text {
          color: @baseColor;
          transition: 0.3s;
          &:hover {
            cursor: pointer;
            color: @activeColor;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .renew {
    width: 100%;
    padding: 0 30px;
    display: flex;
    align-items: center;
    span {
      width: 180px;
    }
  }
  .allocation {
    width: 100%;
    padding: 0 30px;
    display: flex;
    align-items: center;
    .allocation-title {
      width: 120px;
    }
    .allocation-value {
      margin: 0 10px;
    }
  }

  .buts {
    width: 100%;
    height: 50px;
    .but {
      width: auto;
      height: auto;
      padding: 5px 15px;
      margin: 0 10px;
      box-sizing: border-box;
      border-radius: 8px;
      background-color: @baseColor;
      color: #ffffff;
      font-size: @font-size-tip-mini;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        background-color: @but-hoverColor;
      }
    }
  }
  .tip {
    margin-top: 20px;
    font-size: 14px;
  }
}
</style>
