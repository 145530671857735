<template>
  <div
    id="syssetting-record"
    class="div-flex-column-center"
    v-loading="loading"
  >
    <!-- 充值及消费记录 -->
    <div class="search-line">
      <el-input
        v-model="searchData.companyName"
        placeholder="企业名称"
        clearable
        @keyup.enter.native="resetPage"
      ></el-input>
      <el-input
        v-model="searchData.topUpUserName"
        placeholder="充值用户"
        clearable
        @keyup.enter.native="resetPage"
      ></el-input>
      <el-select
        v-model="searchData.payType"
        clearable
        filterable
        placeholder="类型"
        @change="resetPage"
      >
        <el-option label="充值" value="1"> </el-option>
        <el-option label="扣款" value="2"> </el-option>
      </el-select>
      <el-date-picker
        v-model="searchData.gmtCreate"
        type="daterange"
        value-format="yyyy-MM-dd"
        range-separator="至"
        start-placeholder="充值时间"
        end-placeholder="充值时间"
        @change="resetPage"
      >
      </el-date-picker>
    </div>
    <div class="content div-flex-center">
      <el-table :data="tableData" border style="width: 90%" height="98%">
        <el-table-column
          :prop="item.key"
          :label="item.title"
          :width="item.width"
          v-for="(item, index) in tableMenu"
          :key="index"
          :fixed="item.fixed"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span
              v-if="item.key == 'payCertificate' && scope.row[item.key]"
              style="color: #40a3ff; cursor: pointer;"
              @click="handleClick(scope.row, 'showFile')"
            >
              查看附件
            </span>
            <span v-else>{{ scope.row[item.key] }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page">
      <el-pagination
        @current-change="initData"
        :current-page.sync="pageData.currentPage"
        :page-size="pageData.pageSize"
        layout="total, prev, pager, next,jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>
    <div id="my-dialog" class="div-flex-center" v-if="dialogData.flag">
      <div
        class="my-dialog-content div-flex-column-center"
        v-loading="dialogData.loading"
      >
        <div class="top div-flex-center">
          <div class="title">{{ dialogData.title }}</div>
          <div class="perch-active"></div>
          <div class="close" v-if="dialogData.closeFlag">
            <i class="el-icon-close" @click="dialogData.flag = false"></i>
          </div>
        </div>
        <div
          class="content"
          v-if="dialogData.type == 'edit' || dialogData.type == 'add'"
          style="width: 100%"
        >
          <div class="edit">
            <information_input :informationInputData="dialogData.data">
            </information_input>
          </div>
        </div>
        <div class="buts div-flex-center">
          <div
            class="but"
            v-for="(item, index) in dialogData.buttonList"
            :key="index"
            @click="dialogHandleCilck(item.active)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      userData: {
        userId: "",
        userName: "",
        icon: "",
      },
      searchData: {
        topUpUserName: "",
        payType: "",
        gmtCreate: [],
        companyName: "",
      },
      pageData: {
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页条数
        total: 0, // 总条目数
      },
      tableData: [],
      tableMenu: [
        {
          title: "企业名称",
          key: "companyName",
          width: "220",
        },
        {
          title: "变更账户",
          key: "topUpUserName",
          width: "100",
        },
        {
          title: "类型",
          key: "payType",
          width: "120",
        },
        {
          title: "充值或扣款金额",
          key: "amount",
          width: "150",
        },
        {
          title: "实际金额（折扣后的金额）",
          key: "depositAmount",
          width: "150",
        },
        {
          title: "操作人",
          key: "operationalUserName",
          width: "",
        },
        {
          title: "充值或扣款时间",
          key: "gmtCreate",
          width: "220",
        },
        {
          title: "描述",
          key: "description",
          width: "220",
        },
        {
          title: "凭证",
          key: "payCertificate",
          width: "100",
          fixed: "right",
        },
      ],
      tableOper: [
        {
          name: "编辑",
          key: "编辑",
          active: "edit",
          show: false, // 由权限控制
          statusShow: true, // 由当前订单的状态控制
        },
        {
          name: "删除",
          key: "删除",
          active: "delete",
          show: false, // 由权限控制
          statusShow: true, // 由当前订单的状态控制
        },
      ],
      dialogData: {
        loading: true,
        closeFlag: true,
        flag: false,
        title: "",
        type: "show_information",
        data: [],
        url: require("../../assets/img/qr_code.jpg"),
        buttonList: [],
      },
      orderList: [
        {
          title: "充值及消费记录",
          data: [
            {
              title: "标题",
              type: "input",
              value: "",
              placeholder: "请输入标题",
              tip: true, // 是否为必填项
              landscape: true, // 是否横屏显示
              key: "title",
            },
            {
              title: "状态",
              key: "status",
              type: "switch",
              value: false,
              tip: true, // 是否为必填项
              landscape: true, // 是否横屏显示
              activeValue: "启用",
              inactiveValue: "禁用",
            },
            {
              title: "广告图片",
              key: "businessLicense",
              type: "file_reuse", // 仅文字展示
              tip: true, // 是否不得为空
              value: "",
              options: [],
              landscape: true, // 是否横屏显示
              placeholder: "选择附件", // 提示文字
              data: {
                systemName: "cluster_register",
                path: "",
                title: "广告图片",
              },
              isDisable: false,
              text: "", // 文件名称  显示在界面上的
            },
          ],
        },
      ],
      tempData: {},
      roleId: "",
    };
  },
  methods: {
    /**
     * 2.3 获取当前角色权限
     */
    getUserAuthority() {
      this.$baseData.getUserAuthority(this.userData.userId, this, (data) => {
        this.roleId = data[0].roleId;
        this.tableOper = this.$baseData.judgeButtonPermissions(
          data[0].model,
          this.tableOper,
          "系统设置",
          "充值及消费记录"
        );
        this.initData();
      });
    },
    /**
     *12.2 获取客户模板列表
     */
    initData() {
      let requestData = {
        companyName: this.searchData.companyName, // 筛选项：企业名称
        topUpUserName: this.searchData.topUpUserName, // 筛选项：充值用户
        payType: this.searchData.payType, // 筛选项：充值用户
        gmtCreate: this.searchData.gmtCreate, // 筛选项：充值或扣款时间
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
      };
      this.$request("post", "/expressPayRecords/page", requestData, (data) => {
        this.tableData = data.records;
        this.tableData.forEach((item) => {
          if (item.payType == "充值") {
            item.amount = "+" + item.amount;
            item.depositAmount = "+" + item.depositAmount;
          } else {
            item.amount = "-" + item.amount;
            item.depositAmount = "-" + item.depositAmount;
          }
        });
        this.pageData.total = data.total;
      });
    },
    /**
     * 重置 page 请求列表数据
     */
    resetPage() {
      this.pageData.currentPage = 1;
      this.initData();
    },
    /**
     * 表格中按钮点击事件
     */
    handleClick(item, active) {
      this.tempData = item;
      let requestData = {};
      switch (active) {
        case "showFile":
          window.open(this.tempData.payCertificate, "_blank");
          break;
        default:
          break;
      }
    },
    dialogHandleCilck(active) {
      let requestData = {};
      switch (active) {
        default:
          break;
      }
    },
  },
  mounted() {
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    let dd = {
      id: "",
      name: "充值及消费记录",
      auth: "2",
      path: "/main/setting/syssetting-record",
    };
    sessionStorage.setItem("childernMenu", JSON.stringify(dd));
    this.getUserAuthority();
  },
};
</script>

<style lang="less" scoped>
#syssetting-record {
  width: 100%;
  height: 100%;
  .search-line {
    width: 100%;
    height: auto;
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    .el-input {
      width: 23% !important;
      margin-left: 10px !important;
      margin-top: 5px;
      .el-input__inner {
        height: 30px !important;
      }
    }
    .el-select,
    .el-range-editor {
      width: 23% !important;
      margin-left: 10px !important;
      margin-top: 5px;
    }
  }
  .content {
    width: 98%;
    height: 0;
    flex: 1;
    .tip {
      color: @baseColor;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: @activeColor;
      }
    }
    img {
      width: 30px;
      height: 30px;
    }
  }
  .page {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
  }
  .buts {
    width: 100%;
    height: 50px;
    .but {
      width: auto;
      height: auto;
      padding: 5px 15px;
      margin: 0 10px;
      box-sizing: border-box;
      border-radius: 8px;
      background-color: @baseColor;
      color: #ffffff;
      font-size: @font-size-tip-mini;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        background-color: @but-hoverColor;
      }
    }
  }
  #my-dialog .content {
    width: 98%;
    height: 0;
    flex: 1;
    .content-item {
      width: 33%;
      height: 50%;
      font-size: 14px;
      .content-item-top {
        width: 100%;
        height: 35px;
        position: relative;
        .content-item-top-line {
          width: 100%;
          height: 1px;
          background-color: rgba(160, 160, 160, 0.288);
          position: absolute;
          left: 0;
          top: 10px;
        }
        .content-item-top-title {
          width: auto;
          background-color: #ffffff;
          height: 100%;
          position: absolute;
          left: 30px;
          padding: 0 8px;
          font-size: 14px;
          span {
            color: red;
            margin-left: 8px;
          }
        }
      }
      .content {
        width: 98%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 13px;
        .line {
          width: 100%;
          height: 30px;
          display: flex;
          align-items: center;
          .span {
            width: auto;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 3px;
            box-sizing: border-box;
          }
          .value {
            width: 0;
            flex: 1;
            height: 100%;
            display: flex;
            align-items: center;
          }
          .file_text {
            color: @baseColor;
            transition: 0.3s;
            &:hover {
              cursor: pointer;
              color: @activeColor;
              text-decoration: underline;
            }
          }
        }
        .edit {
          width: 100%;
        }
      }
    }
    .buts {
      width: 100%;
      height: 50px;
      .but {
        width: auto;
        height: auto;
        padding: 5px 15px;
        margin: 0 10px;
        box-sizing: border-box;
        border-radius: 8px;
        background-color: @baseColor;
        color: #ffffff;
        font-size: @font-size-tip-mini;
        transition: 0.3s;
        &:hover {
          cursor: pointer;
          background-color: @but-hoverColor;
        }
      }
    }
    .tip {
      margin-top: 20px;
      font-size: 14px;
    }
  }
}
</style>
