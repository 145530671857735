<template>
  <div id="agent-create" class="div-flex-column-center" v-loading="loading">
    <!-- 区域审核 -->
    <div class="search-line">
      <el-input
        v-model="searchData.userName"
        placeholder="用户名称"
        clearable
        @keyup.enter.native="resetPage"
      ></el-input>
      <el-select
        v-model="searchData.areaId"
        placeholder="区域名称"
        clearable
        @change="resetPage"
      >
        <el-option-group
          v-for="group in alreadyAreaSelectOption"
          :key="group.label"
          :label="group.label"
        >
          <el-option
            v-for="item in group.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-option-group>
      </el-select>
      <el-select
        v-model="searchData.areaFlag"
        placeholder="区域范围"
        clearable
        @change="resetPage"
      >
        <el-option label="安徽省" value="0"></el-option>
        <el-option label="合肥市" value="1"></el-option>
      </el-select>
      <el-date-picker
        v-model="searchData.gmtCreate"
        type="daterange"
        value-format="yyyy-MM-dd"
        range-separator="至"
        start-placeholder="提交范围"
        end-placeholder="提交范围"
        @change="resetPage"
      >
      </el-date-picker>
    </div>
    <div class="content div-flex-center">
      <el-table :data="tableData" border style="width: 90%" height="98%">
        <el-table-column
          :prop="item.key"
          :label="item.title"
          :width="item.width"
          :fixed="item.key == 'orderNo' ? true : item.fixed"
          v-for="(item, index) in tableMenu"
          :key="index"
        >
          <template slot-scope="scope">
            <span v-if="item.key == 'areaFlag'">{{
              scope.row[item.key] == "0" ? "安徽省" : "合肥市"
            }}</span>
            <span v-else>{{ scope.row[item.key] }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="left">
          <template slot="header">
            <el-button
              @click="handleClick({}, 'add')"
              type="text"
              size="small"
              icon="el-icon-circle-plus-outline"
              >添加</el-button
            >
          </template>
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row, it.active)"
              type="text"
              size="small"
              v-for="(it, ii) in tableOper"
              v-show="it.show && it.statusShow && it.name !== '添加'"
              :key="ii"
              >{{ it.name }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page">
      <!-- @current-change="getAllCompanyList" -->
      <el-pagination
        @current-change="initData"
        :current-page.sync="pageData.currentPage"
        :page-size="pageData.pageSize"
        layout="total, prev, pager, next,jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>
    <div id="my-dialog" class="div-flex-center" v-if="dialogData.flag">
      <div
        class="my-dialog-content div-flex-column-center"
        v-loading="dialogData.loading"
      >
        <div class="top div-flex-center">
          <div class="title">{{ dialogData.title }}</div>
          <div class="perch-active"></div>
          <div class="close">
            <i class="el-icon-close" @click="dialogData.flag = false"></i>
          </div>
        </div>
        <div
          class="content div-flex-column-center"
          v-if="dialogData.type == 'add'"
        >
          <createAgent
            :value="dialogData.data"
            @addArea="addArea"
            @deleteArea="deleteArea"
          ></createAgent>
        </div>
        <div class="buts div-flex-center">
          <div
            class="but"
            v-for="(item, index) in dialogData.buttonList"
            :key="index"
            @click="dialogHandleCilck(item.active)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import createAgent from "./components/create-agent.vue";
import * as agentData from "../../assets/js/agentData.js";
export default {
  components: {
    createAgent,
  },
  data() {
    return {
      input: "12312",
      loading: false,
      userData: {
        userId: "",
        userName: "",
        icon: "",
      },
      searchData: {
        userName: "",
        areaId: "",
        areaFlag: "",
        gmtCreate: [],
      },
      orderList: agentData.getAssignDialogData(),
      pageData: {
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页条数
        total: 0, // 总条目数
      },
      tableData: [],
      tableMenu: [
        {
          key: "userName",
          title: "用户名称",
          width: "200",
          fixed: false,
        },
        {
          key: "idCard",
          title: "账号",
          width: "200",
          fixed: false,
        },
        {
          key: "roleName",
          title: "角色",
          width: "120",
          fixed: false,
        },

        {
          key: "areaName",
          title: "区域名称",
          width: "200",
          fixed: false,
        },
        {
          key: "areaFlag",
          title: "区域范围",
          width: "120",
          fixed: false,
        },
        {
          key: "gmtCreate",
          title: "注册时间",
          width: "200",
          fixed: false,
        },
      ],
      tableOper: [
        // {
        //   name: "添加",
        //   key: "添加",
        //   active: "add",
        //   show: false, // 由权限控制
        //   statusShow: true, // 由当前订单的状态控制
        // },
        {
          name: "编辑",
          key: "编辑",
          active: "edit",
          show: false, // 由权限控制
          statusShow: true, // 由当前订单的状态控制
        },
      ],
      dialogData: {
        flag: false,
        title: "客户基本信息录入",
        loading: true,
        type: "sign",
        baseData: {
          // 录入基本信息时,填写的表单信息
          title: "", // 自定义表单标题
          id: "", // 自定义表单ID
          time: "", // 时间
        },
        data: [],
        buttonList: [],
      },
      tempData: {},
      areaSelectOption: [],
      alreadyAreaSelectOption: [],
    };
  },
  methods: {
    /**
     * 获取所有区域列表
     */
    getAreaSelectOption() {
      this.$request(
        "get",
        "/area/getAreaSelectOption?flag=1", // 0 - 查询未代理；1 - 查询已代理；不传查询全部
        {},
        (data) => {
          this.alreadyAreaSelectOption = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 2.3 获取当前角色权限
     */
    getUserAuthority() {
      this.$baseData.getUserAuthority(this.userData.userId, this, (data) => {
        this.tableOper = this.$baseData.judgeButtonPermissions(
          data[0].model,
          this.tableOper,
          "代理管理",
          "区域审核"
        );
        console.log("当前界面按钮权限:", this.tableOper);
        this.getAreaSelectOption();
        this.initData();
      });
    },
    // 列表数据
    initData() {
      let requestData = {
        userName: this.searchData.userName, // 筛选项：用户姓名
        idCard: this.searchData.idCard, // 筛选项：身份证
        areaId: this.searchData.areaId, // 筛选项：区域名称对应的区域 id
        areaFlag: this.searchData.areaFlag, // 筛选项：区域范围标识
        gmtCreate: this.searchData.gmtCreate, // 筛选项：注册时间
        currentPage: this.pageData.currentPage,
        pageSize: this.pageData.pageSize,
      };
      this.$request(
        "post",
        "/user/queryRegionalAgent",
        requestData,
        (data) => {
          this.tableData = data.records;
          this.pageData.total = data.total;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 重置 page 请求列表数据
     */
    resetPage() {
      this.pageData.currentPage = 1;
      this.initData();
    },
    /**
     * 表格按钮操作事件
     */
    handleClick(item, active) {
      let requestData = {};
      this.tempData = item;
      switch (active) {
        case "add":
          // 创建区域审核
          this.dialogData.loading = true;
          this.dialogData.title = "创建区域审核";
          this.dialogData.type = "add";
          this.dialogData.buttonList = [
            {
              name: "确认",
              active: "sure_add",
            },
            {
              name: "取消",
              active: "cancel",
            },
          ];
          this.$request(
            "get",
            "/area/getAreaSelectOption?flag=0", // 0 - 查询未代理；1 - 查询已代理；不传查询全部
            {},
            (data) => {
              this.areaSelectOption = data;
              this.dialogData.data = JSON.parse(JSON.stringify(this.orderList));
              this.dialogData.data.areaList = this.areaSelectOption;
              this.dialogData.loading = false;
              this.dialogData.flag = true;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "edit":
          // 编辑区域审核
          this.dialogData.loading = true;
          this.dialogData.title = "修改区域审核信息";
          this.dialogData.type = "add";
          this.dialogData.buttonList = [
            {
              name: "确认",
              active: "sure_edit",
            },
            {
              name: "取消",
              active: "cancel",
            },
          ];
          this.$request(
            "get",
            "/area/getAreaSelectOption?flag=1", // 0 - 查询未代理；1 - 查询已代理；不传查询全部
            {},
            (data) => {
              this.alreadyAreaSelectOption = data;
              this.$request(
                "get",
                "/address/queryByAreaId?areaId=" + this.tempData.areaId,
                {},
                (data2) => {
                  this.dialogData.data = JSON.parse(
                    JSON.stringify(this.orderList)
                  );
                  this.dialogData.data.isEdit = true;
                  this.dialogData.data.idCard = this.tempData.idCard;
                  this.dialogData.data.userName = this.tempData.userName;
                  this.dialogData.data.password = this.tempData.password;
                  this.dialogData.data.areaId = this.tempData.areaId;
                  this.dialogData.data.areaList = this.alreadyAreaSelectOption;
                  if (data2.length > 0) {
                    this.dialogData.data.addressList = data2;
                  } else {
                    this.dialogData.data.addressList = [
                      {
                        prefix: "", // 地址前缀
                        suffix: "", // 地址后缀
                        tag: "", // 地址标签
                        priceIndividual: "500", // 价格（个体户），保留两位小数
                        discountIndividual: "1", // 折扣（个体户），区间：(0 - 1]，保留两位小数
                        priceEnterprise: "500", // 价格（企业），保留两位小数
                        discountEnterprise: "1", // 折扣（企业），区间：(0 - 1]，保留两位小数
                      },
                    ];
                  }
                  this.dialogData.loading = false;
                  this.dialogData.flag = true;
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "setting":
          // 价格设置
          break;
        default:
          break;
      }
    },
    /**
     * dialog 按钮操作事件
     */
    dialogHandleCilck(active) {
      let requestData = {};
      let flag = false;
      let flag2 = false;
      switch (active) {
        case "sure_add":
          // 确认创建区域审核
          requestData = {
            idCard: this.dialogData.data.idCard, // 身份证号
            userName: this.dialogData.data.userName, // 用户名
            password: this.dialogData.data.password, // 密码
            areaId: this.dialogData.data.areaId, // 区域id
            addressList: this.dialogData.data.addressList, // 地址规则
          };
          requestData.addressList.forEach((item) => {
            item.areaId = this.dialogData.data.areaId;
          });
          if (!requestData.idCard) {
            this.$message.error("身份证号不能为空!");
            return;
          }
          if (!requestData.userName) {
            this.$message.error("用户名不能为空!");
            return;
          }
          if (!requestData.password) {
            this.$message.error("密码不能为空!");
            return;
          }
          if (!requestData.areaId) {
            this.$message.error("区域名称不能为空!");
            return;
          }
          flag = requestData.addressList.every((item) => {
            if (
              !item.prefix ||
              !item.tag ||
              !item.priceIndividual ||
              !item.discountIndividual ||
              !item.priceEnterprise ||
              !item.discountEnterprise
            ) {
              return false;
            } else {
              return true;
            }
          });
          if (!flag) {
            this.$message.error("地址前缀、标签、价格、折扣不能为空!");
            return;
          }
          // requestData.addressList.every(item => {

          // })
          this.$request(
            "post",
            "/user/createRegionalAgentAccount",
            requestData,
            (data) => {
              this.$message.success("创建成功");
              this.dialogData.flag = false;
              this.initData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "sure_edit":
          // 取消
          requestData = {
            id: this.tempData.id, // 用户 id
            idCard: this.dialogData.data.idCard, // 身份证
            userName: this.dialogData.data.userName, // 用户名
            password: this.dialogData.data.password, // 密码
            areaId: this.dialogData.data.areaId, // 区域id
            addressList: [],
          };
          requestData.addressList = this.dialogData.data.addressList.map(
            (item) => {
              return {
                id: item.id,
                areaId: this.dialogData.data.areaId, // 区域id
                prefix: item.prefix, // 地址前缀
                suffix: item.suffix, // 地址前缀
                tag: item.tag, // 标签
                priceIndividual: item.priceIndividual, // 价格（个体户），保留两位小数
                discountIndividual: item.discountIndividual, // 折扣（个体户），区间：(0 - 1]，保留两位小数
                priceEnterprise: item.priceEnterprise, // 价格（企业），保留两位小数
                discountEnterprise: item.discountEnterprise, // 折扣（企业），区间：(0 - 1]，保留两位小数
              };
            }
          );
          if (!requestData.idCard) {
            this.$message.error("身份证号不能为空!");
            return;
          }
          if (!requestData.userName) {
            this.$message.error("用户名不能为空!");
            return;
          }
          if (!requestData.password) {
            this.$message.error("密码不能为空!");
            return;
          }
          if (!requestData.areaId) {
            this.$message.error("区域名称不能为空!");
            return;
          }
          console.log("requestData", requestData);
          flag = requestData.addressList.every((item) => {
            if (
              !item.prefix ||
              !item.tag ||
              !item.priceIndividual ||
              !item.discountIndividual ||
              !item.priceEnterprise ||
              !item.discountEnterprise
            ) {
              return false;
            } else {
              return true;
            }
          });
          if (!flag) {
            this.$message.error("地址前缀、标签、价格、折扣不能为空!");
            return;
          }
          this.$request(
            "post",
            "/user/updRegionalAgentAccount",
            requestData,
            (data) => {
              this.$message.success("编辑成功");
              this.dialogData.flag = false;
              this.initData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          // 取消
          this.dialogData.flag = false;
          break;
        default:
          break;
      }
    },
    isNumeric(value) {
      return typeof value === "number" && !isNaN(value);
    },
    /**
     * 添加地址规则
     */
    addArea(index) {
      let flag = this.dialogData.data.addressList.every((item) => {
        if (
          !item.prefix ||
          !item.tag ||
          !item.priceIndividual ||
          !item.discountIndividual ||
          !item.priceEnterprise ||
          !item.discountEnterprise
        ) {
          return false;
        } else {
          return true;
        }
      });
      if (!flag) {
        this.$message.error("地址前缀、标签、价格、折扣不能为空!");
        return;
      }
      this.dialogData.data.addressList.push({
        prefix: "", // 地址前缀
        suffix: "", // 地址后缀
        tag: "", // 地址标签
        priceIndividual: "500", // 价格（个体户），保留两位小数
        discountIndividual: "1", // 折扣（个体户），区间：(0 - 1]，保留两位小数
        priceEnterprise: "500", // 价格（企业），保留两位小数
        discountEnterprise: "1", // 折扣（企业），区间：(0 - 1]，保留两位小数
      });
    },
    /**
     * 删除地址规则
     */
    deleteArea(index) {
      console.log("index", index);
      if (this.dialogData.data.addressList.length == 1) {
        this.$message.error("至少保留一条地址信息!");
        return;
      }
      this.dialogData.data.addressList.splice(index, 1);
    },
  },
  mounted() {
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    let dd = {
      id: "",
      name: "区域审核",
      auth: "2",
      path: "/main/agent/agent_create",
    };
    sessionStorage.setItem("childernMenu", JSON.stringify(dd));
    this.getUserAuthority();
  },
};
</script>

<style lang="less" scoped>
#agent-create {
  width: 100%;
  height: 100%;
  .fill-in-information {
    width: 100%;
    height: auto;
    .el-tabs {
      width: 98%;
      height: auto;
    }
  }
  .search-line {
    width: 100%;
    height: auto;
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    .el-input {
      width: 23% !important;
      margin-left: 10px !important;
      margin-top: 5px;
      .el-input__inner {
        height: 30px !important;
      }
    }
    .el-select,
    .el-range-editor {
      width: 23% !important;
      margin-left: 10px !important;
      margin-top: 5px;
    }
  }
  .content {
    width: 98%;
    height: 0;
    flex: 1;
    .tip {
      color: @baseColor;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: @activeColor;
      }
    }
  }
  .page {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
  }
  .buts {
    width: 100%;
    height: 50px;
    .but {
      width: auto;
      height: auto;
      padding: 5px 15px;
      margin: 0 10px;
      box-sizing: border-box;
      border-radius: 8px;
      background-color: @baseColor;
      color: #ffffff;
      font-size: @font-size-tip-mini;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        background-color: @but-hoverColor;
      }
    }
  }
  #my-dialog .content {
    width: 98%;
    height: 0;
    flex: 1;
    .content-item {
      width: 33%;
      height: 50%;
      font-size: 14px;
      .content-item-top {
        width: 100%;
        height: 35px;
        position: relative;
        .content-item-top-line {
          width: 100%;
          height: 1px;
          background-color: rgba(160, 160, 160, 0.288);
          position: absolute;
          left: 0;
          top: 10px;
        }
        .content-item-top-title {
          width: auto;
          background-color: #ffffff;
          height: 100%;
          position: absolute;
          left: 30px;
          padding: 0 8px;
          font-size: 14px;
          span {
            color: red;
            margin-left: 8px;
          }
        }
      }
      .content {
        width: 98%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 13px;
        .line {
          width: 100%;
          height: 30px;
          display: flex;
          align-items: center;
          .span {
            width: auto;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 3px;
            box-sizing: border-box;
          }
          .value {
            width: 0;
            flex: 1;
            height: 100%;
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .buts {
      width: 100%;
      height: 50px;
      .but {
        width: auto;
        height: auto;
        padding: 5px 15px;
        margin: 0 10px;
        box-sizing: border-box;
        border-radius: 8px;
        background-color: @baseColor;
        color: #ffffff;
        font-size: @font-size-tip-mini;
        transition: 0.3s;
        &:hover {
          cursor: pointer;
          background-color: @but-hoverColor;
        }
      }
    }
    .tip {
      margin-top: 20px;
      font-size: 14px;
    }
  }
}
</style>
