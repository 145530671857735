export function getTableMenuData() {
    return [
        {
            key: "orderNo",
            title: "订单编号",
            width: "200",
            fixed: false,
        },
        {
            key: "companyName",
            title: "企业名称",
            width: "200",
            fixed: false,
        },
        {
            key: "clusterAddress",
            title: "地址编号",
            width: "300",
            fixed: false,
        },
        {
            key: "contractNo",
            title: "合同编号",
            width: "200",
            fixed: false,
        },
        {
            key: "lesseeName",
            title: "承租人/所有人",
            width: "200",
            fixed: false,
        },
        {
            key: "scopeBusiness",
            title: "经营范围",
            width: "200",
            fixed: false,
        },
        {
            key: "siteAddress",
            title: "实际经营地址",
            width: "200",
            fixed: false,
        },
        {
            key: "type",
            title: "类型",
            width: "100",
            fixed: 'right',
        },
        {
            key: "submitTime",
            title: "提交时间",
            width: "200",
            fixed: false,
        },
        {
            key: "submitUser",
            title: "提交单位",
            width: "200",
            fixed: false,
        },
        {
            key: "submitUserPhone",
            title: "提交单位手机号",
            width: "150",
            fixed: false,
        },
        {
            key: "statusName",
            title: "状态",
            width: "100",
            fixed: "right",
        },
    ]
}

export function getNoNumTableMenuData() {
    return [{
            key: "companyName",
            title: "企业名称",
            width: "200",
            fixed: false,
        },
        {
            key: "submitUser",
            title: "提交单位",
            width: "200",
            fixed: false,
        },
        {
            key: "clusterAddress",
            title: "地址编号",
            width: "200",
            fixed: false,
        },
        {
            key: "contractNo",
            title: "合同编号",
            width: "200",
            fixed: false,
        },
        {
            key: "lesseeName",
            title: "承租人/所有人",
            width: "200",
            fixed: false,
        },
        {
            key: "scopeBusiness",
            title: "经营范围",
            width: "200",
            fixed: false,
        },
        {
            key: "siteAddress",
            title: "实际经营地址",
            width: "200",
            fixed: false,
        },
        {
            key: "type",
            title: "类型",
            width: "100",
            fixed: false,
        },
        {
            key: "submitTime",
            title: "提交时间",
            width: "200",
            fixed: false,
        },
        
        {
            key: "statusName",
            title: "状态",
            width: "100",
            fixed: "right",
        },
    ]
}

export function getOrderReportTableMenuData() {
    return [
        {
            key: "submitTime",
            title: "提交日期",
            width: "180",
            fixed: false,
        },
        {
            key: "orderNo",
            title: "订单编号",
            width: "200",
            fixed: false,
        },
        {
            key: "status",
            title: "订单状态",
            width: "140",
            fixed: true,
        },
        {
            key: "companyName",
            title: "企业申请名称",
            width: "200",
            fixed: false,
        },
        {
            key: "industryType",
            title: "行业类型",
            width: "200",
            fixed: false,
        },
        {
            key: "areaName",
            title: "地址所属区域",
            width: "140",
            fixed: false,
        },
        {
            key: "clusterAddress",
            title: "地址简略编号",
            width: "200",
            fixed: false,
        },
        {
            key: "legalPersonName",
            title: "法人姓名",
            width: "120",
            fixed: false,
        },
        {
            key: "legalPersonIdNumber",
            title: "法人身份证号",
            width: "170",
            fixed: false,
        },
        {
            key: "legalPersonPhone",
            title: "法人联系方式",
            width: "160",
            fixed: false,
        },
        {
            key: "spareContactsName",
            title: "备用联系人",
            width: "160",
            fixed: false,
        },
        {
            key: "spareContactsPhone",
            title: "备用联系方式",
            width: "160",
            fixed: false,
        },
        {
            key: "partnerName",
            title: "合作商名称",
            width: "200",
            fixed: false,
        },
        {
            key: "partnerPhone",
            title: "合作商联系方式",
            width: "160",
            fixed: false,
        },
        {
            key: "giftFlag",
            title: "是否赠送",
            width: "100",
            fixed: false,
        },
        {
            key: "voidFlag",
            title: "是否作废",
            width: "100",
            fixed: false,
        },
    ]
}
