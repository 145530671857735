<template>
  <div id="my-company" class="div-flex-column-center" v-loading="loading">
    <!-- 客户管理 -->
    <div class="search-line">
      <el-input
        v-model="searchData.companyName"
        placeholder="企业名称"
        clearable
        @keyup.enter.native="resetPage"
      ></el-input>
      <el-input
        v-model="searchData.legalPersonName"
        placeholder="法定代表人"
        clearable
        @keyup.enter.native="resetPage"
      ></el-input>
      <el-select
        v-model="searchData.areaId"
        filterable
        clearable
        placeholder="请选择区域"
        @change="resetPage"
      >
        <el-option-group
          v-for="group in searchData.alreadyAreaSelectOption"
          :key="group.label"
          :label="group.label"
        >
          <el-option
            v-for="item in group.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-option-group>
      </el-select>
      <el-select
        v-model="searchData.status"
        clearable
        filterable
        placeholder="请选择打卡状态"
        @change="resetPage"
      >
        <el-option label="全部" value="-1"> </el-option>
        <el-option label="已打卡" value="0"> </el-option>
        <el-option label="未打卡" value="1"> </el-option>
      </el-select>
      <el-select
        v-model="searchData.isSign"
        clearable
        filterable
        placeholder="请选择签署商秘合同状态"
        @change="resetPage"
      >
        <el-option label="全部" value="-1"> </el-option>
        <el-option label="已签署商秘合同" value="0"> </el-option>
        <el-option label="未签署商秘合同" value="1"> </el-option>
      </el-select>
    </div>
    <div class="content div-flex-center">
      <el-table :data="tableData" border style="width: 90%" height="98%">
        <el-table-column
          :prop="item.key"
          :label="item.title"
          :width="item.width"
          :fixed="item.key == 'orderNo' ? true : item.fixed"
          v-for="(item, index) in tableMenu"
          :key="index"
        >
          <template slot-scope="scope">
            <span
              v-if="item.key == 'orderNo'"
              class="tip"
              @click="showDetails(scope.row, 0)"
              >{{ scope.row[item.key] }}</span
            >
            <span v-else>{{ scope.row[item.key] }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="180" align="left">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row, it.active)"
              type="text"
              size="small"
              v-for="(it, ii) in scope.row.tableOper"
              :key="ii"
              v-show="it.show && it.statusShow"
              >{{ it.name }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="page">
      <el-pagination
        @current-change="getAllCompanyList"
        :current-page.sync="pageData.currentPage"
        :page-size="pageData.pageSize"
        layout="total, prev, pager, next,jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>

    <div id="my-dialog" class="div-flex-center" v-if="dialogData.flag">
      <div
        class="my-dialog-content div-flex-column-center"
        v-loading="dialogData.loading"
      >
        <div class="top div-flex-center">
          <div class="title">{{ dialogData.title }}</div>
          <div class="perch-active"></div>
          <div class="close">
            <i class="el-icon-close" @click="dialogData.flag = false"></i>
          </div>
        </div>

        <div class="content" v-if="dialogData.type == 'choose_sign'">
          <information_input :informationInputData="dialogData.data">
          </information_input>
        </div>

        <div class="content div-flex-center" v-if="dialogData.type == 'sign'">
          <SheetDetails :sheetDetailsData="dialogData.data"></SheetDetails>
        </div>

        <div
          class="content div-flex-column-center"
          v-if="dialogData.type == 'show_img'"
        >
          <el-image
            style="width: 350px; height: 350px"
            :src="dialogData.url"
            fit="contain"
          ></el-image>

          <div class="tip" @click="dialogHandleCilck('qrcode')">二维码下载</div>
        </div>

        <div class="buts div-flex-center">
          <div
            class="but"
            v-for="(item, index) in dialogData.buttonList"
            :key="index"
            @click="dialogHandleCilck(item.active)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SheetDetails from "../../components/sheet-detials.vue";
import * as CompanyData from "../../assets/js/companyData.js";
export default {
  components: { SheetDetails },
  data() {
    return {
      loading: false,
      userData: {
        userId: "",
        userName: "",
        icon: "",
      },
      searchData: {
        companyName: "", // 企业名称
        legalPersonName: "", // 法定代表人姓名
        status: "", // 订单状态 -1 全部  0 已打卡 1 未打卡
        areaId: "", // 区域ID
        alreadyAreaSelectOption: [],
        isSign: "", // 是否签署商秘合同  -1全部 0是 1否
      },
      pageData: {
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页条数
        total: 0, // 总条目数
      },
      tableData: [],
      tableMenu: [
        {
          key: "companyName",
          title: "企业名称",
          width: "270",
          fixed: false,
        },
        {
          key: "companyCode",
          title: "统一信用代码",
          width: "200",
          fixed: false,
        },
        {
          key: "clusterAddress",
          title: "集群地址",
          width: "200",
          fixed: false,
        },
        {
          key: "areaName",
          title: "所处区域",
          width: "120",
          fixed: false,
        },
        {
          key: "legalPersonName",
          title: "法定代表人",
          width: "120",
          fixed: false,
        },
        {
          key: "lastTimeClockStatus",
          title: "本期打卡状态",
          width: "130",
          fixed: false,
        },
        {
          key: "lastTimeClockTime",
          title: "本期打卡时间",
          width: "200",
          fixed: false,
        },
        {
          key: "remark",
          title: "备注",
          width: "120",
          fixed: false,
        },
      ],

      tableOper: [
        {
          name: "信息查看",
          key: "信息录入",
          active: "show",
          show: false, // 由权限控制
          statusShow: true, // 由当前订单的状态控制
        },
        {
          name: "信息录入",
          key: "信息录入",
          active: "sign",
          show: false, // 由权限控制
          statusShow: true, // 由当前订单的状态控制
        },
        {
          name: "工商信息",
          key: "信息录入",
          active: "aaum_sign",
          show: false, // 由权限控制
          statusShow: true, // 由当前订单的状态控制
        },
        {
          name: "打卡提醒",
          key: "打卡提醒",
          active: "clockIn",
          show: false, // 由权限控制
          statusShow: false, // 由当前订单的状态控制
        },
        {
          name: "生成电子合同",
          key: "电子合同",
          active: "electronic_contract",
          show: false, // 由权限控制
          statusShow: false, // 由当前订单的状态控制
        },
        {
          name: "查看二维码",
          key: "电子合同",
          active: "show_electronic",
          show: false, // 由权限控制
          statusShow: false, // 由当前订单的状态控制
        },
        {
          name: "下载PDF",
          key: "电子合同",
          active: "pdf",
          show: false, // 由权限控制
          statusShow: false, // 由当前订单的状态控制
        },
      ],

      dialogData: {
        flag: false,
        title: "客户基本信息录入",
        loading: true,
        type: "sign",
        baseData: {
          // 录入基本信息时,填写的表单信息
          title: "", // 自定义表单标题
          id: "", // 自定义表单ID
          time: "", // 时间
        },
        data: [],
        buttonList: [],
      },
      tempClient: {},
    };
  },
  methods: {
    /**
     * 2.3 获取当前角色权限
     */
    getUserAuthority() {
      this.$baseData.getUserAuthority(this.userData.userId, this, (data) => {
        this.tableOper = this.$baseData.judgeButtonPermissions(
          data[0].model,
          this.tableOper,
          "企业管理",
          "客户管理"
        );
        console.log("当前界面按钮权限:", this.tableOper);
        this.getAreaSelectOption();
      });
    },
    /**
     * 获取所有区域列表
     */
    getAreaSelectOption() {
      this.$request(
        "get",
        "/area/getAreaSelectOption?flag=1", // 0 - 查询未代理；1 - 查询已代理；不传查询全部
        {},
        (data) => {
          this.searchData.alreadyAreaSelectOption = data;

          this.getAllCompanyList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 6.11 获取订单完成(已经签名)的订单
     */
    getAllCompanyList() {
      this.loading = true;
      let requestData = {
        userId: this.userData.userId,
        search: {
          companyName: this.searchData.companyName, // 企业名称
          legalPersonName: this.searchData.legalPersonName, // 法定代表人姓名
          status: this.searchData.status, // 订单状态 -1 全部  0 已打卡 1 未打卡
          areaId: this.searchData.areaId, // 所属区域id
          isSign: this.searchData.isSign, // 是否签署商秘合同  -1全部 0是 1否
        },
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
      };
      this.$request("post", "/client/getClientList", requestData, (data) => {
        this.pageData.total = data.total;
        this.tableData = data.tableData;
        this.tableData.forEach((element) => {
          let dd = JSON.parse(JSON.stringify(this.tableOper));
          if (element.lastTimeClockStatus == "未打卡") {
            dd[3].statusShow = true;
          } else {
            dd[3].statusShow = -false;
          }
          if (element.signFlag == "0") {
            // 是否签过名 0没有  1签过
            dd[4].statusShow = true;
          } else {
            dd[4].statusShow = -false;
          }
          if (element.pdfFile == "") {
            // 是否有电子合同  查看二维码
            dd[5].statusShow = false;
            dd[6].statusShow = false;
          } else {
            dd[5].statusShow = -true;
            dd[6].statusShow = -true;
          }

          this.$set(element, "tableOper", dd);
        });
        this.loading = false;
      });
    },
    /**
     * 重置 page 请求列表数据
     */
    resetPage() {
      this.pageData.currentPage = 1;
      this.getAllCompanyList();
    },
    /**
     * 表格按钮操作事件
     */
    handleClick(item, active) {
      let requestData = {};
      this.tempClient = item;
      switch (active) {
        case "show":
          // 查看录入的自定义表单信息
          this.$router.push({
            path: "/main/company/client_infomation",
            query: {
              id: item.id,
              name: item.companyName,
            },
          });
          break;
        case "clockIn":
          // 打卡提醒
          requestData = {
            id: item.id,
          };
          this.$request("post", "/client/clockRemind", requestData, (data) => {
            this.$message({
              message: "信息已发至客户公众号,请联系客户尽快打卡!",
              type: "success",
            });
          });
          break;
        case "sign":
          // 信息录入  选择信息录入的表单样式
          requestData = {
            userId: this.userData.userId,
            search: {},
            currentPage: 1,
            pageSize: 100,
          };
          this.$request(
            "post",
            "/clientTemplate/getClientTemplateList",
            requestData,
            (data) => {
              this.dialogData.loading = true;
              this.dialogData.flag = true;
              setTimeout(() => {
                this.dialogData.title = "选择自定义表单类型";
                this.dialogData.data = CompanyData.getSheetListData();
                let options = [];
                data.tableData.forEach((element) => {
                  if (element.status == "正常") {
                    let dd = {
                      label: element.name,
                      value: element.id,
                    };
                    options.push(dd);
                  }
                });
                this.dialogData.data[0].data[1].options = options;
                this.dialogData.type = "choose_sign";
                this.dialogData.buttonList = [
                  {
                    name: "确定",
                    active: "makesure_choose_sign",
                  },
                  { name: "取消", active: "cancel" },
                ];
                this.dialogData.loading = false;
              }, 500);
            }
          );
          break;

        case "aaum_sign":
          // 填写工商信息
          this.dialogData.loading = true;
          this.dialogData.flag = true;
          setTimeout(() => {
            this.dialogData.title = "工商信息查询";
            this.dialogData.data = CompanyData.getCompanyInfo();
            this.dialogData.data[0].data[0].value = item.companyName; // 企业名称
            this.dialogData.data[0].data[1].value = item.companyCode; // 统一信用代码
            this.dialogData.data[0].data[2].value = item.legalPersonName; // 法定代表人
            this.dialogData.data[0].data[3].value = item.legalPersonPhone; // 法定代表人手机号码
            this.dialogData.data[0].data[4].value = item.siteAddress; // 实际经营场地地址
            this.dialogData.data[0].data[5].value =
              item.legalPersonDocumentType; // 法定代表人证件类型
            this.dialogData.data[0].data[6].value = item.legalPersonIdNumber; // 法定代表人证件号码
            this.dialogData.data[0].data[7].value = item.legalPersonEmail; // 法定代表人邮箱
            this.dialogData.data[0].data[8].value = item.remark; // 备注
            this.dialogData.type = "choose_sign";
            this.dialogData.buttonList = [
              {
                name: "工商信息同步",
                active: "synchronous",
              },
              {
                name: "确定",
                active: "makesure_aaum",
              },
              { name: "取消", active: "cancel" },
            ];
            this.dialogData.loading = false;
          }, 500);
          break;
        case "electronic_contract":
          // 生成电子合同
          this.$confirm(
            "此操作将覆盖已有的电子合同并重新生成新的合同, 是否继续?",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              this.loading = true;
              requestData = {
                clientId: item.id,
              };
              this.$request(
                "post",
                "/client/generateContract",
                requestData,
                (data) => {
                  this.$message({
                    type: "success",
                    message: "电子合同生成成功!",
                  });
                  this.loading = false;
                  this.handleClick(item, "show_electronic");
                },
                () => {
                  this.loading = false;
                }
              );
            })
            .catch(() => {});
          break;
        case "show_electronic":
          // 查看二维码
          this.dialogData.loading = true;
          requestData = {
            clientId: item.id,
          };
          this.$request(
            "post",
            "/client/getClientQRCodePath",
            requestData,
            (data) => {
              setTimeout(() => {
                this.dialogData.title = "二维码";
                this.dialogData.type = "show_img";
                this.dialogData.url = data.qrCode;
                this.dialogData.buttonList = [];
                this.dialogData.loading = false;
              }, 500);
              this.dialogData.flag = true;
            }
          );
          break;
        case "pdf":
          // 下载PDF
          window.open(item.pdfFile, "_blank");
          break;

        default:
          break;
      }
    },
    /**
     * dialog 按钮操作事件
     */
    dialogHandleCilck(active) {
      let requestData = {};
      switch (active) {
        case "makesure_choose_sign":
          // 确定选择自定义表单类型
          let sheetType = JSON.parse(
            JSON.stringify(this.dialogData.data[0].data[1])
          );
          this.dialogData.baseData.title = sheetType.value.label;
          this.dialogData.baseData.id = sheetType.value.value;
          this.dialogData.baseData.time = this.dialogData.data[0].data[0].value;
          requestData = {
            id: sheetType.value.value,
            time: this.dialogData.data[0].data[0],
          };
          this.$request(
            "post",
            "/clientTemplate/getClientTemplateById",
            requestData,
            (data) => {
              this.dialogData.loading = true;
              setTimeout(() => {
                this.dialogData.title = "客户基本信息录入";
                this.dialogData.data = data.data;
                this.dialogData.type = "sign";
                this.dialogData.buttonList = [
                  {
                    name: "确定",
                    active: "makesure_sign",
                  },
                  { name: "取消", active: "cancel" },
                ];
                this.dialogData.loading = false;
              });
            }
          );
          break;
        case "makesure_sign":
          // 确定录入客户基本信息
          requestData = {
            clientId: this.tempClient.id,
            title: this.dialogData.baseData.title,
            time: this.dialogData.baseData.time,
            templateData: JSON.parse(JSON.stringify(this.dialogData.data)),
          };
          console.log("确定录入客户基本信息:", requestData);

          // 非空判断
          for (
            let index = 0;
            index < requestData.templateData.length;
            index++
          ) {
            const element = requestData.templateData[index];
            if (element.not_null) {
              // 不可为空
              if (element.type == "6") {
                // 图片
                if (element.itemType == "1") {
                  // 身份证正反面
                  if (element.value2 == "" || element.value1 == "") {
                    this.$message({
                      message: "身份证正反面不可为空!",
                      type: "error",
                    });
                    return;
                  }
                } else if (element.value1 == "") {
                  // 普通图片 或 营业执照
                  this.$message({
                    message: element.title + "不可为空!",
                    type: "error",
                  });
                  return;
                }
              } else if (element.value == "" || element.value == []) {
                this.$message({
                  message: element.title + "不可为空!",
                  type: "error",
                });
                return;
              }
            }
          }

          this.$request("post", "/client/saveFormInfo", requestData, (data) => {
            this.$message({
              message: "客户基本信息录入成功!",
              type: "success",
            });
            this.dialogData.flag = false;
            this.getAllCompanyList();
          });
          break;
        case "qrcode":
          // 二维码下载
          this.downloadIamge(this.dialogData.url, "二维码");
          break;
        case "synchronous":
          //  同步企查查
          this.dialogData.loading = true;
          requestData = {
            clientId: this.tempClient.id,
            clientName: this.dialogData.data[0].data[0].value,
          };
          this.$request(
            "post",
            "/client/synchroBusinessInfo",
            requestData,
            (data) => {
              setTimeout(() => {
                this.dialogData.data[0].data[1].value = data.companyCode;
                this.dialogData.data[0].data[2].value = data.legalPersonName;
                this.dialogData.loading = false;
              }, 500);
            }
          );
          break;
        case "makesure_aaum":
          //  确定更新工商信息
          this.dialogData.loading = true;
          let dddd = CompanyData.formatCompanyInfo(
            this.dialogData.data[0].data
          );
          if (!dddd.flag) {
            this.$message({
              message: dddd.mes,
              type: "error",
            });
            return;
          }
          requestData = {
            clientId: this.tempClient.id,
            companyName: this.dialogData.data[0].data[0].value,
            companyCode: this.dialogData.data[0].data[1].value,
            legalPersonName: this.dialogData.data[0].data[2].value, // 法定代表人
            legalPersonPhone: this.dialogData.data[0].data[3].value, // 法定代表人手机号码
            siteAddress: this.dialogData.data[0].data[4].value, // 实际经营场地地址
            legalPersonDocumentType: this.dialogData.data[0].data[5].value, // 法定代表人证件类型
            legalPersonIdNumber: this.dialogData.data[0].data[6].value, // 法定代表人证件号码
            legalPersonEmail: this.dialogData.data[0].data[7].value, // 法定代表人邮箱
            remark: this.dialogData.data[0].data[8].value, // 备注
          };
          this.$request(
            "post",
            "/client/saveCompanyCodeById",
            requestData,
            (data) => {
              setTimeout(() => {
                this.$message({
                  message: "工商信息修改成功!",
                  type: "success",
                });
                this.dialogData.loading = false;
              }, 500);
            }
          );
          break;
        case "cancel":
          // 取消
          this.dialogData.flag = false;
          break;

        default:
          break;
      }
    },
    /**
     * 图片下载
     */
    downloadIamge(imgsrc, name) {
      //下载图片地址和图片名

      var image = new Image();

      // 解决跨域 Canvas 污染问题

      image.setAttribute("crossOrigin", "anonymous");

      image.onload = function() {
        var canvas = document.createElement("canvas");

        canvas.width = image.width;

        canvas.height = image.height;

        var context = canvas.getContext("2d");

        context.drawImage(image, 0, 0, image.width, image.height);

        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据

        var a = document.createElement("a"); // 生成一个a元素

        var event = new MouseEvent("click"); // 创建一个单击事件

        a.download = name || "photo"; // 设置图片名称

        a.href = url; // 将生成的URL设置为a.href属性

        a.dispatchEvent(event); // 触发a的单击事件
      };

      image.src = imgsrc;
    },
  },
  mounted() {
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    let dd = {
      id: "",
      name: "客户管理",
      auth: "2",
      path: "/main/company/my_company",
    };
    sessionStorage.setItem("childernMenu", JSON.stringify(dd));
    this.getUserAuthority();
  },
};
</script>

<style lang="less" scoped>
#my-company {
  width: 100%;
  height: 100%;
  .search-line {
    width: 100%;
    height: auto;
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    .el-input {
      width: 23% !important;
      height: 30px !important;
      margin-left: 10px !important;
      margin-top: 5px;
      .el-input__inner {
        height: 30px !important;
      }
    }
    .el-select,
    .el-range-editor {
      width: 23% !important;
      margin-left: 10px !important;
      margin-top: 5px;
    }
  }
  .content {
    width: 98%;
    height: 0;
    flex: 1;
    .tip {
      color: @baseColor;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: @activeColor;
      }
    }
  }
  .page {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
  }
  .buts {
    width: 100%;
    height: 50px;
    .but {
      width: auto;
      height: auto;
      padding: 5px 15px;
      margin: 0 10px;
      box-sizing: border-box;
      border-radius: 8px;
      background-color: @baseColor;
      color: #ffffff;
      font-size: @font-size-tip-mini;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        background-color: @but-hoverColor;
      }
    }
  }
}
</style>
