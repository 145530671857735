<template>
  <div id="register">
    <div class="content">
      <div class="register-logo">
        <img src="../assets/img/logo3.png" alt="" />
      </div>
      <div class="register-title">
        用户注册
      </div>
      <div class="register-form">
        <div class="register-form-line">
          <div class="register-form-line-title">
            <span class="required">*</span> 证件类型
          </div>
          <div class="register-form-line-value">
            <el-select
              v-model="cardType"
              placeholder="请选择证件类型"
              style="width: 100%"
            >
              <el-option
                v-for="item in cardTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                clearable
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="register-form-line">
          <div class="register-form-line-title">
            <span class="required">*</span>身份证
          </div>
          <div class="register-form-line-value">
            <el-input
              v-model="idCard"
              placeholder="请输入身份证号"
              clearable
            ></el-input>
          </div>
        </div>
        <div class="register-form-line">
          <div class="register-form-line-title">
            性别
          </div>
          <div class="register-form-line-value">
            <el-radio-group v-model="sex">
              <el-radio :label="'0'">男</el-radio>
              <el-radio :label="'1'">女</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="register-form-line">
          <div class="register-form-line-title">
            <span class="required">*</span>真实姓名
          </div>
          <div class="register-form-line-value">
            <el-input
              v-model="userName"
              placeholder="请输入真实姓名"
              clearable
            ></el-input>
          </div>
        </div>
        <div class="register-form-line">
          <div class="register-form-line-title">
            电子邮箱
          </div>
          <div class="register-form-line-value">
            <el-input
              v-model="email"
              placeholder="请输入电子邮箱"
              clearable
            ></el-input>
          </div>
        </div>
        <div class="register-form-line">
          <div class="register-form-line-title">
            地址
          </div>
          <div class="register-form-line-value">
            <el-input
              v-model="address"
              placeholder="请输入居住地址"
              clearable
            ></el-input>
          </div>
        </div>
        <div class="register-form-line">
          <div class="register-form-line-title">
            <span class="required">*</span>手机号
          </div>
          <div class="register-form-line-value">
            <el-input
              v-model="phone"
              placeholder="请输入手机号"
              clearable
            ></el-input>
          </div>
        </div>
        <div class="register-form-line">
          <div class="register-form-line-title">
            <span class="required">*</span>验证码
          </div>
          <div class="register-form-line-value">
            <el-input
              v-model="verification"
              placeholder="请输入验证码"
              clearable
            >
              <el-button
                slot="append"
                type="primary"
                @click="getVerification"
                >{{ butTip }}</el-button
              ></el-input
            >
          </div>
        </div>
        <div class="register-form-line">
          <div class="register-form-line-title">
            <span class="required">*</span>密码
          </div>
          <div class="register-form-line-value">
            <el-input
              v-model="password"
              placeholder="请输入账号"
              clearable
              show-password
            ></el-input>
          </div>
        </div>
        <div class="register-form-line">
          <div class="register-form-line-title">
            <span class="required">*</span>确认密码
          </div>
          <div class="register-form-line-value">
            <el-input
              v-model="password1"
              placeholder="请输入确认密码"
              clearable
              show-password
            ></el-input>
          </div>
        </div>
      </div>
      <div class="register-button">
        <div class="register-button-item" @click="register">注册</div>
        <div class="register-button-item reset" @click="reset">重置</div>
      </div>
      <div class="register-cut">
        <span @click="goto">切换登录</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      idCard: "", // 身份证 非空
      cardType: "第二代居民身份证", // 证件类型 非空
      cardTypeList: [
        {
          label: "第二代居民身份证",
          value: "第二代居民身份证",
        },
      ],
      sex: "0", // 性别
      userName: "", // 用户姓名 非空
      email: "", // 邮箱
      address: "", // 地址
      phone: "", // 手机号 非空
      password: "", // 登录密码 非空
      password1: "", // 登录密码 非空
      verification: "", // 验证码

      isDisable: false,
      butTip: "获取验证码",
      timer: null,
      countdown: 60,
    };
  },
  methods: {
    /**
     * 获取验证码
     */
    getVerification() {
      if (this.phone == "") {
        this.$message.error("手机号不能为空!");
        return;
      }
      if (!this.$mobilePhoneNumberCheck(this.phone)) {
        this.$message.error("手机号格式不对!");
        return;
      }
      let requestData = {
        phone: this.phone,
      };
      if (this.timer) {
        return;
      }
      this.$request(
        "post",
        "/user/sendVerificationCode",
        requestData,
        (data) => {
          this.$message.success("验证码发送成功!");
          this.isDisable = true;
          if (!this.timer) {
            this.timer = setInterval(() => {
              if (this.countdown > 0 && this.countdown <= 60) {
                this.countdown--;
                if (this.countdown !== 0) {
                  this.butTip = "重新发送(" + this.countdown + ")";
                } else {
                  clearInterval(this.timer);
                  this.butTip = "获取验证码";
                  this.countdown = 60;
                  this.timer = null;
                  this.isDisable = false;
                }
              }
            }, 1000);
          }
        },
        (errormessage) => {
          this.$message.error(errormessage);
        }
      );
    },
    /**
     * 回到登录页面
     */
    goto() {
      this.$router.push("/");
    },
    /**
     * 注册
     */
    register() {
      if (this.idCard == "") {
        this.$message({
          message: "身份证号不能为空!",
          type: "error",
        });
        return;
      }
      if (!this.$idCardValidity(this.idCard)) {
        this.$message({
          message: "身份证格式不对!",
          type: "error",
        });
        return;
      }
      if (this.cardType == "") {
        this.$message({
          message: "证件类型不能为空!",
          type: "error",
        });
        return;
      }
      if (this.userName == "") {
        this.$message({
          message: "真实姓名不能为空!",
          type: "error",
        });
        return;
      }
      if (this.phone == "") {
        this.$message({
          message: "手机号不能为空!",
          type: "error",
        });
        return;
      }
      if (!this.$mobilePhoneNumberCheck(this.phone)) {
        this.$message({
          message: "手机号格式不对!",
          type: "error",
        });
        return;
      }
      if (this.verification == "") {
        this.$message({
          message: "验证码不能为空!",
          type: "error",
        });
        return;
      }
      if (this.password == "" || this.password1 == "") {
        this.$message({
          message: "登录密码不能为空!",
          type: "error",
        });
        return;
      }
      if (this.password != this.password1) {
        this.$message({
          message: "两次登录密码不一致!",
          type: "error",
        });
        return;
      }

      let requestData = {
        idCard: this.idCard, // 身份证 非空
        cardType: this.cardType, // 证件类型 非空
        sex: this.sex, // 性别
        userName: this.userName, // 用户姓名 非空
        email: this.email, // 邮箱
        address: this.address, // 地址
        phone: this.phone, // 手机号 非空
        verification: this.verification, // 返回的验证码
        password: this.password, // 登录密码 非空
      };
      this.$request(
        "post",
        "/user/register",
        requestData,
        (data) => {
          this.$message.success("注册成功!");
          this.$router.push("/");
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 重置
     */
    reset() {
      this.idCard = "";
      this.cardType = "第二代居民身份证";
      this.sex = "0";
      this.userName = "";
      this.email = "";
      this.address = "";
      this.phone = "";
      this.password = "";
      this.password1 = "";
      this.verification = "";
    },
  },
  mounted() {
    sessionStorage.removeItem("userData");
  },
};
</script>

<style lang="less" scoped>
#register {
  width: 100%;
  height: 100%;
  background-image: url("../assets/img/login_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  .content {
    position: absolute;
    right: 0;
    top: 0;
    width: 25%;
    min-width: 500px;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    .register-logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 40px 0;
      img {
        width: 55%;
        height: auto;
      }
    }
    .register-title {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    .register-form {
      width: 100%;
      height: 50%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
      }
      &:-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      }

      .register-form-line {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 10px 0;
        font-size: 14px;
        .register-form-line-title {
          display: flex;
          justify-content: flex-end;
          width: 20%;
          min-width: 60px;
          margin-right: 10px;
          .required {
            color: red;
            font-size: 16px;
            margin: 5px 5px 0 0;
          }
        }
        .register-form-line-value {
          width: 75%;
          text-align: left;
        }
      }
    }
    .register-button {
      display: flex;
      justify-content: space-around;
      margin: 20px 0;
      .register-button-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40%;
        height: 40px;
        color: #fff;
        background-color: #409eff;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background-color: #66b1ff;
          transition: 0.4s;
        }
      }
      .reset {
        background-color: #909399;
        &:hover {
          background-color: #c0c4cc;
          transition: 0.4s;
        }
      }
    }
    .register-cut {
      display: flex;
      justify-content: flex-end;
      font-size: 12px;
      font-weight: 700;
      color: @baseColor;
      margin-top: 10px;
      span {
        cursor: pointer;
      }
    }
  }
}
</style>
