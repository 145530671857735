<template>
  <div id="user-list" class="div-flex-column-center" v-loading="loading">
    <!-- 用户管理 -->
    <div class="search-line">
      <el-input
        v-model="searchData.name"
        placeholder="用户名称"
        clearable
        @keyup.enter.native="resetPage"
      ></el-input>
      <el-date-picker
        v-model="searchData.time"
        type="daterange"
        value-format="yyyy-MM-dd"
        @change="resetPage"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
      <el-select
        v-model="searchData.role"
        clearable
        filterable
        placeholder="请选择用户角色"
        @change="resetPage"
      >
        <el-option
          v-for="(it, i) in searchData.roleList"
          :key="i"
          :label="it.name"
          :value="it.id"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="searchData.status"
        placeholder="请选择用户状态"
        @change="resetPage"
      >
        <el-option label="正常" value="0"> </el-option>
        <el-option label="停用" value="1"> </el-option>
      </el-select>
    </div>
    <div class="content div-flex-center">
      <el-table :data="tableData" border style="width: 90%" height="98%">
        <el-table-column
          :prop="item.key"
          :label="item.title"
          :width="item.width"
          :fixed="item.key == 'orderNo' ? true : item.fixed"
          v-for="(item, index) in tableMenu"
          :key="index"
        >
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="180" align="left">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row, 'qy')"
              type="text"
              size="small"
              v-if="scope.row.status == '停用'"
              >启用</el-button
            >
            <el-button
              @click="handleClick(scope.row, 'ty')"
              type="text"
              size="small"
              v-if="scope.row.status == '正常'"
              >停用</el-button
            >
            <el-button
              @click="handleClick(scope.row, 'checkPhone')"
              type="text"
              size="small"
              >更换手机号</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page">
      <el-pagination
        @current-change="getAllUser"
        :current-page.sync="pageData.currentPage"
        :page-size="pageData.pageSize"
        layout="total, prev, pager, next,jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>
    <div id="my-dialog" class="div-flex-center" v-if="dialogData.flag">
      <div
        class="my-dialog-content div-flex-column-center"
        v-loading="dialogData.loading"
      >
        <div class="top div-flex-center">
          <div class="title">{{ dialogData.title }}</div>
          <div class="perch-active"></div>
          <div class="close" v-if="dialogData.closeFlag">
            <i class="el-icon-close" @click="dialogData.flag = false"></i>
          </div>
        </div>
        <div
          class="content"
          v-if="dialogData.type == 'edit' || dialogData.type == 'add'"
          style="width: 100%"
        >
          <div class="edit">
            <information_input :informationInputData="dialogData.data">
            </information_input>
          </div>
        </div>
        <div class="buts div-flex-center">
          <div
            class="but"
            v-for="(item, index) in dialogData.buttonList"
            :key="index"
            @click="dialogHandleCilck(item.active)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SheetDetails from "../../components/sheet-detials.vue";
import * as CompanyData from "../../assets/js/companyData.js";
export default {
  components: { SheetDetails },
  data() {
    return {
      loading: false,
      userData: {
        userId: "",
        userName: "",
        icon: "",
      },
      searchData: {
        name: "", // 企业名称
        role: "",
        roleList: [],
        time: [],
        status: "0",
      },
      pageData: {
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页条数
        total: 0, // 总条目数
      },
      tableData: [],
      tableMenu: [
        {
          key: "userName",
          title: "用户名称",
          width: "",
          fixed: false,
        },
        {
          key: "phone",
          title: "手机号",
          width: "200",
          fixed: false,
        },
        {
          key: "roleName",
          title: "角色",
          width: "120",
          fixed: false,
        },
        {
          key: "status",
          title: "状态",
          width: "80",
          fixed: false,
        },
        {
          key: "idCard",
          title: "身份证号",
          width: "220",
          fixed: false,
        },
        {
          key: "createTime",
          title: "注册时间",
          width: "200",
          fixed: false,
        },
      ],
      dialogData: {
        loading: true,
        closeFlag: true,
        flag: false,
        title: "",
        type: "show_information",
        data: [],
        url: require("../../assets/img/qr_code.jpg"),
        buttonList: [],
      },
      tableOper: [],
      tempData: {},
    };
  },
  methods: {
    /**
     * 2.3 获取当前角色权限
     */
    getUserAuthority() {
      this.$baseData.getUserAuthority(this.userData.userId, this, (data) => {
        this.tableOper = this.$baseData.judgeButtonPermissions(
          data[0].model,
          this.tableOper,
          "企业管理",
          "用户管理"
        );
        console.log("当前界面按钮权限:", this.tableOper);
        this.getAllRole();
      });
    },
    getAllRole() {
      let requestData = {};
      this.$request("post", "/role/getAllRoleList", requestData, (data) => {
        this.searchData.roleList = data;
        this.getAllUser();
      });
    },
    getAllUser() {
      let requestData = {
        userId: this.userData.userId,
        currentPage: this.pageData.currentPage,
        pageSize: this.pageData.pageSize,
        name: this.searchData.name,
        startTime:
          this.searchData.time.length < 1 ? "" : this.searchData.time[0],
        endTime: this.searchData.time.length < 1 ? "" : this.searchData.time[1],
        roleId: this.searchData.role,
        status: this.searchData.status,
      };
      this.$request("post", "/user/getAllUser", requestData, (data) => {
        this.pageData.total = data.total;
        this.tableData = data.tableData;
      });
    },
    /**
     * 重置 page 请求列表数据
     */
    resetPage() {
      this.pageData.currentPage = 1;
      this.getAllUser();
    },
    handleClick(item, active) {
      this.tempData = item;
      let requestData = {};
      switch (active) {
        case "qy":
          // 启用账号
          requestData = {
            userId: item.id,
            status: "1",
          };
          this.$request(
            "post",
            "/user/startOrStopUser",
            requestData,
            (data) => {
              this.getAllUser();
              this.$message({
                type: "success",
                message: "账号启用成功!",
              });
            }
          );
          break;
        case "ty":
          // 停用账号
          this.$confirm("此操作将停止该用户的一切操作, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              requestData = {
                userId: item.id,
                status: "0",
              };
              this.$request(
                "post",
                "/user/startOrStopUser",
                requestData,
                (data) => {
                  this.getAllUser();
                  this.$message({
                    type: "success",
                    message: "账号停用成功!",
                  });
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
          break;
        case "checkPhone":
          // 更换手机号
          this.dialogData.type = "edit";
          this.dialogData.title = "更换用户手机号";
          this.dialogData.data = [
            {
              title: "更换用户手机号",
              data: [
                {
                  title: "修改手机号",
                  type: "input",
                  value: "",
                  placeholder: "请输入",
                  tip: true, // 是否为必填项
                  landscape: true, // 是否横屏显示
                  key: "newPhone",
                },
                {
                  title: "确认修改手机号",
                  type: "input",
                  value: "",
                  placeholder: "请输入",
                  tip: true, // 是否为必填项
                  landscape: true, // 是否横屏显示
                  key: "newPhone2",
                },
              ],
            },
          ];
          this.dialogData.buttonList = [
            {
              name: "确定",
              active: "sure_edit",
            },
            {
              name: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.loading = false;
          this.dialogData.flag = true;
          break;
        default:
          break;
      }
    },
    dialogHandleCilck(active) {
      let requestData = {};
      switch (active) {
        case "sure_edit":
          // 确认修改手机号
          requestData = {
            id: this.tempData.id,
            phone: this.dialogData.data[0].data[0].value, // 修改手机号
            phone2: this.dialogData.data[0].data[1].value, // 修改手机号
          };
          if (!this.$mobilePhoneNumberCheck(requestData.phone2)) {
            this.$message({
              message: "手机号格式不对!",
              type: "error",
            });
            return;
          }
          if (requestData.phone !== requestData.phone2) {
            this.$message.error("两次输入的手机号不一致");
            return;
          }
          this.$request(
            "post",
            "/user/update",
            requestData,
            (data) => {
              this.dialogData.flag = false;
              this.$message.success("修改成功");
              this.getAllUser();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          this.dialogData.flag = false;
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    let dd = {
      id: "",
      name: "用户管理",
      auth: "2",
      path: "/main/company/user_list",
    };
    sessionStorage.setItem("childernMenu", JSON.stringify(dd));
    this.getUserAuthority();
  },
};
</script>

<style lang="less" scoped>
#user-list {
  width: 100%;
  height: 100%;
  .search-line {
    width: 100%;
    height: auto;
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    .el-input {
      width: 23% !important;

      margin-left: 10px !important;
      margin-top: 5px;
      .el-input__inner {
        height: 30px !important;
      }
    }
    .el-select,
    .el-range-editor {
      width: 23% !important;
      margin-left: 10px !important;
      margin-top: 5px;
    }
  }
  .content {
    width: 98%;
    height: 0;
    flex: 1;
    .tip {
      color: @baseColor;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: @activeColor;
      }
    }
  }
  .page {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
  }
  .buts {
    width: 100%;
    height: 50px;
    .but {
      width: auto;
      height: auto;
      padding: 5px 15px;
      margin: 0 10px;
      box-sizing: border-box;
      border-radius: 8px;
      background-color: @baseColor;
      color: #ffffff;
      font-size: @font-size-tip-mini;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        background-color: @but-hoverColor;
      }
    }
  }
}
</style>
