<template>
  <div id="create-order" class="div-flex-column-center" v-loading="loading">
    <!-- 新设立申请 -->
    <div class="ready div-flex-column-center" v-if="!flag">
      <!-- 业务信息填写前的地址选择 -->
      <el-tabs v-model="activeName1">
        <el-tab-pane label="新设立" name="新设立"> </el-tab-pane>
      </el-tabs>

      <!-- <div class="top-tip div-flex-column-center">
        <div class="title">请选择下面的一个地址进行注册</div>
        <div class="content" v-html="tip"></div>
      </div> -->
      <div class="area-selection div-flex-column-center">
        <div class="area-selection-content">
          <!-- 区域选择 -->
          <div class="area-selection-content-area" v-if="addressFlag == false">
            <div class="area-selection-content-area-item">
              <div class="area-selection-content-area-item-title">
                安徽省
              </div>
              <el-divider></el-divider>
              <div class="area-selection-content-area-item-list">
                <div
                  class="list-item"
                  v-for="item in provinceList"
                  :key="item.value"
                  @click="changeArea(item)"
                >
                  {{ item.label }}
                </div>
              </div>
            </div>
            <div class="area-selection-content-area-item">
              <div class="area-selection-content-area-item-title">
                合肥市
              </div>
              <el-divider></el-divider>
              <div class="area-selection-content-area-item-list">
                <div
                  class="list-item"
                  v-for="item in cityList"
                  :key="item.value"
                  @click="changeArea(item)"
                >
                  {{ item.label }}
                </div>
              </div>
            </div>
          </div>
          <div class="area-selection-content-address" v-else>
            <div
              class="area-selection-content-address-item"
              v-for="(item, index) in addressList"
              :key="index"
            >
              <template v-for="(it, ind) in item.newData">
                <!-- 个体户 -->
                <div
                  :class="{
                    'area-selection-content-address-item-i': true,
                    active:
                      item.id == areaInfo.addressId &&
                      ind == areaInfo.currentIndex,
                  }"
                  v-if="it.selectFlag == '0'"
                  :key="ind"
                  @click="changeAddress(item, it, ind)"
                >
                  <div class="image">
                    <i class="iconfont icon-qiyegetihumingcheng"></i>
                    <span style="color: #40A3FF">个体户</span>
                  </div>
                  <div class="info">
                    <div class="info-line">
                      <span class="title">标签: </span>
                      <span class="text">
                        {{ it.tag }}
                      </span>
                    </div>
                    <div class="info-line">
                      <span class="title">价格: </span>
                      <div>
                        <span class="price" v-if="it.realPrice !== it.price">
                          {{ it.price }}
                        </span>
                        <span class="disPrice person">
                          {{ it.realPrice }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 企业 -->
                <div
                  :class="{
                    'area-selection-content-address-item-i': true,
                    active:
                      item.id == areaInfo.addressId &&
                      ind == areaInfo.currentIndex,
                  }"
                  v-else-if="it.selectFlag == '1'"
                  :key="ind"
                  @click="changeAddress(item, it, ind)"
                >
                  <div class="image">
                    <i class="iconfont icon-qiye"></i>
                    <span class="company">企业</span>
                  </div>
                  <div class="info">
                    <div class="info-line">
                      <span class="title">标签: </span>
                      <span class="text">
                        {{ it.tag }}
                      </span>
                    </div>
                    <div class="info-line">
                      <span class="title">价格: </span>
                      <div>
                        <span class="price" v-if="it.realPrice !== it.price">
                          {{ it.price }}
                        </span>
                        <span class="disPrice company">
                          {{ it.realPrice }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 产品包 -->
                <div
                  :class="{
                    'area-selection-content-address-item-i': true,
                    active:
                      item.id == areaInfo.addressId &&
                      ind == areaInfo.currentIndex,
                  }"
                  v-if="it.selectFlag == '2' || it.selectFlag == '3'"
                  :key="ind"
                  @click="changeAddress(item, it, ind)"
                >
                  <div class="image">
                    <i class="iconfont icon-zengsong"></i>
                    <span style="color: #40A3FF">{{
                      it.selectFlag == "2" ? "产品包(个体户)" : "产品包(企业)"
                    }}</span>
                  </div>
                  <div class="info">
                    <div class="info-line">
                      <span class="title">标题: </span>
                      <span class="text">
                        {{ it.title }}
                      </span>
                    </div>
                    <div class="info-line">
                      <span class="title">价格: </span>
                      <div>
                        <span class="price"> </span>
                        <span class="disPrice person">
                          {{ it.price }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="area-selection-buts" v-if="addressFlag">
          <div class="but div-flex-center" @click="lastStep">
            上一步
          </div>
          <div class="but div-flex-center" @click="startApplication">
            启动申请
          </div>
        </div>
      </div>
    </div>
    <div class="fill-in-information div-flex-column-center" v-else>
      <!-- 业务信息填写 -->
      <el-tabs v-model="activeName">
        <el-tab-pane label="基本信息" name="基本信息" :disabled="showText">
        </el-tab-pane>
        <el-tab-pane label="信息确认" name="信息确认" :disabled="!showText">
        </el-tab-pane>
      </el-tabs>
      <div class="information-content div-flex-column-center" v-if="!showText">
        <information_input
          :informationInputData="orderList"
          @chooseScopeBusiness="chooseScopeBusiness"
          @getVerification="getVerification"
          @handleAvatarSuccess="handleAvatarSuccess"
          @handleAvatarSuccessByIdCard="handleAvatarSuccessByIdCard"
          @beforeAvatarUpload="beforeAvatarUpload"
          @radioChange="radioChange($event, '企业基本信息')"
        ></information_input>
        <div class="buts">
          <div class="but" @click="save(0)">暂存</div>
          <div class="occupi"></div>
          <div class="but" @click="changeFormat">下一步</div>
        </div>
      </div>
      <div class="information-content div-flex-column-center" v-if="showText">
        <information_text :informationTextData="orderList"></information_text>
        <div class="buts">
          <div class="but" @click="previousStep">上一步</div>
          <div class="occupi"></div>
          <div class="but" @click="submit">提交</div>
        </div>
      </div>
    </div>
    <div id="my-dialog" class="div-flex-center" v-if="dialogData.flag">
      <div
        class="my-dialog-content div-flex-column-center"
        v-loading="dialogData.loading"
        v-if="dialogData.type == 'business'"
      >
        <div class="top div-flex-center">
          <div class="title">经营范围</div>
          <div class="perch-active"></div>
          <div class="close">
            <i class="el-icon-close" @click="dialogData.flag = false"></i>
          </div>
        </div>

        <div class="type div-flex-center">
          <div class="type1">
            <el-select
              v-model="dialogData.selectType"
              placeholder="请选择经营范围类别"
              @change="queryCommodityById"
            >
              <el-option
                v-for="item in dialogData.seteletOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="perch-active"></div>
          <div class="type1">
            <el-input
              v-model="filterText"
              placeholder="输入关键字进行过滤"
              clearable
              v-if="dialogData.selectType != ''"
            ></el-input>
          </div>
        </div>

        <div class="tree-data div-flex-center">
          <div class="tree-data-bor">
            <el-tree
              :data="dialogData.treeData"
              show-checkbox
              node-key="id"
              :props="defaultProps"
              :filter-node-method="filterNode"
              check-strictly
              ref="tree"
            >
            </el-tree>
          </div>
          <div class="tree-data-buts">
            <el-button
              type="primary"
              plain
              icon="el-icon-plus"
              @click="butClick(0)"
              >添加</el-button
            >
            <el-button
              type="danger"
              plain
              icon="el-icon-minus"
              @click="butClick(1)"
              >移除</el-button
            >
            <el-button
              type="success"
              plain
              icon="el-icon-check"
              @click="butClick(2)"
              >确认</el-button
            >
          </div>
          <div class="tree-data-bor">
            <div
              class="choose-tree-data"
              v-for="(item, index) in dialogData.selectTreeData"
              :key="index"
            >
              <el-checkbox v-model="item.checked">{{ item.name }}</el-checkbox>
            </div>
          </div>
        </div>
      </div>

      <div
        class="my-dialog-content div-flex-column-center"
        v-if="dialogData.type == 'show_information'"
      >
        <div class="top div-flex-center">
          <div class="title">提交订单</div>
          <div class="perch-active"></div>
          <div class="close">
            <i class="el-icon-close" @click="dialogData.flag = false"></i>
          </div>
        </div>

        <div class="content" v-if="dialogData.type == 'show_information'">
          <information_input
            :informationInputData="dialogData.data"
            @radioChange="radioChange($event, '合同开始信息')"
          >
          </information_input>
        </div>
        <div
          class="buts div-flex-center"
          v-if="dialogData.type == 'show_information'"
        >
          <div
            class="but"
            v-for="(item, index) in dialogData.buttonList"
            :key="index"
            :style="{
              backgroundColor:
                item.active == 'submit' && isGray ? '#C0C4CC' : '#409EFF',
            }"
            @click="dialogHandleCilck(item.active)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as instructionsData from "../../assets/js/instructions.js";
export default {
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  data() {
    return {
      addressFlag: false,
      isGray: false, // 余额是否不足
      loading: true,
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
      tip: instructionsData.getTip(1),
      flag: false, // 当前有没有选择地址 true 选择了地址, 并启动了申请
      userData: {
        userId: "",
        userName: "",
        icon: "",
      },
      selectValue: "1",
      selectList: [
        { label: "安徽省", value: "0" },
        { label: "合肥市", value: "1" },
      ],
      areaInfo: {
        areaId: "", // 所选区域ID
        addressId: "", // 地址规则ID
        selectFlag: "0", // 地址标识 0 - 个体户；1 - 企业
        productPackageId: "", // 产品包 id
        currentIndex: "", // 当前选择index
      },
      provinceList: [], // 所有省份
      cityList: [], // 所有城市
      areaList: instructionsData.getAreaList(), // 可供选择的所有区域
      activeName: "基本信息",
      activeName1: "新设立",
      showText: false, // 是否以文本形式展示信息
      orderList: [],
      id: "", // 暂存提交前保存后返回的ID
      dialogData: {
        loading: true,
        flag: false,
        title: "经营范围",
        type: "business",
        data: [],
        selectType: "",
        seteletOptions: [],
        search: "",
        treeData: [], // 筛选之后的tree数据
        selectTreeData: [], // 选择要添加的数据
        chooseSelectData: [], // 选择要移除的数据
        buttonList: [
          {
            name: "确定",
            active: "submit",
          },
          {
            name: "取消",
            active: "cancel",
          },
        ],
      },
      filterText: "", // 树筛选关键字
      defaultProps: {
        children: "children",
        label: "name",
      },
      props: {
        children: "options",
      },
      addressList: [],
      type: 0,
      userAccount: 0,
    };
  },
  methods: {
    /**
     * 4.5 获取所有正常代理状态的区域
     */
    getAllNormalAreaList() {
      let requestData = {
        flag: this.selectValue,
        search: {},
      };
      this.loading = true;
      this.$request(
        "post",
        "/area/getAllNormalAreaList",
        requestData,
        (data) => {
          this.areaList = data.list;
          setTimeout(() => {
            this.loading = false;
          }, 500);
        }
      );
    },
    /**
     * 获取所有区域列表
     */
    getAreaSelectOption() {
      this.$request(
        "get",
        "/area/getAreaSelectOption?flag=1", // 0 - 查询未代理；1 - 查询已代理；不传查询全部
        {},
        (data) => {
          data.forEach((item) => {
            if (item.label == "安徽省") {
              this.provinceList = item.options;
            } else if (item.label == "合肥市") {
              this.cityList = item.options;
            }
          });
          setTimeout(() => {
            this.loading = false;
          }, 500);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 根据区域查询地址信息
     */
    changeArea(item) {
      this.areaInfo.areaId = item.value;
      this.$request(
        "get",
        "/address/queryByAreaId?areaId=" + this.areaInfo.areaId,
        {},
        (data) => {
          // 根据区域查询产品包信息
          let requestData = {
            userId: this.userData.userId, // 用户 id
            areaId: this.areaInfo.areaId, // 区域 id
          };
          this.$request(
            "post",
            "/productPackageGift/listByUserAndArea",
            requestData,
            (data2) => {
              this.addressList = [];
              data.forEach((address) => {
                address.newData = [
                  {
                    ...address,
                    price: address.priceIndividual,
                    realPrice: address.realPriceIndividual,
                    selectFlag: "0", // 0-个体户 1-企业 2-产品包
                  },
                  {
                    ...address,
                    price: address.priceEnterprise,
                    realPrice: address.realPriceEnterprise,
                    selectFlag: "1", // 0-个体户 1-企业 2-产品包
                  },
                ];
                if (data2.length > 0) {
                  data2.forEach((product) => {
                    // 如果有产品包 添加 selectFlag 2
                    if (address.id == product.productPackage.addressId) {
                      address.newData.push({
                        ...product.productPackage,
                        count: product.count,
                        selectFlag: product.productPackage.type, // 0-个体户 1-企业 2-产品包(个体户) 3-产品包(企业)
                      });
                    }
                  });
                }
              });
              this.addressList = data;
              console.log("this.addressList", this.addressList);
              this.addressFlag = true;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 选择区域地址
     */
    changeAddress(item, it, ind) {
      console.log("item", item);
      this.areaInfo.productPackageId = "";
      this.areaInfo.currentIndex = ind;
      this.areaInfo.addressId = item.id;
      this.areaInfo.selectFlag = it.selectFlag;
      if (it.selectFlag == "2" || it.selectFlag == "3") {
        // 选择的是产品包 添加产品包 id
        this.areaInfo.productPackageId = it.id;
      }
    },
    /**
     * 点击上一步
     */
    lastStep() {
      this.addressFlag = false;
      this.areaInfo = {
        areaId: "", // 所选区域ID
        addressId: "", // 地址规则ID
        selectFlag: "0", // 0-个体户 1-企业 2-产品包(个体户) 3-产品包(企业)
        productPackageId: "", // 产品包 id
        currentIndex: "",
      };
    },

    /**
     * 获取订单详情
     */
    getOrderDetailsById() {
      this.flag = true;
      this.orderList = instructionsData.getOrderData();
      if (this.areaInfo.selectFlag == "0" || this.areaInfo.selectFlag == "2") {
        // 个体户
        this.orderList[0].data[2].value = "个体工商户";
        this.orderList[0].data[2].options = instructionsData.companyTypeListByPerson();
      } else {
        this.orderList[0].data[2].options = instructionsData.companyTypeListByCompany();
      }
      this.loading = true;
      let requestData = {
        id: this.id,
      };
      this.$request(
        "post",
        "/orders/getOrderDetailsById",
        requestData,
        (data) => {
          this.id = data.id;
          this.areaInfo.areaId = data.areaId;
          this.areaInfo.selectFlag = data.selectFlag;
          this.areaInfo.addressId = data.addressId;
          this.areaInfo.productPackageId = data.productPackageId;
          this.orderList = instructionsData.matchOrderData(
            JSON.parse(JSON.stringify(this.orderList)),
            data
          );
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        }
      );
    },
    /**
     * 启动申请
     */
    startApplication() {
      if (
        !this.areaInfo.areaId ||
        !this.areaInfo.addressId ||
        !this.areaInfo.selectFlag
      ) {
        this.$message({
          message: "请先选择要注册的地址！",
          type: "warning",
        });
        return;
      }
      this.orderList = instructionsData.getOrderData();
      if (this.areaInfo.selectFlag == "0" || this.areaInfo.selectFlag == "2") {
        // 个体户
        this.orderList[0].data[2].value = "个体工商户";
        this.orderList[0].data[2].options = instructionsData.companyTypeListByPerson();
      } else {
        this.orderList[0].data[2].options = instructionsData.companyTypeListByCompany();
      }
      this.flag = true;
    },
    /**
     * 发送验证码
     */
    getVerification(item) {
      if (item.value == "") {
        this.$message({
          message: "手机号不能为空!",
          type: "error",
        });
        return;
      }
      if (!this.$mobilePhoneNumberCheck(item.value)) {
        this.$message({
          message: "手机号格式不对!",
          type: "error",
        });
        return;
      }
      let requestData = {
        phone: item.value,
      };
      this.$request(
        "post",
        "/user/sendVerificationCode",
        requestData,
        (data) => {
          this.$message({
            message: "验证码发送成功!",
            type: "success",
          });
          item.isDisable = true;
          if (!item.timer) {
            item.timer = setInterval(() => {
              if (item.countdown > 0 && item.countdown <= 60) {
                item.countdown--;
                if (item.countdown !== 0) {
                  item.text = "重新发送(" + item.countdown + ")";
                } else {
                  clearInterval(item.timer);
                  item.text = "获取验证码";
                  item.countdown = 60;
                  item.timer = null;
                  item.isDisable = false;
                }
              }
            }, 1000);
          }
        }
      );
    },
    /**
     * 信息填写父子组件连锁
     */
    show(data) {
      console.log("组件监听", data);
      // this.orderList = data;
    },
    /**
     * 文件上传前格式判断
     */
    beforeAvatarUpload(file) {
      console.log("上传文件格式:", file.type);
      const isJPG =
        file.type === "application/pdf" ||
        file.type === "image/jpeg" ||
        file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("上传文件只能是 PDF 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 3MB!");
      }
      return isJPG && isLt2M;
    },
    /**
     * 文件上传成功
     */
    handleAvatarSuccess(data) {
      console.log("文件上传成功!", data);
      switch (data.response.result.title) {
        case "经营场地证明":
          this.orderList[4].data[2].value = data.response.result.path;
          this.orderList[4].data[2].isDisable = true;
          this.orderList[4].data[2].text = data.file.name;
          break;
        default:
          break;
      }
    },
    handleAvatarSuccessByIdCard(data) {
      console.log("handleAvatarSuccessByIdCard", this.orderList[1].data[9]);
      let path = data.response.result.path;
      let name = data.file.name;
      switch (data.response.result.title) {
        case "承租方身份证明附件":
          this.$set(this.orderList[1].data[9].value, data.index, path);
          this.$set(this.orderList[1].data[9].text, data.index, name);
          break;
        case "身份证明附件":
          this.$set(this.orderList[2].data[5].value, data.index, path);
          this.$set(this.orderList[2].data[5].text, data.index, name);
          break;
        default:
          break;
      }
    },
    /**
     * 选择经营范围
     * 获取当前所选的经营范围数据
     *  9.2 获取所有货物的最外层的货物类型
     */
    chooseScopeBusiness() {
      this.dialogData.loading = true;
      let this_ = this;
      this.$request(
        "post",
        "/commodityCode/getQueryCommodityType",
        {},
        (data) => {
          this.dialogData.selectTreeData = this.orderList[0].data[4].truevalue;
          this.dialogData.seteletOptions = data;
          this.dialogData.type = "business";
          this.dialogData.flag = true;
          setTimeout(() => {
            this_.dialogData.loading = false;
          }, 500);
        }
      );
    },
    /**
     *9.1 根据id获取子编码树
     */
    queryCommodityById() {
      this.dialogData.loading = true;
      let this_ = this;
      let requestData = {
        id: this.dialogData.selectType,
        search: this.dialogData.search,
      };
      this.$request(
        "post",
        "/commodityCode/queryCommodityById",
        requestData,
        (data) => {
          this.dialogData.treeData = data;
          setTimeout(() => {
            this_.dialogData.loading = false;
          }, 500);
        }
      );
    },
    /**
     * 关键字筛选
     */
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    /**
     * 选择经营范围的中间三个按钮点击事件
     */
    butClick(flag) {
      switch (flag) {
        case 0:
          // 添加
          let chooseData = this.$refs.tree.getCheckedNodes(false, false);
          this.dialogData.selectTreeData = [];
          chooseData.forEach((element) => {
            this.$set(element, "checked", false);
            this.dialogData.selectTreeData.push(element);
          });
          break;
        case 1:
          // 移除
          this.dialogData.chooseSelectData = [];
          this.dialogData.selectTreeData.forEach((element) => {
            if (!element.checked) {
              this.dialogData.chooseSelectData.push(element);
            }
          });
          this.dialogData.selectTreeData = JSON.parse(
            JSON.stringify(this.dialogData.chooseSelectData)
          );
          break;
        case 2:
          // 提交
          let value = "";
          this.dialogData.selectTreeData.forEach((element, index) => {
            value = index == 0 ? element.name : value + "," + element.name;
          });
          this.orderList[0].data[4].value = value;
          this.orderList[0].data[4].truevalue = this.dialogData.selectTreeData;
          this.dialogData.flag = false;
          break;

        default:
          break;
      }
    },
    /**
     * 暂存
     */
    save(flag) {
      let this_ = this;
      let requestData = instructionsData.formatSubmitData(this.orderList, this);
      this.$set(requestData, "id", this.id);
      this.$set(requestData, "submitUser", this.userData.userId);
      this.$set(requestData, "areaId", this.areaInfo.areaId);
      this.$set(requestData, "addressId", this.areaInfo.addressId);
      this.$set(requestData, "selectFlag", this.areaInfo.selectFlag);
      this.$set(requestData, "type", "0");
      this.$set(requestData, "oldId", "");
      this.$set(
        requestData,
        "productPackageId",
        this.areaInfo.productPackageId
      );
      this.$request(
        "post",
        "/orders/saveOrUpdOrders",
        requestData,
        (data) => {
          switch (flag) {
            case 0:
              // 保存之后直接跳转到订单界面
              this.$baseData.saveOperationRecord(
                data.id,
                "修改订单!",
                this.userData.userId,
                "order",
                this,
                (saveData) => {
                  this.$message({
                    message: "保存成功!",
                    type: "success",
                  });
                  let dd = {
                    id: "",
                    name: "订单管理",
                    auth: "2",
                    path: "/main/order/order_management",
                  };
                  sessionStorage.setItem("childernMenu", JSON.stringify(dd));
                  this.$router.push({
                    path: "/main/order/order_management",
                    query: {
                      name: "订单管理",
                    },
                  });
                }
              );
              break;
            case 1:
              // 只保存
              this.id = data.id;
              // return new Promise((resolve, reject) => {
              //   resolve(data.id);
              // });
              break;

            default:
              break;
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 下一步
     * 下一步要非空校验
     * 非空校验通过后,
     */
    changeFormat() {
      let dt = instructionsData.checkToSubmitData(this.orderList, this);
      if (!dt.flag) {
        this.$message({
          message: dt.mes,
          type: "error",
        });
        return;
      }
      let lesseePhone = this.orderList[1].data[7].value;
      let lesseeVerificationCode = this.orderList[1].data[8].value;
      let requestData = {
        phone: this.orderList[2].data[3].value,
        name: this.orderList[2].data[0].value,
        idCard: this.orderList[2].data[2].value,
        lesseePhone: lesseePhone,
        verificationCode: lesseeVerificationCode,
      };
      this.$request(
        "post",
        "/orders/matchPhoneNumberVerificationCode",
        requestData,
        (data) => {
          this.activeName = "信息确认";
          this.showText = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 返回上一步
     */
    previousStep() {
      this.activeName = "基本信息";
      this.showText = false;
    },
    /**
     * 提交
     * 提交前,手动调用保存
     */
    submit() {
      this.dialogData.title = "";
      this.dialogData.type = "show_information";
      this.dialogData.data = [
        {
          title: "合同开始信息",
          tip: "",
          data: [
            {
              title: "合同开始日期:",
              tip: true, // 是否不得为空
              type: "time",
              key: "registeredCapital",
              value: this.$formatTime(new Date(), "YYYY-MM-DD"),
              options: [],
              landscape: true, // 是否横屏显示
              placeholder: "", // 提示文字
            },
            {
              title: "支付方式",
              tip: true, // 是否为必填项
              type: "radio_click",
              key: "flag",
              value: "0",
              options: [
                {
                  label: "余额扣除",
                  value: "0",
                },
                {
                  label: "在线支付",
                  value: "1",
                },
              ],
              landscape: true, // 是否横屏显示
              placeholder: "",
            },
          ],
        },
      ];
      this.dialogData.flag = true;
      this.queryUserAccount();
    },
    dialogHandleCilck(active) {
      switch (active) {
        case "cancel":
          // 取消
          this.dialogData.flag = false;
          break;
        case "submit":
          //
          if (this.dialogData.data[0].data[1].value == "0") {
            // 余额扣除
            if (this.isGray) {
              this.$message.info("余额不足, 请联系管理员进行充值!");
            } else {
              // 余额扣除
              let this_ = this;
              this.loading = true;
              this.save(1);
              setTimeout(() => {
                let requestData = {
                  orderId: this_.id,
                  startTime: this_.dialogData.data[0].data[0].value, // 订单开始时间
                  term: "1", // 订单期限,
                  submitUserId: this.userData.userId, // 提交用户 id
                };
                this_.$request(
                  "post",
                  "/orders/submitOrders",
                  requestData,
                  (data) => {
                    this_.$baseData.saveOperationRecord(
                      this_.id,
                      "提交订单",
                      this_.userData.userId,
                      "order",
                      this_,
                      (saveData) => {
                        this_.$message({
                          message: "提交成功!",
                          type: "success",
                        });
                        this_.loading = false;

                        let dd = {
                          id: "",
                          name: "订单管理",
                          auth: "2",
                          path: "/main/order/order_management",
                        };
                        sessionStorage.setItem(
                          "childernMenu",
                          JSON.stringify(dd)
                        );
                        this_.$router.push({
                          path: "/main/order/order_management",
                          query: {
                            name: "订单管理",
                          },
                        });
                      }
                    );
                  },
                  (errorMessage) => {
                    this.$message.error(errorMessage);
                  }
                );
              }, 3000);
            }
          } else if (this.dialogData.data[0].data[1].value == "1") {
            // 在线支付
            this.$message.info("该功能暂未开通!");
          }
          break;

        default:
          break;
      }
    },
    /**
     * 自然人与法人单选框切换事件
     */
    radioChange(value, title) {
      if (title == "企业基本信息") {
        switch (value) {
          case "自然人":
            this.orderList[1].data[1].show = false;
            this.orderList[1].data[1].value = "";
            this.orderList[1].data[2].value = "";
            this.orderList[1].data[2].show = false;
            break;
          case "法人":
            this.orderList[1].data[1].show = true;
            this.orderList[1].data[2].show = true;
            break;
          default:
            break;
        }
        console.log(this.orderList);
      } else if (title == "合同开始信息") {
        this.isGray = false;
        if (value == "0") {
          // 余额扣除 检查余额是否充足, 不足确认按钮为灰色
          this.queryUserAccount();
        }
      }
    },
    queryUserAccount() {
      // 查询用户账户余额
      this.$request(
        "get",
        "/user/queryMoney?userId=" + this.userData.userId,
        {},
        (data) => {
          this.userAccount = data;
          console.log(
            "this.areaInfo.productPackageId",
            this.areaInfo.productPackageId
          );
          if (this.areaInfo.productPackageId) {
            // 有产品包
            this.queryProductPackageDetail();
          } else {
            // 无产品包
            this.queryAddressDetail();
          }
        }
      );
    },
    /**
     * 根基产品包id 查询产品包信息
     */
    queryProductPackageDetail() {
      this.$request(
        "get",
        "/productPackage/getById?id=" + this.areaInfo.productPackageId,
        {},
        (data) => {
          // 判断账号余额是否充足 不足确认按钮为灰色
          if (this.userAccount < data.price) {
            this.isGray = true;
          } else {
            this.isGray = false;
          }
        }
      );
    },
    /**
     * 根基地址id 查询地址信息
     */
    queryAddressDetail() {
      this.$request(
        "get",
        "/address/queryPrice?addressId=" + this.areaInfo.addressId,
        {},
        (data) => {
          let price = 0;
          // 地址标识 0 - 个体户；1 - 企业
          if (this.areaInfo.selectFlag == "0") {
            price = data.realPriceIndividual;
          } else if (this.areaInfo.selectFlag == "1") {
            price = data.realPriceEnterprise;
          }
          // 判断账号余额是否充足 不足确认按钮为灰色
          if (this.userAccount < price) {
            this.isGray = true;
            this.$message.error("余额不足, 请联系管理员进行充值!");
          } else {
            this.isGray = false;
          }
        }
      );
    },
  },
  mounted() {
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    this.id = this.$route.query.id;
    let dd = {
      id: "",
      name: "新设立申请",
      auth: "2",
      path: "/main/order/instructions",
    };
    sessionStorage.setItem("childernMenu", JSON.stringify(dd));
    if (this.id == "") {
      // 从路由进入
      this.getAreaSelectOption();
    } else {
      // 从订单列表中带ID进入
      this.getOrderDetailsById();
    }
  },
};
</script>

<style lang="less" scoped>
#create-order {
  width: 100%;
  height: 100%;
  .ready {
    width: 100%;
    height: 100%;
    .top-tip {
      width: 95%;
      height: 15%;
      border-bottom: 1px solid @borderColor;
      box-sizing: border-box;
      font-size: 15px;
      .title {
        width: 100%;
        height: 35%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      .content {
        width: 90%;
        height: 0;
        flex: 1;
        color: red;
        text-align: left;
      }
    }

    .area-selection {
      width: 95%;
      height: 0;
      flex: 1;
      .area-selection-select {
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
      }
      .area-selection-content {
        width: 100%;
        height: 0;
        flex: 1;
        .area-selection-content-area {
          .area-selection-content-area-item {
            width: 100%;
            min-height: 250px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 10px;
            .area-selection-content-area-item-list {
              width: 100%;
              flex: 1;
              height: 100%;
              display: flex;
              flex-wrap: wrap;
              .list-item {
                width: 100px;
                height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 20px;
                margin-bottom: 20px;
                font-size: 16px;
                font-weight: 700;
                background: #40a3ff0d url("../../assets/img/area.png") no-repeat
                  center/70%;
                &:hover {
                  cursor: pointer;
                  color: @activeColor;
                  transition: 0.2s;
                }
              }
            }
          }
        }
        .area-selection-content-address {
          width: 100%;
          display: flex;
          .area-selection-content-address-item {
            display: flex;
            background-color: #f5faff;
            margin: 0 20px 20px 0;
            padding: 10px 0;
            .area-selection-content-address-item-i {
              display: flex;
              flex-direction: column;
              width: auto;
              border: 2px solid #dcdfe6;
              box-sizing: border-box;
              border-radius: 3px;
              margin: 0 10px;
              padding: 10px;
              cursor: pointer;
              .image {
                width: 120px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                span {
                  font-size: 14px;
                  font-weight: 700;
                }
              }
              .info {
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: space-between;
                font-size: 14px;
                .info-line {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  line-height: 2;
                  .title {
                    font-weight: 700;
                    margin-right: 10px;
                  }
                  .text {
                    width: 80px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    word-break: break-all;
                    white-space: nowrap;
                    text-align: right;
                  }
                  .price {
                    color: gray;
                    font-size: 12px;
                    text-decoration: line-through;
                  }
                  .disPrice {
                    font-weight: 700;
                    margin-left: 5px;
                  }
                }
              }
              .person {
                color: #40a3ff;
              }
              .company {
                color: @activeColor;
              }
            }
            .active {
              border: 2px solid @baseColor;
            }
          }
        }
      }
      .area-selection-buts {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        .but {
          width: auto;
          height: auto;
          padding: 10px 30px;
          margin: 0 20px;
          box-sizing: border-box;
          border-radius: 8px;
          background-color: @baseColor;
          color: #ffffff;
          font-size: @font-size-tip-mini;
          transition: 0.3s;
          &:hover {
            cursor: pointer;
            background-color: @but-hoverColor;
          }
        }
      }
    }
  }

  .el-tabs {
    width: 98%;
    height: auto;
  }
  .fill-in-information {
    width: 100%;
    height: 100%;

    .information-content {
      width: 98%;
      height: 0;
      flex: 1;
      overflow-y: auto;
      .model {
        width: 100%;
        height: auto;
        .title {
          width: 100%;
          height: 35px;
          position: relative;
          .line {
            width: 100%;
            height: 1px;
            background-color: rgba(160, 160, 160, 0.288);
            position: absolute;
            left: 0;
            top: 10px;
          }
          .text {
            width: auto;
            background-color: #ffffff;
            height: 100%;
            position: absolute;
            left: 30px;
            padding: 0 8px;
            font-size: 14px;
            span {
              color: red;
              margin-left: 8px;
            }
          }
        }
        .content {
          width: 100%;
          height: auto;
          display: flex;
          flex-wrap: wrap;
          .line {
            width: 49%;
            height: auto;
            min-height: 50px;
            display: flex;
            align-items: center;
            font-size: 14px;
            margin: 8px 0;
            .line-title {
              width: 200px;
              height: 100%;
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              span {
                color: red;
                margin-top: 3px;
                margin-right: 3px;
                font-size: 18px;
              }
            }
            .line-value {
              width: 0;
              flex: 1;
              height: 100%;
              display: flex;
              align-items: flex-start;
              margin-left: 10px;
              .div1 {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
              }

              .textarea-but {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              }
            }

            .line-but {
              width: auto;
              min-width: 65px;
              height: 30px;
              padding: 0 15px;
              margin: 0 0 0 15px;
              background-color: @baseColor;
              color: #fff;
              font-size: 13px;
              border-radius: 8px;
              transition: 0.3s;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12),
                0 0 6px rgba(0, 0, 0, 0.04);
              &:hover {
                cursor: pointer;
                background-color: @but-hoverColor;
              }
            }
            .line-but-disable {
              background-color: @but-disableColor;
              &:hover {
                background-color: @but-disableColor;
                cursor: not-allowed;
              }
            }
          }
          .line-ver {
            // 横屏展示
            width: 98%;
          }
        }
      }
    }
  }
  .buts {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 200px;
    box-sizing: border-box;
    .occupi {
      width: 50px;
    }
  }
  .but {
    width: auto;
    height: auto;
    padding: 5px 15px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: @baseColor;
    color: #ffffff;
    transition: 0.3s;
    margin-bottom: 8px;
    &:hover {
      cursor: pointer;
      background-color: @but-hoverColor;
    }
  }
  .uploader-show {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    .text-show {
      margin: 0 8px;
    }
    .el-upload__tip {
      color: #9b9b9b8a;
    }
  }
}

#my-dialog .type {
  width: 90%;
  height: 50px;
  .type1 {
    width: 49%;
    height: 100%;
  }
}
#my-dialog .content {
  width: 100%;
  height: 100%;
}
#my-dialog .buts {
  padding: 0;
  .but {
    margin: 8px 20px;
  }
}
.tree-data {
  width: 90%;
  height: 0;
  flex: 1;
  margin: 10px 0 30px;
  .tree-data-bor {
    flex: 1;
    width: 0;
    height: 100%;
    border-radius: 5px;
    border: 1px solid @borderColor;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .choose-tree-data {
      width: 98%;
      height: 30px;
      margin-left: 1%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .tree-data-buts {
    width: 120px;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
