<template>
  <div id="login">
    <div class="content">
      <div class="login-logo">
        <img src="../assets/img/logo3.png" alt="" />
      </div>
      <div class="login-title">
        用户登录
      </div>
      <div class="login-form">
        <div class="login-form-line">
          <el-input
            v-if="flag == '0'"
            v-model="user"
            placeholder="请输入账号"
            prefix-icon="el-icon-user-solid"
          >
          </el-input>
          <el-input
            v-else
            v-model="phone"
            placeholder="请输入手机号"
            prefix-icon="el-icon-user-solid"
          >
          </el-input>
        </div>
        <div class="login-form-line">
          <el-input
            v-if="flag == '0'"
            v-model="password"
            placeholder="请输入密码"
            show-password
            prefix-icon="el-icon-lock"
            @keyup.enter.native="login"
          >
          </el-input>
          <el-input
            v-else
            v-model="verification"
            placeholder="请输入验证码"
            prefix-icon="el-icon-chat-dot-square"
          >
            <el-button slot="append" type="primary" @click="getVerification">{{
              butTip
            }}</el-button>
          </el-input>
        </div>
        <div class="login-form-line">
          <el-button style="width:100%" type="primary" @click="login"
            >登录</el-button
          >
        </div>
      </div>
      <div class="login-cut">
        <span @click="gotoRegister">没有账号? 点击注册</span>
        <span v-if="flag == '0'" @click="changeLoginWay">切换手机号登录</span>
        <span v-else @click="changeLoginWay">切换账号密码登录</span>
      </div>
    </div>
    <el-dialog
      :title="dialogData.title"
      :visible.sync="dialogData.flag"
      width="30%"
    >
      <span
        v-html="dialogData.content"
        v-if="dialogData.type == '普通公告'"
      ></span>
      <div class="dialogcontent" v-if="dialogData.type == '文档公告'">
        <span v-html="dialogData.content"></span>
        <div class="content-line">
          <div
            class="lien"
            v-for="(item, index) in dialogData.link"
            :key="index"
            @click="showFile(item)"
          >
            {{ item.label }}
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="previousStep"
          v-if="noticeList.length > 0 && index != 0"
        >
          上一步</el-button
        >
        <el-button @click="dialogData.flag = false">关闭</el-button>
        <el-button
          type="primary"
          @click="lastStep"
          v-if="noticeList.length > 0 && index != noticeList.length - 1"
        >
          下一步</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      flag: "0", // 登录方式 0 (账号)身份证+密码  1 手机号+验证码
      user: "", // 账号
      password: "", // 密码
      phone: "", // 手机号
      verification: "", // 验证码
      butTip: "获取验证码",
      timer: null,
      countdown: 60,
      noticeList: [],
      index: 0,
      dialogData: {
        title: "公告",
        content: "",
        flag: false,
        tyep: "普通公告",
        link: [],
      },
    };
  },
  methods: {
    /**
     * 获取当前所有要显示的公告列表
     */
    getAllNoticeList() {
      this.$request("post", "/notice/getAllNoticeListByIndex", {}, (data) => {
        this.noticeList = data.list;
        if (this.noticeList.length > 0) {
          this.index = 0;
          this.dialogData.content = this.noticeList[0].content.text;
          this.dialogData.link = this.noticeList[0].content.documentList;
          this.dialogData.type = this.noticeList[0].type;
          this.dialogData.flag = true;
        }
      });
    },
    /**
     * 切换登录方式
     */
    changeLoginWay() {
      this.user = "";
      this.password = "";
      this.phone = "";
      this.verification = "";
      if (this.flag == "0") {
        this.flag = "1";
      } else {
        this.flag = "0";
      }
    },
    /**
     * 登录
     */
    login() {
      let requestData = {
        flag: this.flag, // 登录方式 0 (账号)身份证+密码  1 手机号+验证码
        user: this.user, // 账号
        password: this.password, // 密码
        phone: this.phone, // 手机号
        verification: this.verification, // 验证码
      };
      if (this.flag == "0") {
        // 账号密码登录
        requestData.phone = "";
        requestData.verification = "";
        if (this.user == "" || this.password == "") {
          this.$message.error("账号或密码不能为空!");
          return;
        }
      } else {
        if (this.phone == "" || this.verification == "") {
          // 手机号验证码登录
          requestData.user = "";
          requestData.password = "";
          this.$message.error("手机号或验证码不能为空!");
          return;
        }
        if (!this.$mobilePhoneNumberCheck(this.phone)) {
          this.$message.error("手机号格式不对!");
          return;
        }
      }
      this.$request(
        "post",
        "/user/login",
        requestData,
        (data) => {
          this.$message.success("登录成功!");
          let userData = {
            userId: data.userId,
            userName: data.userName,
            icon:
              data.icon == "" || !data.icon || data.icon == null
                ? require("../assets/img/user.png")
                : data.icon,
          };
          sessionStorage.setItem("userData", JSON.stringify(userData));
          if (data.flag == "0") {
            //  跳转至首页
            this.$router.push("/main");
          } else {
            // 跳转至个人中心
            sessionStorage.setItem("topMenu", JSON.stringify(data));
            let dd = {
              id: "",
              name: "个人信息",
              auth: "2",
              path: "/main/personal_center/personal_information",
            };
            sessionStorage.setItem(
              "childernMenu",
              JSON.stringify({
                id: "",
                name: "个人中心",
                auth: "1", // 1 显示 2 不显示
                path: "/main/personal_center",
              })
            );
            this.$router.push("/main/personal_center/personal_information");
          }
        },
        (errormessage) => {
          this.$message.error(errormessage);
        }
      );
    },
    /**
     * 获取验证码
     */
    getVerification() {
      if (this.phone == "") {
        this.$message.error("手机号不能为空!");
        return;
      }
      if (!this.$mobilePhoneNumberCheck(this.phone)) {
        this.$message.error("手机号格式不对!");
        return;
      }
      let requestData = {
        phone: this.phone,
      };
      if (this.timer) {
        return;
      }
      this.$request(
        "post",
        "/user/sendVerificationCode",
        requestData,
        (data) => {
          this.$message.success("验证码发送成功!");
          this.isDisable = true;
          if (!this.timer) {
            this.timer = setInterval(() => {
              if (this.countdown > 0 && this.countdown <= 60) {
                this.countdown--;
                if (this.countdown !== 0) {
                  this.butTip = "重新发送(" + this.countdown + ")";
                } else {
                  clearInterval(this.timer);
                  this.butTip = "获取验证码";
                  this.countdown = 60;
                  this.timer = null;
                  this.isDisable = false;
                }
              }
            }, 1000);
          }
        },
        (errormessage) => {
          this.$message.error(errormessage);
        }
      );
    },
    /**
     * 跳转至注册界面
     */
    gotoRegister() {
      this.$router.push("/register");
    },
  },
  mounted() {
    sessionStorage.removeItem("userData");
    this.getAllNoticeList();
  },
};
</script>

<style lang="less" scoped>
#login {
  width: 100%;
  height: 100%;
  background-image: url("../assets/img/login_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  .content {
    position: absolute;
    right: 0;
    top: 0;
    width: 18%;
    min-width: 300px;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    .login-logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 40px 0;
      img {
        width: 55%;
        height: auto;
      }
    }
    .login-title {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    .login-form {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      .login-form-line {
        margin: 10px 0;
        width: 100%;
      }
    }
    .login-cut {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      font-weight: 700;
      color: @baseColor;
      margin-top: 10px;
      span {
        cursor: pointer;
      }
    }
  }
}
</style>
