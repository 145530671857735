<template>
  <div
    id="announcement-list"
    class="div-flex-column-center"
    v-loading="loading"
  >
    <!-- 公告管理 -->
    <el-tabs v-model="activeName">
      <el-tab-pane label="公告列表" name="first">
        <div class="div-flex-center">
          <div class="search-line div-flex-center">
            <el-input
              v-model="search.name"
              placeholder="请输入要查询的内容"
              clearable
              @keyup.enter.native="resetPage"
            ></el-input>
          </div>
          <div class="search-line">
            <el-button
              type="primary"
              size="small"
              icon="el-icon-circle-plus-outline"
              plain
              @click="addNotice"
              >添加</el-button
            >
          </div>
          <div class="search-line div-flex-center"></div>
          <div class="search-line div-flex-center"></div>
        </div>
        <div class="content div-flex-center">
          <el-table
            :data="tableData"
            border
            style="width: 98%"
            height="67vh"
            stripe
          >
            <el-table-column prop="title" label="标题" width="150" center>
            </el-table-column>
            <el-table-column prop="content" label="内容">
              <template slot-scope="scope">
                <div
                  class="table_content"
                  v-html="scope.row.content.text"
                ></div>
              </template>
            </el-table-column>
            <el-table-column prop="time" label="更新时间"></el-table-column>
            <el-table-column prop="userName" label="上传者"></el-table-column>
            <el-table-column prop="userName" label="是否显示在首页">
              <template slot-scope="scope">
                <div class="table_content">
                  <el-switch
                    v-model="scope.row.sequence"
                    @change="changeSequence(scope.row)"
                  >
                  </el-switch>
                </div>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="140">
              <template slot-scope="scope">
                <el-button
                  @click="handleClick(scope.row, item.active)"
                  type="text"
                  size="small"
                  v-for="(item, index) in tableOper"
                  :key="index"
                  v-show="item.show && item.statusShow"
                  >{{ item.name }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="page">
          <el-pagination
            @current-change="getAllNoticeList"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>

    <div id="my-dialog" class="div-flex-center" v-if="dialogData.flag">
      <div
        class="my-dialog-content div-flex-column-center"
        v-loading="dialogData.loading"
      >
        <div class="top div-flex-center">
          <div class="title">{{ dialogData.title }}</div>
          <div class="perch-active"></div>
          <div class="close">
            <i class="el-icon-close" @click="dialogData.flag = false"></i>
          </div>
        </div>

        <div class="content">
          <information_input
            :informationInputData="dialogData.data"
            @radioChange="radioChange"
          >
          </information_input>
        </div>

        <div class="buts div-flex-center">
          <div
            class="but"
            v-for="(item, index) in dialogData.buttonList"
            :key="index"
            @click="dialogHandleCilck(item.active)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as NewsData from "../../assets/js/newsData.js";
export default {
  data() {
    return {
      activeName: "first",
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
      uploadData: {
        systemName: "cluster_register",
        path: "",
        title: "上传文档",
      },
      loading: false,
      userData: {
        userId: "",
        userName: "",
        icon: "",
      },
      pageData: {
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页条数
        total: 0, // 总条目数
      },
      search: {
        name: "",
      },
      tableData: [],
      tableOper: [
        {
          name: "查看",
          key: "查看",
          active: "show",
          show: false, // 由权限控制
          statusShow: true, // 由当前订单的状态控制
        },
        {
          name: "删除",
          key: "删除",
          active: "delete",
          show: false, // 由权限控制
          statusShow: true, // 由当前订单的状态控制
        },
      ],
      but: [
        {
          name: "添加",
          key: "编辑",
          active: "add",
          show: false, // 由权限控制
          statusShow: true, // 由当前订单的状态控制
        },
      ],
      buttShow: false,
      dialogData: {
        loading: true,
        flag: false,
        title: "",
        data: {},
        buttonList: "",
      },
      tempOrder: {}, // 临时表格数据
      pathName: "",
      pathType: 0,
    };
  },
  methods: {
    /**
     *2.3 获取当前角色权限
     */
    getUserAuthority() {
      this.$baseData.getUserAuthority(this.userData.userId, this, (data) => {
        this.tableOper = this.$baseData.judgeButtonPermissions(
          data[0].model,
          this.tableOper,
          "集群资讯",
          "公告管理"
        );
        this.but = this.$baseData.judgeButtonPermissions(
          data[0].model,
          this.but,
          "集群资讯",
          "公告管理"
        );
        this.buttShow = this.but[0].show;
        this.getAllNoticeList();
      });
    },

    /**
     * 获取所有公告列表
     */
    getAllNoticeList() {
      let requestData = {
        flag: "0",
        search: {
          name: this.search.name,
        },
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
      };
      this.$request("post", "/notice/getAllNoticeList", requestData, (data) => {
        this.tableData = data.list;
        this.pageData.total = data.total;
      });
    },
    /**
     * 重置 page 请求列表数据
     */
    resetPage() {
      this.pageData.currentPage = 1;
      this.getAllNoticeList();
    },
    /**
     * 添加公告
     */
    addNotice() {
      this.dialogData.flag = true;
      this.dialogData.loading = true;
      this.tempOrder = { id: "" };
      this.dialogData.buttonList = [
        {
          name: "确定",
          active: "update",
        },
        { name: "取消", active: "cancel" },
      ];
      this.dialogData.title = "添加公告";
      this.dialogData.data = NewsData.getNoticeDialogData();
      setTimeout(() => {
        this.dialogData.loading = false;
      }, 500);
    },
    /**
     * 表格按钮事件
     */
    handleClick(item, active) {
      this.tempOrder = item;
      let requestData = {};
      switch (active) {
        case "show":
          // 查看文档
          this.dialogData.flag = true;
          this.dialogData.loading = true;
          requestData = {
            noticeId: item.id,
          };
          this.$request(
            "post",
            "/notice/getNoticeById",
            requestData,
            (data) => {
              this.dialogData.buttonList = [
                {
                  name: "确定",
                  active: "update",
                },
                { name: "取消", active: "cancel" },
              ];
              this.dialogData.title = "更新公告";
              this.dialogData.data = NewsData.getNoticeDialogData();
              this.dialogData.data[0].data[0].value = data.title;
              this.dialogData.data[0].data[1].value = data.type;
              this.radioChange(data.type);
              this.dialogData.data[0].data[2].value = data.content.text;
              this.dialogData.data[0].data[3].value = data.content.documentList;
              this.dialogData.loading = false;
            }
          );
          break;
        case "delete":
          // 删除文档
          this.$confirm("此操作将永久删除该公告, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              requestData = {
                noticeId: item.id,
              };
              this.$request(
                "post",
                "/notice/delNotice",
                requestData,
                (data) => {
                  this.$message({
                    type: "success",
                    message: "文档删除成功!",
                  });
                  this.getAllNoticeList();
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;

        default:
          break;
      }
    },
    /**
     * dialog按钮操作事件
     */
    dialogHandleCilck(active) {
      let requestData = {};
      switch (active) {
        case "update":
          // 添加或修改公告信息
          requestData = {
            noticeId: this.tempOrder.id, // 公告id,空为添加  有值为修改
            title: this.dialogData.data[0].data[0].value, // 标题
            content: {
              text: this.dialogData.data[0].data[2].value,
              documentList: this.dialogData.data[0].data[3].value,
            }, // 内容
            userId: this.userData.userId, // 上传人id
            status: "0", // 公告状态
            type: this.dialogData.data[0].data[1].value, // 公告类别
            sequence: false, // 顺序
          };
          this.$request(
            "post",
            "/notice/saveOrUpdateNotice",
            requestData,
            (data) => {
              this.$message({
                message: "公告添加成功!",
                type: "success",
              });
              this.dialogData.flag = false;
              this.getAllNoticeList();
            }
          );
          break;
        case "cancel":
          this.dialogData.flag = false;
          break;

        default:
          break;
      }
    },
    /**
     * dialog 单选框选择事件
     */
    radioChange(value) {
      let requestData = {};
      if (value == "普通公告") {
        this.dialogData.data[0].data[3].show = false;
        this.dialogData.data[0].data[3].value = [];
      } else if (value == "文档公告") {
        requestData = {
          type: "",
          search: {
            name: "",
          },
          currentPage: 1, // 当前页数
          pageSize: 100, // 每页条数
        };
        this.$request(
          "post",
          "/document/getDocumentList",
          requestData,
          (data) => {
            let options = [];
            data.tableData.forEach((element) => {
              let dd = {
                label: element.title,
                value: element.id,
              };
              options.push(dd);
            });
            this.dialogData.data[0].data[3].options = options;
            this.dialogData.data[0].data[3].show = true;
          }
        );
      }
    },
    /**
     * 更改状态
     */
    changeSequence(item) {
      let requestData = {
        noticeId: item.id,
        sequence: item.sequence,
      };
      this.$request(
        "post",
        "/notice/updateSequenceNotice",
        requestData,
        (data) => {
          this.$message({
            message: "公告修改成功!",
            type: "success",
          });
          this.getAllNoticeList();
        }
      );
    },
  },
  mounted() {
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    this.pathName = this.$route.query.name;
    this.getUserAuthority();
  },
};
</script>

<style lang="less" scoped>
#announcement-list {
  width: 100%;
  height: 100%;

  .el-tabs {
    width: 98%;
    height: 98%;
    .search-line {
      width: 25%;
      margin-bottom: 10px;
    }
    .page {
      width: 100%;
      height: 50px;
      margin-bottom: 10px;
    }
  }
  .content {
    width: 100%;
    height: auto;
    .tip {
      color: @baseColor;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: @activeColor;
      }
    }
  }

  .buts {
    width: 100%;
    height: 50px;
    .but {
      width: auto;
      height: auto;
      padding: 5px 15px;
      margin: 0 10px;
      box-sizing: border-box;
      border-radius: 8px;
      background-color: @baseColor;
      color: #ffffff;
      font-size: @font-size-tip-mini;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        background-color: @but-hoverColor;
      }
    }
  }
}
</style>