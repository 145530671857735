export function getOrderList() {
    return [{
        title: "业务说明",
        tip: "",
        data: [{
            title: "代理类型:",
            tip: false, // 是否不得为空
            type: "radio", // 仅文字展示
            value: "5",
            options: [{
                label: "合作商",
                value: "5"
            }],
            landscape: false, // 是否横屏显示
            placeholder: "", // 提示文字
            key: "agentType",
        }]
    }, {
        title: "企业信息",
        tip: "",
        data: [{
            title: "企业名称:",
            tip: true, // 是否不得为空
            type: "input", // 仅文字展示
            value: "",
            options: [],
            landscape: false, // 是否横屏显示
            placeholder: "请输入企业名称", // 提示文字
            key: "companyName",
        }, {
            title: "统一社会信用代码:",
            tip: true, // 是否不得为空
            type: "input", // 仅文字展示
            value: "",
            options: [],
            landscape: false, // 是否横屏显示
            placeholder: "请输入统一社会信用代码", // 提示文字
            key: "compantCode",
        }, {
            title: "实际经营地址:",
            tip: true, // 是否不得为空
            type: "input", // 仅文字展示
            value: "",
            options: [],
            landscape: true, // 是否横屏显示
            placeholder: "请输入实际经营地址", // 提示文字
            key: "companyAddress",
        }, {
            title: "营业执照:",
            tip: true, // 是否不得为空
            type: "file_reuse", // 仅文字展示
            value: "",
            options: [],
            landscape: true, // 是否横屏显示
            placeholder: "选择附件", // 提示文字
            key: "businessLicense",
            data: {
                systemName: "cluster_register",
                path: "",
                title: "营业执照"
            },
            isDisable: false,
            text: "", // 文件名称  显示在界面上的
        }, {
            title: "代理凭证:",
            tip: true, // 是否不得为空
            type: "file_reuse", // 仅文字展示
            value: "",
            options: [],
            landscape: true, // 是否横屏显示
            placeholder: "选择附件", // 提示文字
            key: "file",
            data: {
                systemName: "cluster_register",
                path: "",
                title: "代理凭证"
            },
            isDisable: false,
            text: "", // 文件名称  显示在界面上的
        }, ]
    }, {
        title: "法定代表人信息",
        tip: "",
        data: [{
                title: "法定代表人姓名:",
                tip: true, // 是否不得为空
                type: "input", // 仅文字展示
                value: "",
                options: [],
                landscape: false, // 是否横屏显示
                placeholder: "请输入法定代表人姓名", // 提示文字
                key: "name",
            }, {
                title: "身份证号码:",
                tip: true, // 是否不得为空
                type: "input", // 仅文字展示
                value: "",
                options: [],
                landscape: false, // 是否横屏显示
                placeholder: "请输入身份证号码", // 提示文字
                key: "idCard",
            }, {
                title: "手机号:",
                tip: true, // 是否不得为空
                type: "input-phone", // 手机号+按钮
                value: "",
                options: [],
                landscape: false, // 是否横屏显示
                placeholder: "请输入手机号", // 提示文字
                key: "phone",
                text: "发送验证码",
                isDisable: false, // 发送验证码的按钮禁用状态
                timer: null, // 发送验证码计时器
                countdown: 60, // 倒计时时间
            },
            {
                title: "验证码:",
                tip: true, // 是否不得为空
                type: "input", // 禁用输入框
                value: "",
                options: [],
                landscape: false, // 是否横屏显示
                placeholder: "请输入验证码", // 提示文字
                key: "verification",
            }, {
                title: "",
                tip: false, // 是否不得为空
                type: "tip", // 提示文字
                value: "注:请填写法定代表人实名认证手机号",
                options: [],
                landscape: true, // 是否横屏显示
                placeholder: "", // 提示文字
                key: "",
            },
            {
                title: "电子邮箱:",
                tip: true, // 是否不得为空
                type: "input", // 禁用输入框
                value: "",
                options: [],
                landscape: false, // 是否横屏显示
                placeholder: "请输入验证码", // 提示文字
                key: "email",
            }, {
                title: "身份证附件:",
                tip: true, // 是否不得为空
                type: "file_idCard", // 仅文字展示
                value: ["", ""],
                options: [],
                landscape: true, // 是否横屏显示
                placeholder: "选择附件", // 提示文字
                key: "idCardFile",
                data: {
                    systemName: "cluster_register",
                    path: "",
                    title: "身份证附件"
                },
                isDisable: false,
                text: ["", ""], // 文件名称  显示在界面上的
            },
        ]
    }]
}

export function getDocumentsManagement() {
    return [{
        title: "凭证信息",
        tip: "",
        data: [{
            title: "企业名称:",
            tip: false, // 是否不得为空
            type: "text", // 仅文字展示
            value: "",
            options: [],
            landscape: true, // 是否横屏显示
            placeholder: "", // 提示文字
            key: "companyName",
        }, {
            title: "代理类型:",
            tip: false, // 是否不得为空
            type: "input", // 仅文字展示
            value: "",
            options: [],
            landscape: false, // 是否横屏显示
            placeholder: "", // 提示文字
            key: "agentTypeName",
        }, {
            title: "代理状态:",
            tip: false, // 是否不得为空
            type: "input", // 仅文字展示
            value: "",
            options: [],
            landscape: false, // 是否横屏显示
            placeholder: "", // 提示文字
            key: "status",
        }, {
            title: "代理凭证:",
            tip: false, // 是否不得为空
            type: "file_reuse", // 仅文字展示
            text: "",
            value: "",
            options: [],
            landscape: true, // 是否横屏显示
            placeholder: "", // 提示文字
            key: "file",
        }, {
            title: "账户余额:",
            tip: false, // 是否不得为空
            type: "input", // 仅文字展示
            value: "",
            options: [],
            landscape: true, // 是否横屏显示
            placeholder: "", // 提示文字
            key: "money",
        }, {
            title: "代理额度:",
            tip: false, // 是否不得为空
            type: "input", // 仅文字展示
            value: "",
            options: [],
            landscape: true, // 是否横屏显示
            placeholder: "", // 提示文字
            key: "remainAmount",
        }]
    }]
}

export function getAgentMenuData() {
    return [{
            key: "companyName",
            title: "企业名称",
            width: "200",
            fixed: false,
        },
        {
            key: "name",
            title: "法定代表人",
            width: "130",
            fixed: false,
        },
        {
            key: "phone",
            title: "手机号",
            width: "150",
            fixed: false,
        },
        {
            key: "companyAddress",
            title: "实际经营地址",
            width: "",
            fixed: false,
        },
        {
            key: "money",
            title: "账号余额",
            width: "150",
            fixed: false,
        },
        {
            key: "status",
            title: "备案状态",
            width: "150",
            fixed: false,
        },
    ]
}

export function getEmpolyeeMenuData() {
    return [{
        key: "userName",
        title: "姓名",
        width: "",
        fixed: false
    }, {
        key: "phone",
        title: "联系方式",
        width: "",
        fixed: false
    }, {
        key: "status",
        title: "状态",
        width: "",
        fixed: false
    }]
}

export function getAssignDialogData() {
    return {
        isEdit: false, // 是否是编辑状态
        idCard: '', // 身份证
        userName: '', // 用户名
        password: '', // 密码
        areaId: '', // 区域id
        flag: '0', // 0 安徽区域内(不包括合肥) 1 合肥
        areaList: [],
        addressList: [
            {
                prefix: "", // 地址前缀
                suffix: "", // 地址后缀
                tag: '', // 地址标签
                priceIndividual: '500', // 价格（个体户），保留两位小数
                discountIndividual: '1', // 折扣（个体户），区间：(0 - 1]，保留两位小数
                priceEnterprise: '500', // 价格（企业），保留两位小数
                discountEnterprise: '1', // 折扣（企业），区间：(0 - 1]，保留两位小数
            },
        ]
    }
}

export function getEmployeeDialogData() {
    return [{
        title: "创建三级代理",
        tip: "",
        data: [{
            title: "姓名:",
            tip: true, // 是否不得为空
            type: "input", // 仅文字展示
            value: "",
            options: [],
            landscape: true, // 是否横屏显示
            placeholder: "请输入代理姓名", // 提示文字
            key: "userName",
        }, {
            title: "联系方式:",
            tip: true, // 是否不得为空
            type: "input", // 仅文字展示
            value: "",
            options: [],
            landscape: true, // 是否横屏显示
            placeholder: "请输入手机号(用于三级代理初始账号)", // 提示文字
            key: "phone",
        }, {
            title: "角色:",
            tip: true, // 是否不得为空
            type: "select_disable_value", // 仅文字展示
            value: "",
            options: [{
                label: "",
                value: ""
            }],
            landscape: true, // 是否横屏显示
            placeholder: "请选择角色", // 提示文字
            key: "phone",
        }, ]
    }]
}

/**
 * 充值dialog界面数据
 */
export function payDialogData() {
    return [{
        title: "合作商余额调整",
        tip: "",
        data: [{
                title: "当前余额",
                tip: false,
                type: "text",
                value: "",
                landscape: true, // 是否横屏显示
            }, {
                title: "充值金额(元)",
                tip: true,
                type: "input",
                value: "",
                landscape: true, // 是否横屏显示
                placeholder: "请输入调整金额", // 提示文字
            },
            {
                title: "充值凭证:",
                tip: true, // 是否不得为空
                type: "file_reuse", // 仅文字展示
                value: "",
                options: [],
                landscape: true, // 是否横屏显示
                placeholder: "选择附件", // 提示文字
                key: "businessLicense",
                data: {
                    systemName: "cluster_register",
                    path: "",
                    title: "充值凭证"
                },
                isDisable: false,
                text: "", // 文件名称  显示在界面上的
            }, 
            {
                title: "",
                tip: false, // 是否不得为空
                type: "tip", // 提示文字
                value: "注:本次填写的金额会在原有金额基础上增加",
                options: [],
                landscape: true, // 是否横屏显示
                placeholder: "", // 提示文字
                key: "",
            },
        ]
    }]
}

/**
 * 折扣dialog界面数据
 */
export function discountDialogData() {
    return [{
        title: "合作商折扣调整",
        tip: "",
        data: [{
                title: "折扣失效时间",
                tip: true,
                type: "time",
                value: "",
                landscape: true, // 是否横屏显示
                placeholder: "", // 提示文字
            },{
                title: "修改折扣",
                tip: true,
                type: "input",
                value: "",
                landscape: true, // 是否横屏显示
                placeholder: "请输入修改折扣", // 提示文字
            },{
                title: "",
                tip: false, // 是否不得为空
                type: "tip", // 提示文字
                value: "注:此折扣为充值比例, 若设置为10, 充值1元时, 实际充值为10 ( 实际充值金额 = 充值金额 * 折扣 ) ",
                options: [],
                landscape: true, // 是否横屏显示
                placeholder: "", // 提示文字
                key: "",
            },
            
        ]
    }]
}

/**
 * 代理额度dialog界面数据
 */
export function linesDialogData() {
    return [{
        title: "代理额度调整",
        tip: "",
        data: [{
                title: "额度调整(个)",
                tip: true,
                type: "input",
                value: "",
                landscape: true, // 是否横屏显示
                placeholder: "请输入充值额度", // 提示文字
            },
            {
                title: "",
                tip: false, // 是否不得为空
                type: "tip", // 提示文字
                value: "注:本次填写的数值会在原有额度基础上加减",
                options: [],
                landscape: true, // 是否横屏显示
                placeholder: "", // 提示文字
                key: "",
            },
        ]
    }]
}

/**
 * 获取扣费规则数据结构
 */
export function getAreaDeductionRules() {
    return [{
        title: "扣费规则",
        tip: "",
        data: [{
                title: "合同单价(/年):",
                tip: true, // 是否不得为空
                type: "input",
                value: "500",
                options: [],
                landscape: false, // 是否横屏显示
                placeholder: "", // 提示文字
                key: "registeredCapital",
            }
            // , {
            // title: "手续费(%):",
            // tip: true, // 是否不得为空
            // type: "pedometer", // 计步器
            // value: "0.0",
            // options: [],
            // landscape: false, // 是否横屏显示
            // placeholder: "", // 提示文字
            // max: 100,
            // step: 0.1,
            // key: "registeredCapital",
            // }
        ]
    }]
}

