<template>
  <div id="tomovein-order" class="div-flex-column-center" v-loading="loading">
    <!-- 迁入 -->
    <div class="ready div-flex-column-center" v-if="!flag">
      <!-- 业务信息填写前的地址选择 -->

      <el-tabs v-model="activeName1">
        <el-tab-pane label="新设立" name="新设立"> </el-tab-pane>
      </el-tabs>

      <div class="top-tip div-flex-column-center">
        <div class="title">请选择下面的一个地址进行注册</div>
        <div class="content" v-html="tip"></div>
      </div>

      <div class="area-selection div-flex-column-center">
        <div class="area-selection-select">
          <el-select
            v-model="selectValue"
            placeholder="请选择"
            @change="getAreaSelectOption"
          >
            <el-option
              v-for="item in selectList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="area-selection-content">
          <div class="area-selection-content-title">
            <div class="tip"><span>*</span>注册地址:</div>
          </div>
          <div class="area-selection-content-list">
            <el-radio-group v-model="areaId">
              <el-radio
                :label="item.value"
                v-for="(item, index) in areaList"
                :key="index"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </div>
        </div>
        <div class="area-selection-buts">
          <div class="but div-flex-center" @click="startApplication">
            启动申请
          </div>
        </div>
      </div>
    </div>

    <div class="fill-in-information div-flex-column-center" v-else>
      <!-- 业务信息填写 -->
      <el-tabs v-model="activeName">
        <el-tab-pane label="基本信息" name="基本信息" :disabled="showText">
        </el-tab-pane>
        <el-tab-pane label="信息确认" name="信息确认" :disabled="!showText">
        </el-tab-pane>
      </el-tabs>

      <div class="information-content div-flex-column-center" v-if="!showText">
        <information_input
          :informationInputData="orderList"
          @chooseScopeBusiness="chooseScopeBusiness"
          @getVerification="getVerification"
          @handleAvatarSuccess="handleAvatarSuccess"
          @beforeAvatarUpload="beforeAvatarUpload"
          @radioChange="radioChange"
        ></information_input>
        <div class="buts">
          <div class="but" @click="save(0)">暂存</div>
          <div class="occupi"></div>
          <div class="but" @click="changeFormat">下一步</div>
        </div>
      </div>
      <div class="information-content div-flex-column-center" v-if="showText">
        <information_text :informationTextData="orderList"></information_text>
        <div class="buts">
          <div class="but" @click="previousStep">上一步</div>
          <div class="occupi"></div>
          <div class="but" @click="submit">提交</div>
        </div>
      </div>
    </div>

    <div id="my-dialog" class="div-flex-center" v-if="dialogData.flag">
      <div
        class="my-dialog-content div-flex-column-center"
        v-loading="dialogData.loading"
        v-if="dialogData.type == 'business'"
      >
        <div class="top div-flex-center">
          <div class="title">经营范围</div>
          <div class="perch-active"></div>
          <div class="close">
            <i class="el-icon-close" @click="dialogData.flag = false"></i>
          </div>
        </div>

        <div class="type div-flex-center">
          <div class="type1">
            <el-select
              v-model="dialogData.selectType"
              placeholder="请选择经营范围类别"
              @change="queryCommodityById"
            >
              <el-option
                v-for="item in dialogData.seteletOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="perch-active"></div>
          <div class="type1">
            <el-input
              v-model="filterText"
              placeholder="输入关键字进行过滤"
              clearable
              v-if="dialogData.selectType != ''"
            ></el-input>
          </div>
        </div>

        <div class="tree-data div-flex-center">
          <div class="tree-data-bor">
            <el-tree
              :data="dialogData.treeData"
              show-checkbox
              node-key="id"
              :props="defaultProps"
              :filter-node-method="filterNode"
              check-strictly
              ref="tree"
            >
            </el-tree>
          </div>
          <div class="tree-data-buts">
            <el-button
              type="primary"
              plain
              icon="el-icon-plus"
              @click="butClick(0)"
              >添加</el-button
            >
            <el-button
              type="danger"
              plain
              icon="el-icon-minus"
              @click="butClick(1)"
              >移除</el-button
            >
            <el-button
              type="success"
              plain
              icon="el-icon-check"
              @click="butClick(2)"
              >确认</el-button
            >
          </div>
          <div class="tree-data-bor">
            <div
              class="choose-tree-data"
              v-for="(item, index) in dialogData.selectTreeData"
              :key="index"
            >
              <el-checkbox v-model="item.checked">{{ item.name }}</el-checkbox>
            </div>
          </div>
        </div>
      </div>

      <div
        class="my-dialog-content div-flex-column-center"
        v-if="dialogData.type == 'show_information'"
      >
        <div class="top div-flex-center">
          <div class="title">提交订单</div>
          <div class="perch-active"></div>
          <div class="close">
            <i class="el-icon-close" @click="dialogData.flag = false"></i>
          </div>
        </div>

        <div class="content" v-if="dialogData.type == 'show_information'">
          <information_input :informationInputData="dialogData.data">
          </information_input>
        </div>
        <div
          class="buts div-flex-center"
          v-if="dialogData.type == 'show_information'"
        >
          <div
            class="but"
            v-for="(item, index) in dialogData.buttonList"
            :key="index"
            @click="dialogHandleCilck(item.active)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as instructionsData from "../../assets/js/instructions.js";
export default {
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },

  data() {
    return {
      loading: true,
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
      tip: instructionsData.getTip(2),
      flag: false, // 当前有没有选择地址 true 选择了地址,并启动了申请
      userData: {
        userId: "",
        userName: "",
        icon: "",
      },
      selectValue: "1",
      selectList: [
        { label: "安徽省", value: "0" },
        { label: "合肥市", value: "1" },
      ],
      areaId: "", // 所选区域ID
      areaList: instructionsData.getAreaList(), // 可供选择的所有区域

      activeName: "基本信息",
      activeName1: "迁址",
      showText: false, // 是否以文本形式展示信息

      orderList: instructionsData.getOrderData(),
      id: "", // 暂存提交前保存后返回的ID

      dialogData: {
        loading: true,
        flag: false,
        title: "经营范围",
        type: "business",
        data: [],
        selectType: "",
        seteletOptions: [],
        search: "",
        treeData: [], // 筛选之后的tree数据
        selectTreeData: [], // 选择要添加的数据
        chooseSelectData: [], // 选择要移除的数据
        buttonList: [
          {
            name: "确定",
            active: "submit",
          },
          {
            name: "取消",
            active: "cancel",
          },
        ],
      },
      filterText: "", // 树筛选关键字
      defaultProps: {
        children: "children",
        label: "name",
      },
    };
  },
  methods: {
    /**
     * 获取所有区域列表
     */
    getAreaSelectOption() {
      this.$request(
        "get",
        "/area/getAreaSelectOption?flag=1", // 0 - 查询未代理；1 - 查询已代理；不传查询全部
        {},
        (data) => {
          if (this.selectValue == 0) {
            // 安徽省
            this.areaList = data[0].options;
          } else if (this.selectValue == 1) {
            // 合肥市
            this.areaList = data[1].options;
          }
          setTimeout(() => {
            this.loading = false;
          }, 500);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取订单详情
     */
    getOrderDetailsById() {
      this.flag = true;
      this.loading = true;
      let requestData = {
        id: this.id,
      };
      this.$request(
        "post",
        "/orders/getOrderDetailsById",
        requestData,
        (data) => {
          this.areaId = data.areaId;
          this.orderList = instructionsData.matchOrderData(
            JSON.parse(JSON.stringify(this.orderList)),
            data
          );
          setTimeout(() => {
            this.loading = false;
          }, 500);
        }
      );
    },

    /**
     * 启动申请
     */
    startApplication() {
      if (this.areaId == "") {
        this.$message({
          message: "请先选择要注册的地址！",
          type: "warning",
        });
        return;
      }
      this.flag = true;
    },

    /**
     * 发送验证码
     */
    getVerification(item) {
      if (item.value == "") {
        this.$message({
          message: "手机号不能为空!",
          type: "error",
        });
        return;
      }
      if (!this.$mobilePhoneNumberCheck(item.value)) {
        this.$message({
          message: "手机号格式不对!",
          type: "error",
        });
        return;
      }
      let requestData = {
        phone: this.phone,
      };
      this.$request(
        "post",
        "/user/sendVerificationCode",
        requestData,
        (data) => {
          this.$message({
            message: "验证码发送成功!",
            type: "success",
          });
          item.isDisable = true;
          if (!this.timer) {
            item.timer = setInterval(() => {
              if (item.countdown > 0 && item.countdown <= 60) {
                item.countdown--;
                if (item.countdown !== 0) {
                  item.text = "重新发送(" + item.countdown + ")";
                } else {
                  clearInterval(item.timer);
                  item.text = "获取验证码";
                  item.countdown = 60;
                  item.timer = null;
                  item.isDisable = false;
                }
              }
            }, 1000);
          }
        }
      );
    },
    /**
     * 文件上传前格式判断
     */
    beforeAvatarUpload(file) {
      console.log("上传文件格式:", file.type);
      const isJPG =
        file.type === "application/pdf" ||
        file.type === "image/jpeg" ||
        file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("上传文件只能是 PDF 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    /**
     * 文件上传成功
     */
    handleAvatarSuccess(data) {
      switch (data.response.result.title) {
        case "承租方身份证明附件":
          this.orderList[1].data[9].value = data.response.result.path;
          this.orderList[1].data[9].isDisable = true;
          this.orderList[1].data[9].text = data.file.name;
          break;
        case "身份证明附件":
          this.orderList[2].data[5].value = data.response.result.path;
          this.orderList[2].data[5].isDisable = true;
          this.orderList[2].data[5].text = data.file.name;

          break;
        case "经营场地证明":
          this.orderList[4].data[2].value = data.response.result.path;
          this.orderList[4].data[2].isDisable = true;
          this.orderList[4].data[2].text = data.file.name;

          break;

        default:
          break;
      }
      console.log(this.orderList);
    },
    /**
     * 选择经营范围
     * 获取当前所选的经营范围数据
     *  9.2 获取所有货物的最外层的货物类型
     */
    chooseScopeBusiness() {
      this.dialogData.loading = true;
      let this_ = this;
      this.$request(
        "post",
        "/commodityCode/getQueryCommodityType",
        {},
        (data) => {
          this.dialogData.selectTreeData = this.orderList[0].data[4].truevalue;
          this.dialogData.seteletOptions = data;
          this.dialogData.type = "business";
          this.dialogData.flag = true;
          setTimeout(() => {
            this_.dialogData.loading = false;
          }, 500);
        }
      );
    },
    /**
     *9.1 根据id获取子编码树
     */
    queryCommodityById() {
      this.dialogData.loading = true;
      let this_ = this;
      let requestData = {
        id: this.dialogData.selectType,
        search: this.dialogData.search,
      };
      this.$request(
        "post",
        "/commodityCode/queryCommodityById",
        requestData,
        (data) => {
          this.dialogData.treeData = data;
          setTimeout(() => {
            this_.dialogData.loading = false;
          }, 500);
        }
      );
    },
    /**
     * 关键字筛选
     */
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    /**
     * 选择经营范围的中间三个按钮点击事件
     */
    butClick(flag) {
      switch (flag) {
        case 0:
          // 添加
          let chooseData = this.$refs.tree.getCheckedNodes(false, false);
          this.dialogData.selectTreeData = [];
          chooseData.forEach((element) => {
            this.$set(element, "checked", false);
            this.dialogData.selectTreeData.push(element);
          });
          break;
        case 1:
          // 移除
          this.dialogData.chooseSelectData = [];
          this.dialogData.selectTreeData.forEach((element) => {
            if (!element.checked) {
              this.dialogData.chooseSelectData.push(element);
            }
          });
          this.dialogData.selectTreeData = JSON.parse(
            JSON.stringify(this.dialogData.chooseSelectData)
          );
          break;
        case 2:
          // 提交
          let value = "";
          this.dialogData.selectTreeData.forEach((element, index) => {
            value = index == 0 ? element.name : value + "," + element.name;
          });
          this.orderList[0].data[4].value = value;
          this.orderList[0].data[4].truevalue = this.dialogData.selectTreeData;
          this.dialogData.flag = false;
          break;

        default:
          break;
      }
    },
    /**
     * 暂存
     */
    save(flag) {
      let this_ = this;
      let requestData = instructionsData.formatSubmitData(this.orderList, this);
      this.$set(requestData, "orderId", this.id);
      this.$set(requestData, "userId", this.userData.userId);
      this.$set(requestData, "areaId", this.areaId);
      this.$set(requestData, "type", "2");
      this.$set(requestData, "oldId", "");

      this.$request(
        "post",
        "/orders/createNewOrderSave",
        requestData,
        (data) => {
          switch (flag) {
            case 0:
              // 保存之后直接跳转到订单界面
              this.$baseData.saveOperationRecord(
                data.id,
                "修改订单!",
                this.userData.userId,
                "order",
                this,
                (saveData) => {
                  this.$message({
                    message: "保存成功!",
                    type: "success",
                  });
                  let dd = {
                    id: "",
                    name: "订单管理",
                    auth: "2",
                    path: "/main/order/order_management",
                  };
                  sessionStorage.setItem("childernMenu", JSON.stringify(dd));
                  this.$router.push({
                    path: "/main/order/order_management",
                    query: {
                      name: "订单管理",
                    },
                  });
                }
              );
              break;
            case 1:
              // 只保存
              this.id = data.id;
              // return new Promise((resolve, reject) => {
              //   resolve(data.id);
              // });
              break;

            default:
              break;
          }
        }
      );
    },
    /**
     * 下一步
     * 下一步要非空校验
     * 非空校验通过后,
     */
    changeFormat() {
      let dt = instructionsData.checkToSubmitData(this.orderList, this);
      if (!dt.flag) {
        this.$message({
          message: dt.mes,
          type: "error",
        });
        return;
      }

      let requestData = {
        phone: this.orderList[1].data[5].value,
        verificationCode: this.orderList[1].data[6].value,
        name: this.orderList[1].data[2].value,
        idCard: this.orderList[1].data[4].value,
      };
      this.$request(
        "post",
        "/orders/matchPhoneNumberVerificationCode",
        requestData,
        (data) => {
          this.activeName = "信息确认";
          this.showText = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 返回上一步
     */
    previousStep() {
      this.activeName = "基本信息";
      this.showText = false;
    },
    /**
     * 提交
     * 提交前,手动调用保存
     */
    submit() {
      this.dialogData.title = "";
      this.dialogData.data = [
        {
          title: "企业基本信息",
          tip: "",
          data: [
            // {
            //   title: "合同期限(年):",
            //   tip: true, // 是否不得为空
            //   type: "select",
            //   value: "1",
            //   options: [
            //     {
            //       label: "1",
            //       value: "1",
            //     },
            //     {
            //       label: "2",
            //       value: "2",
            //     },
            //     {
            //       label: "3",
            //       value: "3",
            //     },
            //     {
            //       label: "4",
            //       value: "4",
            //     },
            //     {
            //       label: "5",
            //       value: "5",
            //     },
            //     {
            //       label: "6",
            //       value: "6",
            //     },
            //     {
            //       label: "7",
            //       value: "7",
            //     },
            //     {
            //       label: "8",
            //       value: "8",
            //     },
            //     {
            //       label: "9",
            //       value: "9",
            //     },
            //     {
            //       label: "10",
            //       value: "10",
            //     },
            //   ],
            //   landscape: true, // 是否横屏显示
            //   placeholder: "", // 提示文字
            //   key: "registeredCapital",
            // },
            {
              title: "合同开始日期:",
              tip: true, // 是否不得为空
              type: "time",
              value: this.$formatTime(new Date(), "YYYY-MM-DD"),
              options: [],
              landscape: true, // 是否横屏显示
              placeholder: "", // 提示文字
              key: "registeredCapital",
            },
          ],
        },
      ];
      this.dialogData.type = "show_information";
      this.dialogData.flag = true;

      let this_ = this;
      this.loading = true;
    },
    dialogHandleCilck(active) {
      switch (active) {
        case "cancel":
          // 取消
          this.dialogData.flag = false;
          break;
        case "submit":
          // 确定提交订单
          let this_ = this;
          this.loading = true;
          this.save(1);
          setTimeout(() => {
            let requestData = {
              orderId: this_.id,
              startTime: this_.dialogData.data[0].data[0].value, // 订单开始时间
              term: "1", // 订单期限
            };
            this_.$request(
              "post",
              "/orders/createNewOrderSubmit",
              requestData,
              (data) => {
                this_.$baseData.saveOperationRecord(
                  this_.id,
                  "提交订单",
                  this_.userData.userId,
                  "order",
                  this_,
                  (saveData) => {
                    this_.$message({
                      message: "提交成功!",
                      type: "success",
                    });
                    this_.loading = false;

                    let dd = {
                      id: "",
                      name: "订单管理",
                      auth: "2",
                      path: "/main/order/order_management",
                    };
                    sessionStorage.setItem("childernMenu", JSON.stringify(dd));
                    this_.$router.push({
                      path: "/main/order/order_management",
                      query: {
                        name: "订单管理",
                      },
                    });
                  }
                );
              }
            );
          }, 3000);
          break;

        default:
          break;
      }
    },
    /**
     * 自然人与法人单选框切换事件
     */
    radioChange(value) {
      switch (value) {
        case "自然人":
          this.orderList[1].data[1].show = false;
          this.orderList[1].data[1].value = "";
          this.orderList[1].data[2].value = "";
          this.orderList[1].data[2].show = false;
          break;
        case "法人":
          this.orderList[1].data[1].show = true;
          this.orderList[1].data[2].show = true;

          break;

        default:
          break;
      }
      console.log(this.orderList);
    },
  },
  mounted() {
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    let dd = {
      id: "",
      name: "迁入",
      auth: "2",
      path: "/main/order/instructions",
    };
    this.id = this.$route.query.id;
    sessionStorage.setItem("childernMenu", JSON.stringify(dd));
    if (this.id == "") {
      // 从路由进入
      this.getAreaSelectOption();
    } else {
      // 从订单列表中带ID进入
      this.getOrderDetailsById();
    }
  },
};
</script>

<style lang="less" scoped>
#tomovein-order {
  width: 100%;
  height: 100%;
  .ready {
    width: 100%;
    height: 100%;
    .top-tip {
      width: 95%;
      height: 15%;
      border-bottom: 1px solid @borderColor;
      box-sizing: border-box;
      font-size: 15px;
      .title {
        width: 100%;
        height: 35%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      .content {
        width: 90%;
        height: 0;
        flex: 1;
        color: red;
        text-align: left;
      }
    }

    .area-selection {
      width: 95%;
      height: 0;
      flex: 1;
      .area-selection-select {
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
      }
      .area-selection-content {
        width: 100%;
        height: 0;
        flex: 1;
        display: flex;
        .area-selection-content-title {
          width: auto;
          height: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          padding: 0 10px;
          font-size: 15px;
          .tip {
            width: 100%;
            height: 30px;
            display: flex;
            span {
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              color: red;
              margin-right: 3px;
            }
          }
        }
        .area-selection-content-list {
          width: 0;
          flex: 1;
          height: 100%;
          .el-radio-group {
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
          }
          .el-radio {
            width: 30%;
            height: 40px;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
          }
        }
      }
      .area-selection-buts {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        .but {
          width: auto;
          height: auto;
          padding: 10px 30px;
          box-sizing: border-box;
          border-radius: 8px;
          background-color: @baseColor;
          color: #ffffff;
          font-size: @font-size-tip-mini;
          transition: 0.3s;
          &:hover {
            cursor: pointer;
            background-color: @but-hoverColor;
          }
        }
      }
    }
  }

  .el-tabs {
    width: 98%;
    height: auto;
  }
  .fill-in-information {
    width: 100%;
    height: 100%;

    .information-content {
      width: 98%;
      height: 0;
      flex: 1;
      overflow-y: auto;
      .model {
        width: 100%;
        height: auto;
        .title {
          width: 100%;
          height: 35px;
          position: relative;
          .line {
            width: 100%;
            height: 1px;
            background-color: rgba(160, 160, 160, 0.288);
            position: absolute;
            left: 0;
            top: 10px;
          }
          .text {
            width: auto;
            background-color: #ffffff;
            height: 100%;
            position: absolute;
            left: 30px;
            padding: 0 8px;
            font-size: 14px;
            span {
              color: red;
              margin-left: 8px;
            }
          }
        }
        .content {
          width: 100%;
          height: auto;
          display: flex;
          flex-wrap: wrap;
          .line {
            width: 49%;
            height: auto;
            min-height: 50px;
            display: flex;
            align-items: center;
            font-size: 14px;
            margin: 8px 0;
            .line-title {
              width: 200px;
              height: 100%;
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              span {
                color: red;
                margin-top: 3px;
                margin-right: 3px;
                font-size: 18px;
              }
            }
            .line-value {
              width: 0;
              flex: 1;
              height: 100%;
              display: flex;
              align-items: flex-start;
              margin-left: 10px;
              .div1 {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
              }

              .textarea-but {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              }
            }

            .line-but {
              width: auto;
              min-width: 65px;
              height: 30px;
              padding: 0 15px;
              margin: 0 0 0 15px;
              background-color: @baseColor;
              color: #fff;
              font-size: 13px;
              border-radius: 8px;
              transition: 0.3s;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12),
                0 0 6px rgba(0, 0, 0, 0.04);
              &:hover {
                cursor: pointer;
                background-color: @but-hoverColor;
              }
            }
            .line-but-disable {
              background-color: @but-disableColor;
              &:hover {
                background-color: @but-disableColor;
                cursor: not-allowed;
              }
            }
          }
          .line-ver {
            // 横屏展示
            width: 98%;
          }
        }
      }
    }
  }
  .buts {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 200px;
    box-sizing: border-box;
    .occupi {
      width: 50px;
    }
  }
  .but {
    width: auto;
    height: auto;
    padding: 5px 15px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: @baseColor;
    color: #ffffff;
    transition: 0.3s;
    margin-bottom: 8px;
    &:hover {
      cursor: pointer;
      background-color: @but-hoverColor;
    }
  }
  .uploader-show {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    .text-show {
      margin: 0 8px;
    }
    .el-upload__tip {
      color: #9b9b9b8a;
    }
  }
}

#my-dialog .type {
  width: 90%;
  height: 50px;
  .type1 {
    width: 49%;
    height: 100%;
  }
}
#my-dialog .content {
  width: 100%;
  height: 100%;
}
#my-dialog .buts {
  padding: 0;
  .but {
    margin: 8px 20px;
  }
}
.tree-data {
  width: 90%;
  height: 0;
  flex: 1;
  margin: 10px 0 30px;
  .tree-data-bor {
    flex: 1;
    width: 0;
    height: 100%;
    border-radius: 5px;
    border: 1px solid @borderColor;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .choose-tree-data {
      width: 98%;
      height: 30px;
      margin-left: 1%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .tree-data-buts {
    width: 120px;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
