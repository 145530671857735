<template>
  <div id="login" class="div-flex-center">
    <div
      class="content div-flex-column-center"
      v-loading="loading"
      element-loading-background="rgba(255, 255, 255, 0.3)"
    >
      <div class="title div-flex-center">用户登录</div>
      <div class="line div-flex-center">
        <div class="line-span">{{ flag == "0" ? "身份证号" : "手机号" }}:</div>
        <div class="line-value div-flex-center">
          <el-input
            v-if="flag == '0'"
            v-model="user"
            placeholder="请输入身份证号"
            clearable
          ></el-input>
          <el-input
            v-if="flag == '1'"
            v-model="phone"
            placeholder="请输入手机号"
            clearable
          ></el-input>
        </div>
      </div>
      <div class="line div-flex-center">
        <div class="line-span">{{ flag == "0" ? "密码" : "验证码" }}:</div>
        <div class="line-value div-flex-center">
          <el-input
            v-if="flag == '0'"
            v-model="password"
            placeholder="请输入密码"
            show-password
            clearable
            @keyup.enter.native="login"
          ></el-input>
          <el-input
            v-if="flag == '1'"
            v-model="verification"
            placeholder="请输入验证码"
            clearable
            @keyup.enter.native="login"
          ></el-input>
        </div>
        <div
          :class="{
            'line-but': true,
            'div-flex-center': true,
            'line-but-disable': isDisable,
          }"
          v-if="flag == '1'"
          @click="getVerification"
        >
          {{ butTip }}
        </div>
      </div>

      <div class="line-radio div-flex-center">
        <el-radio-group v-model="flag" @change="loginWayChange">
          <el-radio :label="'0'">身份证号登录</el-radio>
          <el-radio :label="'1'">手机号登录</el-radio>
        </el-radio-group>
      </div>

      <div class="line-login div-flex-center" @click="login">登录</div>

      <div class="line-tip" @click="gotoRegister">没有账号? 点击注册</div>
    </div>
    <div class="technical-support div-flex-center">
      {{ this.$copyright }}
    </div>

    <el-dialog
      :title="dialogData.title"
      :visible.sync="dialogData.flag"
      width="30%"
    >
      <span
        v-html="dialogData.content"
        v-if="dialogData.type == '普通公告'"
      ></span>
      <div class="dialogcontent" v-if="dialogData.type == '文档公告'">
        <span v-html="dialogData.content"></span>
        <div class="content-line">
          <div
            class="lien"
            v-for="(item, index) in dialogData.link"
            :key="index"
            @click="showFile(item)"
          >
            {{ item.label }}
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="previousStep"
          v-if="noticeList.length > 0 && index != 0"
        >
          上一步</el-button
        >
        <el-button @click="dialogData.flag = false">关闭</el-button>
        <el-button
          type="primary"
          @click="lastStep"
          v-if="noticeList.length > 0 && index != noticeList.length - 1"
        >
          下一步</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      flag: "0", // 登录方式 0 (账号)身份证+密码  1 手机号+验证码
      user: "", // 账号
      password: "", // 密码
      phone: "", // 手机号
      verification: "", // 验证码
      loading: false,
      isDisable: false,
      butTip: "获取验证码",
      timer: null,
      countdown: 60,
      noticeList: [],
      index: 0,
      dialogData: {
        title: "公告",
        content: "",
        flag: false,
        tyep: "普通公告",
        link: [],
      },
    };
  },
  methods: {
    /**
     * 获取当前所有要显示的公告列表
     */
    getAllNoticeList() {
      this.$request("post", "/notice/getAllNoticeListByIndex", {}, (data) => {
        this.noticeList = data.list;
        if (this.noticeList.length > 0) {
          this.index = 0;
          this.dialogData.content = this.noticeList[0].content.text;
          this.dialogData.link = this.noticeList[0].content.documentList;
          this.dialogData.type = this.noticeList[0].type;
          this.dialogData.flag = true;
        }
      });
    },
    /**
     * 切换登录方式时界面缓冲
     */
    loginWayChange() {
      let that = this;
      that.loading = true;
      if (this.flag == "0") {
        this.phone = "";
        this.verification = "";
      } else {
        this.user = "";
        this.password = "";
      }
      setTimeout(() => {
        that.loading = false;
      }, 500);
    },
    /**
     * 上一步
     */
    previousStep() {
      this.index--;
      this.dialogData.content = this.noticeList[this.index].content.text;
      this.dialogData.link = this.noticeList[this.index].content.documentList;
      this.dialogData.type = this.noticeList[this.index].type;
      console.log(this.dialogData);
    },
    /**
     * 下一步
     */
    lastStep() {
      this.index++;
      this.dialogData.content = this.noticeList[this.index].content.text;
      this.dialogData.link = this.noticeList[this.index].content.documentList;
      this.dialogData.type = this.noticeList[this.index].type;
      console.log(this.dialogData);
    },
    /**
     * 查看文档
     */
    showFile(item) {
      window.open(item.file, "_blank");
    },
    /**
     * 登录
     */
    login() {
      let requestData = {
        flag: this.flag, // 登录方式 0 (账号)身份证+密码  1 手机号+验证码
        user: this.user, // 账号
        password: this.password, // 密码
        phone: this.phone, // 手机号
        verification: this.verification, // 验证码
      };

      if (this.flag == "0") {
        if (this.user == "" || this.password == "") {
          this.$message({
            message: "账号或密码不能为空!",
            type: "error",
          });
          return;
        }
      } else {
        if (this.phone == "" || this.verification == "") {
          this.$message({
            message: "手机号或验证码不能为空!",
            type: "error",
          });
          return;
        }
        if (!this.$mobilePhoneNumberCheck(this.phone)) {
          this.$message({
            message: "手机号格式不对!",
            type: "error",
          });
          return;
        }
      }

      this.$request("post", "/user/login", requestData, (data) => {
        this.$message({
          message: "登录成功!",
          type: "success",
        });
        let userData = {
          userId: data.userId,
          userName: data.userName,
          icon:
            data.icon == "" || !data.icon || data.icon == null
              ? require("../assets/img/user.png")
              : data.icon,
        };
        sessionStorage.setItem("userData", JSON.stringify(userData));
        if (data.flag == "0") {
          //  跳转至首页
          this.$router.push("/main");
        } else {
          // 跳转至个人中心
          sessionStorage.setItem("topMenu", JSON.stringify(data));
          let dd = {
            id: "",
            name: "个人信息",
            auth: "2",
            path: "/main/personal_center/personal_information",
          };
          sessionStorage.setItem(
            "childernMenu",
            JSON.stringify({
              id: "",
              name: "个人中心",
              auth: "1", // 1 显示 2 不显示
              path: "/main/personal_center",
            })
          );
          this.$router.push("/main/personal_center/personal_information");
        }
      });
    },

    /**
     * 获取验证码
     */
    getVerification() {
      if (this.phone == "") {
        this.$message({
          message: "手机号不能为空!",
          type: "error",
        });
        return;
      }
      if (!this.$mobilePhoneNumberCheck(this.phone)) {
        this.$message({
          message: "手机号格式不对!",
          type: "error",
        });
        return;
      }
      let requestData = {
        phone: this.phone,
      };
      this.$request(
        "post",
        "/user/sendVerificationCode",
        requestData,
        (data) => {
          this.$message({
            message: "验证码发送成功!",
            type: "success",
          });
          this.isDisable = true;
          if (!this.timer) {
            this.timer = setInterval(() => {
              if (this.countdown > 0 && this.countdown <= 60) {
                this.countdown--;
                if (this.countdown !== 0) {
                  this.butTip = "重新发送(" + this.countdown + ")";
                } else {
                  clearInterval(this.timer);
                  this.butTip = "获取验证码";
                  this.countdown = 60;
                  this.timer = null;
                  this.isDisable = false;
                }
              }
            }, 1000);
          }
        }
      );
    },

    /**
     * 跳转至注册界面
     */
    gotoRegister() {
      this.$router.push("/register");
    },
  },
  mounted() {
    sessionStorage.removeItem("userData");
    this.getAllNoticeList();
  },
};
</script>

<style lang="less" scoped>
#login {
  width: 100%;
  height: 100%;
  background-image: url("../assets/img/login_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;

  .content {
    width: 38%;
    height: 68%;
    background-color: #ffffff4f;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.08);

    .title {
      width: 100%;
      height: 18%;
      border-bottom: 3px solid #fdfdfd6b;
      font-size: @font-size-main-title;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.308), 0 0 6px rgba(0, 0, 0, 0.192);
      letter-spacing: 20px;
      font-weight: bolder;
      color: #fff;
      margin-bottom: 8%;
    }

    .line {
      width: 80%;
      height: 10%;
      color: #fff;
      margin-top: 3%;
      .line-span {
        width: 18%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 10px;
      }
      .line-value {
        width: 0;
        height: 100%;
        flex: 1;
      }
      .line-but {
        width: auto;
        height: 60%;
        padding: 0 15px;
        margin: 0 0 0 15px;
        background-color: @baseColor;
        color: #fff;
        font-size: 15px;
        border-radius: 8px;
        transition: 0.3s;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        &:hover {
          cursor: pointer;
          background-color: @but-hoverColor;
        }
      }
      .line-but-disable {
        background-color: @but-disableColor;
        &:hover {
          background-color: @but-disableColor;
          cursor: not-allowed;
        }
      }
    }

    .line-radio {
      width: 80%;
      height: auto;
      margin-top: 5%;
      .el-radio-group {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .el-radio {
          color: #fff;
        }
      }
    }

    .line-login {
      width: 80%;
      height: 50px;
      background-color: @baseColor;
      color: #fff;
      font-size: 18px;
      border-radius: 8px;
      margin-top: 8%;
      letter-spacing: 20px;
      font-weight: bolder;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        background-color: @but-hoverColor;
      }
    }

    .line-tip {
      width: 100%;
      height: auto;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 13px;
      color: #000000;
      font-weight: bolder;
      transition: 0.3s;
      padding-left: 20%;
      &:hover {
        cursor: pointer;
        color: #ff5202;
      }
    }
  }

  .dialogcontent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .content-line {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .lien {
        width: 100%;
        height: 30px;
        margin: 3px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: @baseColor;
        transition: 0.3s;
        &:hover {
          cursor: pointer;
          color: @activeColor;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
