import Vue from 'vue'
import VueRouter from 'vue-router'
import test from '../views/test.vue'
import Login from '../views/login.vue'
import Register from '../views/register.vue'
import Main from '../views/main.vue'
import Index from '../views/index/index.vue'
import Auth from '../views/applet/auth.vue'

import Order from '../views/order/order.vue'
import OrderManagement from '../views/order/order-management.vue'
import CreateOrder from '../views/order/create-order.vue'
import ToMoveInOrder from '../views/order/tomovein-order.vue'
import RenewOrder from '../views/order/renew-order.vue'
import Instructions from '../views/order/instructions.vue'

import Setting from '../views/syssetting/syssetting.vue'
import Authority from '../views/syssetting/authority.vue'
import Area from '../views/syssetting/area.vue'
import Sheet from '../views/syssetting/sheet.vue'
import DiySheet from '../views/syssetting/diy-sheet.vue'
import Advertising from '../views/syssetting/advertising.vue'
import SyssettingEmployee from '../views/syssetting/syssetting-employee.vue'
import SyssettingRecord from '../views/syssetting/syssetting-record.vue'
import SyssettingProduct from '../views/syssetting/syssetting-product.vue'

import Company from "../views/company/company.vue"
import MyCompany from "../views/company/my-company.vue"
import UserList from "../views/company/user-list.vue"
import ClientInfomation from "../views/company/client-infomation.vue"

import Agent from "../views/agent/agent.vue"
import ContractRecords from "../views/agent/contract_records.vue"
import AgentManagement from "../views/agent/agent_management.vue"
import DocumentsManagement from "../views/agent/documents_management.vue"
import AgentCreate from "../views/agent/agent_create.vue"
import AgentAddress from "../views/agent/agent_address.vue";
import AgentCancellation from "../views/agent/agent_cancellation.vue";


import Person from "../views/person/person.vue"
import PersonalInformation from "../views/person/personal_information.vue"

import Document from "../views/document/document.vue"
import DocumentList from "../views/document/document-list.vue"

import News from "../views/news/news.vue"
import NewsList from "../views/news/news-list.vue"
import AnnouncementList from "../views/news/announcement-list.vue"

import Usens from "../views/usens/usens.vue"
import BigScreen from "../views/usens/big-screen.vue"

import Profile from "../views/profile_data/profile.vue"
import ProfileData from "../views/profile_data/profile-data.vue"
import OrderReport from "../views/profile_data/order-report.vue";

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'login',
    component: Login
}, {
    path: '/register',
    name: 'register',
    component: Register,
}, {
    path: '/main',
    name: 'main',
    component: Main,
    children: [{
        path: "index", // 首页
        name: "index",
        component: Index,
    }, {
        path: "order", // 业务管理
        name: "order",
        component: Order,
        children: [{
            path: "order_management", // 订单管理
            name: "order_management",
            component: OrderManagement,
        }, {
            path: "instructions", // 公告须知界面
            name: "instructions",
            component: Instructions,
        }, {
            path: "create_order", // 新设立申请
            name: "create_order",
            component: CreateOrder,
        }, {
            path: "tomovein_order", // 迁入
            name: "tomovein_order",
            component: ToMoveInOrder,
        }, {
            path: "renew_order", // 续签
            name: "renew_order",
            component: RenewOrder,
        }]
    }, {
        path: "company", // 企业管理
        name: "company",
        component: Company,
        children: [{
            path: "my_company", // 我的企业
            name: "my_company",
            component: MyCompany,
        }, {
            path: "user_list", // 用户管理
            name: "user_list",
            component: UserList,
        }, {
            path: "client_infomation",
            name: "client_infomation",
            component: ClientInfomation
        }]
    }, {
        path: "agent", // 代理管理
        name: "agent",
        component: Agent,
        children: [{
            path: "contract_records", // 备案管理
            name: "contract_records",
            component: ContractRecords,
        },  {
            path: "documents_management", // 凭证管理
            name: "documents_management",
            component: DocumentsManagement,
        }, {
            path: "agent_management", // 合作商
            name: "agent_management",
            component: AgentManagement,
        },{
            path: "agent_create", // 区域审核
            name: "agent_create",
            component: AgentCreate,
        },{
            path: "agent_address", // 优质号段
            name: "agent_address",
            component: AgentAddress,
        },
        {
            path: "agent_cancellation", // 作废地址
            name: "agent_cancellation",
            component: AgentCancellation,
        }]
    }, {
        path: "personal_center", // 个人中心
        name: "personal_center",
        component: Person,
        children: [{
            path: "personal_information", // 我的企业
            name: "personal_information",
            component: PersonalInformation,
        }, ]
    }, {
        path: "document", // 文档管理
        name: "document",
        component: Document,
        children: [{
            path: "document_list", // 文档列表
            name: "document_list",
            component: DocumentList,
        }, ]
    }, {
        path: "news", // 资讯管理
        name: "news",
        component: News,
        children: [{
            path: "news_list", // 资讯列表
            name: "news_list",
            component: NewsList,
        }, {
            path: "announcement_list", // 公告管理
            name: "announcement_list",
            component: AnnouncementList
        }]
    }, {
        path: "profile", // 数据概况
        name: "profile",
        component: Profile,
        children: [{
            path: "profile_data", // 数据概况
            name: "profile_data",
            component: ProfileData,
        },{
            path: "order_report", // 订单报表
            name: "order_report",
            component: OrderReport,
        }]
    }, {
        path: "setting", // 系统设置
        name: "setting",
        component: Setting,
        children: [{
            path: "authority", // 权限管理
            name: "authority",
            component: Authority,
        }, {
            path: "area", // 权限管理
            name: "area",
            component: Area,
        }, {
            path: "sheet", // 自定义表单列表
            name: "sheet",
            component: Sheet,
        }, {
            path: "diy_sheet", // 自定义表格格式
            name: "diy_sheet",
            component: DiySheet,
        },{
            path: "advertising", // 广告设置
            name: "advertising",
            component: Advertising,
        }, {
            path: "syssetting-employee", // 员工列表
            name: "syssetting-employee",
            component: SyssettingEmployee,
        },{
            path: "syssetting-record", // 充值及消费记录
            name: "syssetting-record",
            component: SyssettingRecord,
        },,{
            path: "syssetting-product", // 产品包
            name: "syssetting-product",
            component: SyssettingProduct,
        },]
    }]
}, {
    path: '/usens', // 大屏数据 
    name: 'usens',
    component: Usens,
    // redirect: "/usens/big_screen",
    children: [{
        path: 'big_screen', // 大屏数据  合肥/安徽省
        name: 'big_screen',
        component: BigScreen,
    }]
}, {
    path: '/test',
    name: 'test',
    component: test,
}, {
    path: '/auth',
    name: 'auth',
    component: Auth,
}]

const router = new VueRouter({
    routes
})

//重复路由
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
export default router