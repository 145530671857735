<template>
  <div id="document-list" class="div-flex-column-center" v-loading="loading">
    <!-- 文档表格管理界面 -->
    <el-tabs v-model="activeName">
      <el-tab-pane label="帮助文档" name="first">
        <div class="div-flex-center">
          <div class="search-line div-flex-center">
            <el-input
              v-model="search.name"
              placeholder="请输入要查询的内容"
              clearable
              @keyup.enter.native="resetPage"
            ></el-input>
          </div>
          <div class="search-line">
            <el-upload
              class="avatar-uploader"
              v-if="buttShow == true"
              :action="uploadUrl"
              :show-file-list="false"
              :data="uploadData"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <div class="uploader-show">
                <el-button
                  type="primary"
                  size="small"
                  icon="el-icon-circle-plus-outline"
                  plain
                  >添加</el-button
                >
              </div>
            </el-upload>
          </div>
          <div class="search-line div-flex-center"></div>
          <div class="search-line div-flex-center"></div>
        </div>
        <div class="content div-flex-center">
          <el-table
            :data="tableData"
            border
            style="width: 98%"
            height="67vh"
            stripe
          >
            <el-table-column prop="title" label="标题" center>
            </el-table-column>
            <el-table-column prop="type" label="文档类型" width="150" center>
            </el-table-column>
            <el-table-column
              prop="updateTime"
              label="更新时间"
              width="150"
              center
            >
            </el-table-column>
            <el-table-column prop="userName" label="上传者" width="150" center>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="140">
              <template slot-scope="scope">
                <el-button
                  @click="handleClick(scope.row, item.active)"
                  type="text"
                  size="small"
                  v-for="(item, index) in tableOper"
                  :key="index"
                  v-show="item.show && item.statusShow"
                  >{{ item.name }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="page">
          <el-pagination
            @current-change="getAllDocumentList"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "first",
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
      uploadData: {
        systemName: "cluster_register",
        path: "",
        title: "上传文档",
      },
      loading: false,
      userData: {
        userId: "",
        userName: "",
        icon: "",
      },
      pageData: {
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页条数
        total: 0, // 总条目数
      },
      search: {
        name: "",
      },
      tableData: [],
      tableOper: [
        {
          name: "查看",
          key: "查看",
          active: "show",
          show: false, // 由权限控制
          statusShow: true, // 由当前订单的状态控制
        },
        {
          name: "删除",
          key: "删除",
          active: "delete",
          show: false, // 由权限控制
          statusShow: true, // 由当前订单的状态控制
        },
      ],
      but: [
        {
          name: "添加",
          key: "上传",
          active: "add",
          show: false, // 由权限控制
          statusShow: true, // 由当前订单的状态控制
        },
      ],
      buttShow: false,
      dialogData: {
        loading: true,
        flag: false,
        title: "",
        type: "add_document",
        data: [],
        buttonList: "",
      },
      tempOrder: {}, // 临时表格数据
      pathName: "",
      pathType: 0,
    };
  },
  methods: {
    /**
     *2.3 获取当前角色权限
     */
    getUserAuthority() {
      this.$baseData.getUserAuthority(this.userData.userId, this, (data) => {
        this.tableOper = this.$baseData.judgeButtonPermissions(
          data[0].model,
          this.tableOper,
          "帮助文档",
          "文档管理"
        );
        this.but = this.$baseData.judgeButtonPermissions(
          data[0].model,
          this.but,
          "帮助文档",
          "文档管理"
        );
        this.buttShow = this.but[0].show;
        this.getAllDocumentList();
      });
    },
    /**
     * 获取当前类别的文档列表
     */
    getAllDocumentList() {
      let requestData = {
        type: this.pathName,
        // type: this.pathType,
        search: {
          name: this.search.name,
        },
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
      };
      this.$request(
        "post",
        "/document/getDocumentList",
        requestData,
        (data) => {
          this.tableData = data.tableData;
          this.pageData.total = data.total;
        }
      );
    },
    /**
     * 重置 page 请求列表数据
     */
    resetPage() {
      this.pageData.currentPage = 1;
      this.getAllDocumentList();
    },
    /**
     * 获取对应类别
     */
    getDocumentType() {
      switch (this.pathName) {
        case "新设立":
          this.pathType = 0;

          break;
        case "迁址":
          this.pathType = 1;

          break;
        case "续签":
          this.pathType = 2;

          break;
        case "代理管理":
          this.pathType = 3;

          break;
        case "小程序":
          this.pathType = 4;

          break;
        case "个人中心":
          this.pathType = 5;

          break;
        case "企业管理":
          this.pathType = 6;

          break;

        default:
          break;
      }
    },
    /**
     * 表格按钮事件
     */
    handleClick(item, active) {
      this.tempOrder = item;
      let requestData = {};
      switch (active) {
        case "show":
          // 查看文档
          console.log("item", item);
          window.open(item.file, "_blank");
          break;
        case "delete":
          // 删除文档
          this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              requestData = {
                id: item.id,
              };
              this.$request(
                "post",
                "/document/delDocument",
                requestData,
                (data) => {
                  this.$message({
                    type: "success",
                    message: "文档删除成功!",
                  });
                  this.getAllDocumentList();
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;

        default:
          break;
      }
    },
    /**
     * 文件上传成功
     */
    handleAvatarSuccess(response, file, fileList) {
      let requestData = {};
      switch (response.result.title) {
        case "上传文档":
          requestData = {
            file: response.result.path,
            type: this.pathName,
            userId: this.userData.userId,
            title: file.name,
            keys: [],
          };
          this.$request(
            "post",
            "/document/uploadDocument",
            requestData,
            (data) => {
              this.$message({
                message: "文档添加成功!",
                type: "success",
              });
              this.getAllDocumentList();
            }
          );
          break;
        default:
          break;
      }
    },

    /**
     * 文件上传之前的格式校验
     */
    beforeAvatarUpload(file) {
      console.log("上传文件格式:", file.type);
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 3MB!");
      }
      return isLt2M;
    },
  },
  mounted() {
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    this.pathName = this.$route.query.name;
    // this.getDocumentType();
    this.getUserAuthority();
  },
};
</script>

<style lang="less" scoped>
#document-list {
  width: 100%;
  height: 100%;

  .el-tabs {
    width: 98%;
    height: 98%;
    .search-line {
      width: 25%;
      margin-bottom: 10px;
    }
    .content {
      width: 100%;
      height: auto;
      .tip {
        color: @baseColor;
        transition: 0.3s;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
          color: @activeColor;
        }
      }
    }
    .page {
      width: 100%;
      height: 50px;
      margin-bottom: 10px;
    }
  }
}
</style>
