<template>
  <div id="sys-setting" class="div-flex-center" v-loading="loading">
    <!-- 系统设置 -->
    <div class="left div-flex-column-center">
      <div
        :class="{
          menu: true,
          'div-flex-center': true,
          'is-active': isActive == item.name,
        }"
        v-show="item.auth == '1'"
        v-for="(item, index) in menus"
        :key="index"
        @click="jump(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="right"><router-view /></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: "权限管理",
      loading: true,
      menuList: [
        "/main/setting/authority",
        "/main/setting/area",
        "/main/setting/sheet",
      ],
      menus: [
        {
          id: "",
          name: "权限管理",
          auth: "2",
          path: "/main/setting/authority",
        },
        // {
        //   id: "",
        //   name: "合同基本设置",
        //   auth: "2",
        //   path: "/main/setting/area",
        // },
        {
          id: "",
          name: "员工列表",
          auth: "2",
          path: "/main/setting/syssetting-employee",
        },
        {
          id: "",
          name: "表单设置",
          auth: "2",
          path: "/main/setting/sheet",
        },
        {
          id: "",
          name: "广告设置",
          auth: "2",
          path: "/main/setting/advertising",
        },
        {
          id: "",
          name: "充值及消费记录",
          auth: "2",
          path: "/main/setting/syssetting-record",
        },
        {
          id: "",
          name: "产品包",
          auth: "2",
          path: "/main/setting/syssetting-product",
        },
      ],
      userData: {
        userId: "",
        userName: "",
        icon: "",
      },
    };
  },
  methods: {
    /**
     * 路由跳转
     */
    jump(data) {
      sessionStorage.setItem("childernMenu", JSON.stringify(data));
      sessionStorage.setItem("path", data.path);
      this.isActive = data.name;
      this.$router.push(data.path);
    },
  },
  mounted() {
    this.loading = true;
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    this.$baseData.getUserAuthority(this.userData.userId, this, (data) => {
      this.menus = this.$baseData.judgeDirectoryPermissions(
        data,
        this.menus,
        "系统设置"
      );
    });

    setTimeout(() => {
      let name = "";
      let path = "";
      let tempMenuList = [];

      for (let i = 0; i < this.menus.length; i++) {
        if (this.menus[i].auth == 1 || this.menus[i].auth == "1") {
          name = this.menus[i].name;
          path = this.menus[i].path;
          break;
        }
      }
      this.menus.forEach((element) => {
        if (element.auth == 1 || element.auth == "1") {
          tempMenuList.push(element.path);
        }
      });

      this.isActive =
        sessionStorage.getItem("childernMenu") &&
        tempMenuList.indexOf(
          JSON.parse(sessionStorage.getItem("childernMenu")).path
        ) >= 0
          ? JSON.parse(sessionStorage.getItem("childernMenu")).name
          : name;
      path =
        sessionStorage.getItem("childernMenu") &&
        tempMenuList.indexOf(
          JSON.parse(sessionStorage.getItem("childernMenu")).path
        ) >= 0
          ? JSON.parse(sessionStorage.getItem("childernMenu")).path
          : path;
      this.$router.push({
        path: path,
        query: {
          name: this.isActive,
        },
      });
      this.loading = false;
    }, 1000);
  },
};
</script>

<style lang="less" scoped>
#sys-setting {
  width: 100%;
  height: 100%;
  .left {
    width: 15%;
    height: 100%;
    box-sizing: border-box;
    border-right: 1px solid @borderColor;
    // border-left: 1px solid @borderColor;

    .menu {
      width: 100%;
      height: 8%;
      border-bottom: 1px solid #33333300;
      border-left: 1px solid #33333300;
      box-sizing: border-box;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        background-color: rgba(201, 201, 201, 0.212);
        border-left: 5px solid @activeColor;
        color: @activeColor;
      }
    }
    .is-active {
      background-color: rgba(201, 201, 201, 0.212);
      border-left: 5px solid @activeColor;
      color: @activeColor;
    }
  }
  .right {
    width: 0;
    height: 100%;
    flex: 1;
    overflow: hidden;
  }
}
</style>
