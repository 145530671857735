<template>
  <div id="documents_management">
    <!-- 凭证管理 -->
    <div class="fill-in-information div-flex-column-center">
      <el-tabs v-model="activeName">
        <el-tab-pane label="基本信息" name="基本信息"> </el-tab-pane>
      </el-tabs>
      <div class="information-content div-flex-column-center" v-if="!showText">
        <el-empty :description="description"></el-empty>
      </div>
      <div class="information-content div-flex-column-center" v-if="showText">
        <information_text :informationTextData="orderList"></information_text>
      </div>
    </div>
  </div>
</template>

<script>
import * as agentData from "../../assets/js/agentData.js";
import * as instructionsData from "../../assets/js/instructions.js";
export default {
  data() {
    return {
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
      activeName: "基本信息",
      description: "请先进行代理申请!",
      showText: false, // 是否以文本形式展示信息
      userData: {
        userId: "",
        userName: "",
        icon: "",
      },
      orderList: agentData.getDocumentsManagement(),
      id: "",
    };
  },
  methods: {
    /**
     * 初始化界面
     * 3.2 查看备案的状态
     */
    initData() {
      let requestData = {
        userId: this.userData.userId,
      };
      this.$request("post", "/agent/getAgentStatus", requestData, (data) => {
        if (data.agentId == "" || data.status == "审核不通过") {
          // 暂未进行代理申请
          this.description = "请先进行代理申请!";
          this.showText = false;
        } else if (data.status == "审核中") {
          // 代理申请状态处于审核中
          this.description = "请等待总公司进行代理审核!";
          this.showText = false;
        } else if (data.status == "已通过") {
          this.showText = true;
          this.id = data.agentId;
          this.getAgentInformation();
        }
      });
    },
    /**
     * 3.3 查看备案的具体信息
     */
    getAgentInformation() {
      let requestData = {
        agentId: this.id,
      };
      this.$request(
        "post",
        "/agent/getAgentInformation",
        requestData,
        (data) => {
          this.orderList = instructionsData.matchOrderData(
            this.orderList,
            data
          );
          if (data.remainAmount == "") {
            // 合作商没有剩余额度一说
            this.orderList[0].data[5].title = "";
          } else {
            this.orderList[0].data[5].title = "剩余额度:";
          }
        }
      );
    },
  },
  mounted() {
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    let dd = {
      id: "",
      name: "凭证管理",
      auth: "2",
      path: "/main/agent/documents_management",
    };
    sessionStorage.setItem("childernMenu", JSON.stringify(dd));
    this.initData();
  },
};
</script>

<style lang="less" scoped>
#documents_management {
  width: 100%;
  height: 100%;
  .fill-in-information {
    width: 100%;
    height: 100%;

    .el-tabs {
      width: 98%;
      height: auto;
    }

    .information-content {
      width: 98%;
      height: 0;
      flex: 1;
      overflow-y: auto;
      .model {
        width: 100%;
        height: auto;
        .title {
          width: 100%;
          height: 35px;
          position: relative;
          .line {
            width: 100%;
            height: 1px;
            background-color: rgba(160, 160, 160, 0.288);
            position: absolute;
            left: 0;
            top: 10px;
          }
          .text {
            width: auto;
            background-color: #ffffff;
            height: 100%;
            position: absolute;
            left: 30px;
            padding: 0 8px;
            font-size: 14px;
            span {
              color: red;
              margin-left: 8px;
            }
          }
        }
        .content {
          width: 100%;
          height: auto;
          display: flex;
          flex-wrap: wrap;
          .line {
            width: 49%;
            height: auto;
            min-height: 50px;
            display: flex;
            align-items: center;
            font-size: 14px;
            margin: 8px 0;
            .line-title {
              width: 200px;
              height: 100%;
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              span {
                color: red;
                margin-top: 3px;
                margin-right: 3px;
                font-size: 18px;
              }
            }
            .line-value {
              width: 0;
              flex: 1;
              height: 100%;
              display: flex;
              align-items: flex-start;
              margin-left: 10px;
              .div1 {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
              }

              .textarea-but {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              }
            }

            .line-but {
              width: auto;
              min-width: 65px;
              height: 30px;
              padding: 0 15px;
              margin: 0 0 0 15px;
              background-color: @baseColor;
              color: #fff;
              font-size: 13px;
              border-radius: 8px;
              transition: 0.3s;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12),
                0 0 6px rgba(0, 0, 0, 0.04);
              &:hover {
                cursor: pointer;
                background-color: @but-hoverColor;
              }
            }
            .line-but-disable {
              background-color: @but-disableColor;
              &:hover {
                background-color: @but-disableColor;
                cursor: not-allowed;
              }
            }
          }
          .line-ver {
            // 横屏展示
            width: 98%;
          }
        }
      }
    }
  }
  .buts {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 200px;
    box-sizing: border-box;
    .occupi {
      width: 50px;
    }
  }
  .but {
    width: auto;
    height: auto;
    padding: 5px 15px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: @baseColor;
    color: #ffffff;
    transition: 0.3s;
    margin-bottom: 8px;
    &:hover {
      cursor: pointer;
      background-color: @but-hoverColor;
    }
  }
  .uploader-show {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    .text-show {
      margin: 0 8px;
    }
    .el-upload__tip {
      color: #9b9b9b8a;
    }
  }
}
</style>
